import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_DEDUCTION_PAYMENT = gql`
    query DeductionPayments($where: deductionPaymentWhereInput, $limit: Int, $skip: Int) {
      deductionPayments(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          deductionID
          deductionAmount
          deductionDate
          deductionStatus
          deductionNote
          deductionCreatedAt
          deductionCreatedBy {
            _id
            first_name
            last_name
            gender
          }
          deductionCheck
          noID
          deductionDriverID {
            _id
            first_name
            last_name
            gender
          }
        }
      }
    }
  `;

  static CREATE_DEDUCTION_PAYMENT = gql`
    mutation CreateDeductionPayment($data: deductionPaymentInput!) {
      createDeductionPayment(data: $data) {
        status
      }
    }
  `;

  static UPDATE_DEDUCTION_PAYMENT = gql`
    mutation UpdateDeductionPayment($where: whereUID!, $data: deductionPaymentInput) {
      updateDeductionPayment(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static DELETE_DEDUCTION_PAYMENT = gql`
    mutation DeleteDeductionPayment($where: whereUID!) {
      deleteDeductionPayment(where: $where) {
        status
      }
    }
  `;
}
