import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import Profile from "../../img/user_null.png";
import { ParentPath } from "../../routes/Path";
import { Gender, formatDate, formatDateTime } from "../../utils";
import { url_upload_Profile } from "../../configs/Environment";
import Female from "../../img/female-icon.png";

export default function InformationDriver() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const getUser = params.get("Driver");
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_USERS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          _id: Number(getUser),
        },
      },
    });
  }, [getUser, FetchData]);

  const value = data?.users?.data[0];

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item" style={{ cursor: "pointer" }} onClick={() => navigate(`${ParentPath.users}`)}>
            ຂໍ້ມູນພະນັກງານໃຊ້ລະບົບ
          </li>
          <li className="breadcrumb-item">ລາຍລະອຽດຂໍ້ມູນພະນັກງານໃຊ້ລະບົບ</li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-car"></i> ລາຍລະອຽດຂໍ້ມູນພະນັກງານໃຊ້ລະບົບ
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={3}>

              {value?.image ? (
                <Image src={url_upload_Profile + value?.image} style={{ width: "100%", height: "auto", borderRadius:5 }} />

              ) : (
                <>
                  {value?.gender === "FEMALE" ? (
                    <Image src={Female} style={{ width: "100%", height: "auto",borderRadius:5 }} />

                  ) : (
                    <Image src={Profile} style={{ width: "100%", height: "auto",borderRadius:5 }} />

                  )}
                </>

              )}
              <br />
              <h3 className="text-center text-dark mt-2">ID:{value?._id || 0}</h3>
              <br />
            </Col>
            <Col md={9}>
              <div className="card-header bg-primary">
                <h3 className="text-white">ລາຍລະອຽດຂໍ້ມູນພະນັກງານໃຊ້ລະບົບ </h3>
              </div>
              <br />
              <p>
                ຊື່ ແລະ ນາມສະກຸນ: {value?.gender ? Gender(value?.gender) : ""} {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.first_name || "----"}{" "}
                {value?.last_name || "----"}
              </p>
              <hr />
              <p>
                ສິດການນຳໃຊ້:
                {value?.role === "SUPER_ADMIN" ? (
                  <Button variant="success" size="sm" className="disable-cursor">
                    ຜູ້ດູແລລະບົບ
                  </Button>
                ) : value?.role === "ADMIN" ? (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ຜູ້ຈັດຖ້ຽວ
                  </Button>
                ) : value?.role === "USERS" ? (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ຜູ້ໃຊ້
                  </Button>
                ) : value?.role === "DRIVER_OWN" ? (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ໂຊເຟີ້ສ່ວນບຸກຄົນ
                  </Button>
                ) : value?.role === "AUDITOR" ? (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ກວດສອບຖ້ຽວລົດ
                  </Button>
                ) : value?.role === "ACCOUNTANT" ? (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ບັນຊີ
                  </Button>
                ) : (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ໂຊເຟີ້ບໍລິສັດ
                  </Button>
                )}
              </p>
              <hr />
              <p>
                ສະຖານະ:
                {value?.status === "ACTIVED" ? (
                  <Button variant="success" size="sm" className="disable-cursor">
                    ກຳລັງໃຊ້ງານ
                  </Button>
                ) : (
                  <Button variant="danger" size="sm" className="disable-cursor">
                    ອອກແລ້ວ
                  </Button>
                )}
              </p>
              <hr />
              <p>ວັນທີສ້າງ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : formatDateTime(value?.createdAt)}</p>
              <hr />
              <p>
                ສ້າງໂດຍ: {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""} {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.createdBy?.first_name || "----"}{" "}
                {value?.createdBy?.last_name || "----"}
              </p>
              <hr />
              <p>ເພດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.gender === "FEMALE" ? "ເພດຍິງ" : "ເພດຊາຍ"}</p>
              <hr />
              <p>ເບີໂທ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.phoneNumber || "-----"}</p>
              <hr />

              <p>ອາຍຸ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.age || "-----"}</p>
              <hr />
              <p>ທີ່ຢູ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.address || "-----"}</p>
              <hr />
              <p>ເລກບັດປະຈຳຕົວ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.cardID || "-----"}</p>
              <hr />
              <p>ເລກທີໃບຂັບຂີ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.passID || "-----"}</p>
              <hr />
              <p>
                ວັນທີເລີ່ມ:{" "}
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.startDate ? formatDate(value?.startDate) : "-----"}
              </p>
              <hr />
              <p>
                ວັນທີອອກ:{" "}
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.endDate ? formatDate(value?.endDate) : "-----"}
              </p>
              <hr />
              <p>ເອກະສານຕິດຂັດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.docFile || "-----"}</p>
              <hr />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
