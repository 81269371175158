import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { endOfMonth, startOfMonth } from "..";

export default function SearchDateRangeBetweenConsolidate({
  eMonth,
  sMonth,
  hideStartDate,
  hideEndDate,
  containerClass,
  containerStyle,
  isToday,
}: any) {
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const _start = searchParams.get("startConsolidateDate");
    const _end = searchParams.get("endConsolidateDate");
    const _endOfMonth = eMonth ? endOfMonth("YYYY-MM-DD") : "";
    const _startOfMonth = sMonth ? startOfMonth("YYYY-MM-DD") : "";
    setDefaultStartDate(_start ? _start : _startOfMonth);
    setDefaultEndDate(_end ? _end : _endOfMonth);
  }, [searchParams, isToday, eMonth, sMonth]);

  return (
    <div className={`search-date-range ${containerClass}`} style={containerStyle}>
      <i className="fas fa-search" />
      ຄົ້ນຫາຂໍ້ມູນຈາກວັນທີເລືອກສະຫຼຸບ
      <div className="input-group">
        {!hideStartDate && <Form.Control type="date" value={defaultStartDate} onChange={(e) => setDefaultStartDate(e.target.value)} />}
        {!hideEndDate && <Form.Control type="date" value={defaultEndDate} onChange={(e) => setDefaultEndDate(e.target.value)} />}
        <Button
          disabled={!defaultStartDate || !defaultEndDate}
          onClick={() => {
            if (searchParams.get("page")) {
              searchParams.set("page", "1");
            }
            searchParams.set("startConsolidateDate", defaultStartDate);
            searchParams.set("endConsolidateDate", defaultEndDate);
            setSearchParams(searchParams);
          }}
        >
          <i className="fas fa-search" />
          ຄົ້ນຫາ
        </Button>
      </div>
    </div>
  );
}
