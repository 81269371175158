import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import Schema from "./Schema";
import { useFormik } from "formik";
import compressImage, { LocalStorageName, MessageErrors, MessageSuccess, formatDateUpdate, notifyError, notifySuccess } from "../../utils";
import axios from "axios";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import { FcAddImage, FcImageFile } from "react-icons/fc";
import { url_upload_Profile, url_upload_cardImage, url_upload_docFile, url_upload_file, url_upload_passImage } from "../../configs/Environment";
import SearchDriverCompany from "../../utils/components/SelectDriverCompany";
import SearchCenter from "../../utils/components/SelectCenter";

export default function EditUser({ item, onSuccess }: any) {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_USERS);

  const [PassImage, setPassImage] = useState<any>();
  const [CardIDimage, setCardImage] = useState<any>();
  const [userFile, setUserFile] = useState<any>();
  const [image, setImage] = useState<any>();

  useEffect(() => {
    if (item?.passImage) {
      setPassImage(item?.passImage);
    }
    if (item?.image) {
      setImage(item?.image);
    }
    if (item?.cardImage) {
      setCardImage(item?.cardImage);
    }
    if (item?.docFile) {
      setUserFile(item?.docFile);
    }
  }, [item]);

  const handleChangeFileImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("image", compressedFile);
        const res = await axios.post(url_upload_file, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setImage(filename);
        } else {
          setImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // userImage
  const handleChangeFileCardImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setCardImage(e.target?.value);
        const formData = new FormData();
        formData.append("cardImage", compressedFile);
        const res = await axios.post(url_upload_cardImage, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setCardImage(filename);
        } else {
          setCardImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // PassImage
  const handleChangeFilePassImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setPassImage(e.target?.value);
        const formData = new FormData();
        formData.append("passImage", compressedFile);
        const res = await axios.post(url_upload_passImage, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setPassImage(filename);
        } else {
          setPassImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // useFile
  const handleChangeUserFile = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setUserFile(e.target?.value);
        const formData = new FormData();
        formData.append("docFile", file);
        const res = await axios.post(url_upload_docFile, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setUserFile(filename);
        } else {
          setUserFile("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  const initialValues: any = {
    first_name: "",
    last_name: "",
    gender: "",
    age: 0,
    address: "",
    cardID: 0,
    passID: "",
    basicSalary: 0,
    startDate: "",
    phoneNumber: 0,
    password: "",
    role: "",
    center: 0,
    account_bank_name: "",
    account_bank_number: "",
    stStatus: 0,
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values?.first_name) errors.first_name = "ກະລຸນາປ້ອນຊື່";
    if (!values?.last_name) errors.last_name = "ກະລຸນາປ້ອນນາມສະກຸນ";
    if (!values?.gender) errors.gender = "ກະລຸນາປ້ອນເພດ";
    if (!values?.age) errors.age = "ກະລຸນາປ້ອນອາຍຸ";
    if (!values?.address) errors.address = "ກະລຸນາປ້ອນທີ່ຢູ່";
    if (!values?.startDate) errors.startDate = "ກະລຸນາປ້ອນວັນທີເຂົ້າວຽກ";
    if (!values?.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທໃຫ້ຄົບ 8 ຕົວເລກກ່ອນ";
    if (!values?.password) errors.password = "ກະລຸນາປ້ອນລະຜ່ານ";
    if (!values?.role) errors.role = "ກະລຸນາເລືອກສິດທິນຳໃຊ້";
    if (!values?.account_bank_name) errors.account_bank_name = "ກະລຸນາປ້ອນຊື່ທະນາຄານ";
    if (!values?.account_bank_number) errors.account_bank_number = "ກະລຸນາປ້ອນເລກບັນຊີໃຫ້ຄົບ 13 ຕົວເລກກ່ອນ";
    if (!values?.center) errors.center = "ກະລຸນາເລືອກສູນກ່ອນ";
    if (!values?.stStatus) errors.stStatus = "ກະລຸນາເລືອກບໍລິສັດ";

    return errors;
  };

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<any>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: {
            data: {
              cardID: Number(values?.cardID),
              basicSalary: Number(values?.basicSalary),
              first_name: String(values?.first_name),
              last_name: String(values?.last_name),
              gender: String(values?.gender),
              age: Number(values?.age),
              address: String(values?.address),
              image: String(image),
              passID: String(values?.passID),
              cardImage: String(CardIDimage),
              startDate: String(values?.startDate),
              phoneNumber: Number(values?.phoneNumber),
              password: String(values?.password),
              role: String(values?.role),
              center: Number(values?.center),
              account_bank_name: String(values?.account_bank_name),
              account_bank_number: String(values?.account_bank_number),
              docFile: String(userFile),
              stStatus: Number(values?.stStatus),
              passImage: String(PassImage),
            },
            where: {
              _id: item?._id,
            },
          },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      first_name: item?.first_name || "",
      last_name: item?.last_name || "",
      gender: item?.gender || "",
      age: item?.age || 0,
      address: item?.address || "",
      cardID: item?.cardID || 0,
      passID: item?.passID || "",
      basicSalary: item?.basicSalary || 0,
      startDate: item?.startDate ? formatDateUpdate(item?.startDate) : "",
      phoneNumber: item?.phoneNumber || 0,
      password: item?.password || "",
      role: item?.role || "",
      center: item?.center?.st_id || 0,
      account_bank_name: item?.account_bank_name || "",
      account_bank_number: item?.account_bank_number || "",
      stStatus: item?.stStatus?.cp_id || 0,
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>

      <Modal show={show} dialogClassName="modal-width-95">
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-edit" />
            ຟອມແກ້ໄຂຂໍ້ມູນຜູ້ໃຊ້ລະບົບ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-image">
                    {image ? (
                      <>
                        <Image
                          src={url_upload_Profile + image}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={handleChangeFileImage}
                          id="file-upload-image"
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={handleChangeFileImage}
                          id="file-upload-image"
                          hidden
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບຜູ້ນຳໃຊ້ລະບົບ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ຊື່</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="ກະລຸນາປ້ອນຊື່"
                    onChange={handleChange}
                    value={values?.first_name}
                    isInvalid={errors?.first_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ນາມສະກຸນ</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                    onChange={handleChange}
                    value={values?.last_name}
                    isInvalid={errors?.last_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກເພດ</Form.Label>
                  <Form.Select name="gender" onChange={handleChange} value={values?.gender} isInvalid={errors?.gender ? true : false}>
                    <option>-- ເລືອກ --</option>
                    <option value="MALE">ເພດຊາຍ</option>
                    <option value="FEMALE">ເພດຍິງ</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ອາຍຸ</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ອາຍຸ"
                    onWheel={(e: any) => e.target.blur()}
                    name="age"
                    onChange={handleChange}
                    value={values?.age}
                    isInvalid={errors?.age ? true : false}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-cardImage">
                    {CardIDimage ? (
                      <>
                        <Image
                          src={url_upload_Profile + CardIDimage}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="cardImage"
                          onChange={handleChangeFileCardImage}
                          id="file-upload-cardImage"
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="cardImage"
                          onChange={handleChangeFileCardImage}
                          id="file-upload-cardImage"
                          hidden
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບໃບຂັບຂີ່</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກທີໃບຂັບຂີ່</Form.Label>
                  <Form.Control type="text" name="passID" placeholder="ກະລຸນາປ້ອນເລກໃບຂັບຂີ່" onChange={handleChange} value={values?.passID} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເບີໂທ</Form.Label>
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    onWheel={(e: any) => e.target.blur()}
                    placeholder="ກະລຸນາປ້ອນເບີໂທ"
                    onChange={handleChange}
                    value={values?.phoneNumber}
                    isInvalid={errors?.phoneNumber ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ລະຫັດຜ່ານ</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    disabled
                    placeholder="ລະຫັດຜ່ານ"
                    onChange={handleChange}
                    isInvalid={errors?.password ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ສິດການນຳໃຊ້</Form.Label>
                  <Form.Select name="role" onChange={handleChange} value={values?.role} isInvalid={errors?.role ? true : false}>
                    <option value="">-- ເລືອກ --</option>
                    <option value="SUPER_ADMIN">ຜູ້ດູແລລະບົບ</option>
                    <option value="DRIVER">ໂຊເຟີ້(ບໍລິສັດ)</option>
                    <option value="DRIVER_OWN">ໂຊເຟີ້ສ່ວນບຸກຄົນ</option>
                    <option value="USERS">ຜູ້ໃຊ້</option>
                    <option value="AUDITOR">ຜູ້ກວດສອບ</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-passImage">
                    {PassImage ? (
                      <>
                        <Image
                          src={url_upload_Profile + PassImage}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="passImage"
                          onChange={handleChangeFilePassImage}
                          hidden
                          id="file-upload-passImage"
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="passImage"
                          onChange={handleChangeFilePassImage}
                          hidden
                          id="file-upload-passImage"
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບບັດປະຈຳຕົວ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກບັດປະຈຳຕົວ</Form.Label>
                  <Form.Control
                    type="number"
                    name="cardID"
                    placeholder="ກະລຸນາປ້ອນເລກບັດປະຈຳຕົວ"
                    onChange={handleChange}
                    value={values?.cardID}
                    onWheel={(e: any) => e.target.blur()}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ວັນທີເລີ່ມ</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    onChange={handleChange}
                    value={values?.startDate}
                    isInvalid={errors?.startDate ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກສູນ</Form.Label>
                  <SearchCenter
                    value={values?.center}
                    onChange={(res: any) => {
                      setFieldValue("center", res?.value);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">{String(errors?.center)}</Form.Control.Feedback>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກບໍລິສັດ</Form.Label>
                  <SearchDriverCompany
                    value={values?.stStatus}
                    onChange={(res: any) => {
                      setFieldValue("stStatus", res?.value);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">{String(errors?.stStatus)}</Form.Control.Feedback>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-docFile">
                    {userFile ? (
                      <>
                        <FcImageFile size={95} style={{ cursor: "pointer" }} />
                        <div>{userFile}</div>
                        <Form.Control
                          type="file"
                          accept="application/pdf,application/vnd.ms-excel"
                          className="form-control"
                          name="docFile"
                          onChange={handleChangeUserFile}
                          hidden
                          id="file-upload-docFile"
                        />
                      </>
                    ) : (
                      <>
                        <FcImageFile size={100} style={{ cursor: "pointer" }} />
                        <div className="text-danger">ບໍ່ມີເອກະສານ</div>
                        <Form.Control
                          type="file"
                          accept="application/pdf,application/vnd.ms-excel"
                          className="form-control"
                          name="docFile"
                          onChange={handleChangeUserFile}
                          hidden
                          id="file-upload-docFile"
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດເອກະສານ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເງິນເດືອນພື້ນຖານ(ສະເພາະໂຊເຟີ້ບໍລິສັດ)</Form.Label>
                  <Form.Control
                    type="number"
                    name="basicSalary"
                    onChange={handleChange}
                    value={values?.basicSalary}
                    onWheel={(e: any) => e.target.blur()}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ຊື່ບັນຊີທະນາຄານ(ກີບ)</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_bank_name"
                    placeholder="ຊື່ບັນຊີທະນາຄານ"
                    onChange={handleChange}
                    value={values?.account_bank_name}
                    isInvalid={errors?.account_bank_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກບັນຊີທະນາຄານ(ກີບ)</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_bank_number"
                    placeholder="ເລກບັນຊີທະນາຄານ"
                    onChange={handleChange}
                    value={values?.account_bank_number}
                    isInvalid={errors?.account_bank_number ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ທີ່ຢູ່</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="ກະລຸນາປ້ອນທີ່ຢູ່"
                    onChange={handleChange}
                    value={values?.address}
                    isInvalid={errors?.address ? true : false}
                    as="textarea"
                    rows={5}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
