import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import Schema from "./Schema";
import icon2 from "../../img/FFFF.png";
import { Image, Spinner } from "react-bootstrap";

export default function CountLoading({ eDay, sDay }: any) {
  const [countLoading, setCountLoading] = useState(0);
  const [query_count_loading, { data, loading }] = useLazyQuery(Schema.QUERY_COUNT_STATUS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const where = {
      acceptStatus: "ACCEPTED",
      newLocation: "NO",
      createdDateBetween: [sDay, eDay],
    };

    query_count_loading({
      variables: {
        where,
      },
    });
  }, [query_count_loading, eDay, sDay]);

  useEffect(() => {
    setCountLoading(data?.shippingRoutes?.total || 0);
  }, [data]);
  return (
    <>
      <div className=" header_top row col-md-4 shadow card dash ">
        <Image className="header_img col-md-2 p-2" src={icon2} />

        <div className="header_box d-flex flex-column justify-content-between flex-grow-1 ">
          <span>ກຳລັງແລ່ນ</span>
          <span>{loading ? <Spinner animation="border" variant="#EA3F30" size="sm" /> : countLoading} ຄັນ</span>
        </div>
      </div>
    </>
  );
}
