import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable, checkUserRole } from "../../../utils";
import TableData from "../../../utils/components/TableData";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { ChildPath, ParentPath } from "../../../routes/Path";
import { AddingBranchesToRouteLocationProps_destinationVehicles } from "./component";

export default function RecordBranches() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [params, setParams] = useSearchParams();
  const getBranchID = params.get("routeID");
  const getTitle = params.get("title");
  const getIsDay = params.get("isDay");

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_BRANCH_ROUTE_LOCATION, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          branch_name : searchValue ? searchValue : undefined
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, searchValue, pageNumber, reloading, numberRow]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.officeBranches?.data || []);
    setTotal(data?.officeBranches?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li
            className="breadcrumb-item active"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.destinationVehicle}`)}
          >
            ຈັດການສາຍຂົນສົ່ງ
          </li>
          <li className="breadcrumb-item active">{getTitle}</li>
        </ol>
      </div>
      <TableData
        total={total}
        loading={loading}
        pageNumber={pageNumber}
        numberRow={numberRow}
        onSearchText={(val) => {
          setSearchValue(val || "");
        }}
        header={
          <tr>
            <th className="text-center text-nowrap">ລຳດັບ-------</th>
            <th className=" text-nowrap">ຊື່ສາຂາ</th>
            <th className=" text-nowrap">ທີ່ຢູ່ສາຂາ</th>
            <th className=" text-nowrap">ລະຫັດສາຂາ</th>
            {checkUserRole(["SUPER_ADMIN"]) ? (
              <th className="text-center text-nowrap">
                <i className="fas fa-gear"></i>ຈັດການ
              </th>
            ) : null}
          </tr>
        }
        body={
          <>
            {items?.map((value, index) => (
              <tr key={index}>
                <td className="text-center text-nowrap">
                  {index + 1} {value?.branch_id}
                </td>
                <td className=" text-nowrap">{value?.branch_name || "---"}</td>
                <td className=" text-nowrap">{value?.branch_address || "----"}</td>
                <td className=" text-nowrap">{value?.branch_code || "---"}</td>
                {checkUserRole(["SUPER_ADMIN"]) ? (
                  <>
                    <td className="text-center text-nowrap">
                      <AddingBranchesToRouteLocationProps_destinationVehicles
                        _id={value?.id_branch}
                        BranchID={getBranchID}
                        isDay={getIsDay}
                        onSuccess={() => setReloading(!reloading)}
                        // disabled={value}
                      />
                    </td>
                  </>
                ) : null}
              </tr>
            ))}
          </>
        }
      />
    </React.Fragment>
  );
}
