import { createContext, useContext } from "react";

export type ContextValues = {
  deleteItemBack: boolean;
};

type KeyName =
  | "completed"
  | "deleteItemBack";

type ContextType = {
  reloadTotal: ContextValues;
  reloadData: ContextValues;
  setReloadTotal: (key: KeyName) => void;
  setReloadData: (key: KeyName) => void;
};

export const AppContext = createContext<ContextType>({
  reloadTotal: {
    deleteItemBack: false,
  },
  reloadData: {
    deleteItemBack: false,
  },
  setReloadTotal: () => {},
  setReloadData: () => {},
});

export const useAppContext = () => useContext(AppContext);
