import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../utils";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import Schema from "./schema";
type CompanyDriverPartnerValue = {
  cp_name: string;
  cp_code: string;
  cp_phoneNumber: number;
  cp_address: string;
  cp_remark: string;
  servicesMoney: number;
  status: number;
  createdBy: number;
};

type CompanyDriverPartnerError = {
  cp_name?: string;
  cp_code?: string;
  cp_phoneNumber?: string;
  cp_address?: string;
  servicesMoney?: string;
};

type FormLayoutProp = {
  values: CompanyDriverPartnerValue;
  errors: CompanyDriverPartnerError;
  handleChange: any;
  setFieldValue: any;
};

let userID = getStaffLogin()?._id;

const FormLayout = ({ values, errors, handleChange, setFieldValue }: FormLayoutProp) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>ຊື່ບໍລິສັດ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="cp_name"
          placeholder="ຊື່ບໍລິສັດ"
          onChange={handleChange}
          value={values?.cp_name}
          isInvalid={errors?.cp_name ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ອັກສອນຫຍໍ້ຂອງບໍລິສັດ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="cp_code"
          placeholder="ອັກສອນຫຍໍ້"
          onChange={handleChange}
          value={values?.cp_code}
          isInvalid={errors?.cp_code ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ເບີໂທບໍລິສັດ {InvalidText()}</Form.Label>
        <Form.Control
          type="number"
          name="cp_phoneNumber"
          placeholder="ເບີໂທ"
          onWheel={(e: any) => e.target.blur()}
          onChange={handleChange}
          value={values?.cp_phoneNumber}
          isInvalid={errors?.cp_phoneNumber ? true : false}
          style={{ appearance: "textfield" }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ຄ່າບໍລິຫານ(%) {InvalidText()}</Form.Label>
        <Form.Control
          type="number"
          name="servicesMoney"
          placeholder="ຄ່າບໍລິຫານ(%)"
          onWheel={(e: any) => e.target.blur()}
          onChange={handleChange}
          value={values?.servicesMoney}
          isInvalid={errors?.servicesMoney ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ທີ່ຢູ່ບໍລິສັດ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="cp_address"
          placeholder="ທີ່ຢູ່"
          onChange={handleChange}
          value={values?.cp_address}
          isInvalid={errors?.cp_address ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ໝາຍເຫດ</Form.Label>
        <Form.Control type="text" name="cp_remark" placeholder="ໝາຍເຫດ" as="textarea" rows={3} onChange={handleChange} value={values?.cp_remark} />
      </Form.Group>
    </>
  );
};

const initialValues: CompanyDriverPartnerValue = {
  cp_name: "",
  cp_code: "",
  cp_phoneNumber: 0,
  cp_address: "",
  cp_remark: "",
  servicesMoney: 0,
  status: 0,
  createdBy: Number(userID),
};
const validate = (values: CompanyDriverPartnerValue) => {
  const errors: CompanyDriverPartnerError = {};
  if (!values?.cp_name) errors.cp_name = "ກະລຸນາປ້ອນຊື່ບໍລິສັດ";
  if (!values?.cp_code) errors.cp_code = "ກະລຸນາປ້ອນລະຫັດຫຍໍ້";
  if (!values?.cp_phoneNumber) errors.cp_phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
  if (!values?.servicesMoney) errors.servicesMoney = "ກະລຸນາປ້ອນຄ່າບໍລິການ";
  if (!values?.cp_address) errors.cp_address = "ກະລຸນາປ້ອນທີ່ຢູ່";

  return errors;
};

// create component
export const Create = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_DRIVER_PARTners);

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<CompanyDriverPartnerValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values) => {
      try {
        await createData({
          variables: { data: values },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add" />
        ເພີ່ມຂໍ້ມູນບໍລິສັດ
      </Button>

      <Modal show={show}>
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ເພີ່ມຂໍ້ມູນບໍລິສັດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  cp_id: number;
  onSuccess: () => void;
  className?: string;
};

export const Delete = ({ cp_id, onSuccess, className }: DeleteProps) =>{
  const [updateData, { loading }] = useMutation(Schema.DELETE_DRIVER_PARTners);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  cp_id: cp_id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};
export const Update = ({ item, onSuccess, className }: UpdateProps) => {

  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_DRIVER_PARTNER);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<CompanyDriverPartnerValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: { data: values, where: { cp_id: item?.cp_id } },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      cp_name: item?.cp_name || "",
      cp_code: item?.cp_code || "",
      cp_phoneNumber: item?.cp_phoneNumber || 0,
      cp_address: item?.cp_address || "",
      cp_remark: item?.cp_remark || "",
      servicesMoney: item?.servicesMoney || 0,
      status: item?.status || 0,
      createdBy: item?.createdBy?._id || 0,
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show}>
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ເພີ່ມຂໍ້ມູນບໍລິສັດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


