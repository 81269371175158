import { gql } from "@apollo/client";
export default class Schema {
  static QUERY_ITEM_MODIFY_PRICE = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          locationCode
          isPublic
          note
          createdAt
          priceDay
          priceNight
          runOn
          lg_isDay
          checkRoute
          km
          isAuction
          petroleum
          code
          priceNew
        }
      }
    }
  `;
  static UPDATE_PRICE_DAY = gql`
    mutation UpdateRouteLocation($where: WhereById!, $data: routeLocationInput) {
      updateRouteLocation(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static UPDATE_PRICE_ONE = gql`
  mutation UpdateRouteLocationOne($where: WhereById!, $data: routeLocationInput) {
  updateRouteLocationOne(where: $where, data: $data) {
    status
    data
  }
}
  `;

  static QUERY_ROUTE_LOCATION_LOG = gql`
    query RouteLocationLogs($where: routeLocationLogInput, $limit: Int, $skip: Int) {
      routeLocationLogs(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          priceDayOld
          priceNightOld
          priceNightNew
          priceDayNew
          changeDate
          routeID {
            _id
            title
          }
          detail
          code
          changeBy {
            _id
            first_name
            last_name
            gender
          }
        }
      }
    }
  `;

  static INSERT_ALL_PRICE = gql`
    mutation CreateRouteLocationLog($data: routeLocationLogInput!) {
      createRouteLocationLog(data: $data) {
        status
        data
      }
    }
  `;

  static UPDATE_ALL_PRICE = gql`
    mutation UpdateRouteLocationLog($where: WhereById!, $data: routeLocationLogInput) {
      updateRouteLocationLog(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static UPDATE_ROUTES_LOCATION_MANY = gql`
    mutation UpdateRouteLocationMany($where: WhereByMultipleId!, $data: routeLocationInput) {
      updateRouteLocationMany(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static UPDATE_ROUTE_LOCATION_PRICE = gql`
  query UpdatePriceNews($where: updatePriceNewInput, $limit: Int, $skip: Int) {
    updatePriceNews(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        title
        locationCode
        isPublic
        note
        createdAt
        priceDay
        priceNight
        runOn
        lg_isDay
        checkRoute
        km
        isAuction
        petroleum
        code
        priceNew
      }
    }
  }
  `;
}
