import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_COUNT_STATUS = gql`
    query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
      shippingRoutes(where: $where, limit: $limit, skip: $skip) {
        total
      }
    }
  `;
  static QUERY_CARD = gql`
  query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
    shippingRoutes(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        sp_id
        isDif
        pay
        isDay
        difNote
        newLocation
        driverID {
          _id
          first_name
          last_name
          gender
        }
        routeLocationID {
          _id
          title
        }
        vehicleID {
          _id
          vehicleName
        }
        createdAt
        createdBy {
          _id
          first_name
          last_name
          gender
        }
        checkRoute
        amountValue
        startTime
        endTime
        acceptStatus
        acceptBy {
          _id
          first_name
          last_name
          gender
        }
        checkDate
        noID
        noRID
        inSide
        totalItem
        billNoRout
        acceptArrivedBy {
          _id
          first_name
          last_name
          gender
        }
        driverTwo {
          _id
          first_name
          last_name
          gender
        }
        centerRoute {
          st_id
          centerName
          centerCode
        }
      }
    }
  }
  `;
}
