import React, { useEffect, useRef, useState } from "react";
import { ParentPath } from "../../routes/Path";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { Calculator, currency, formatDash, formatDate, formatDateTime, formatDateUpdate, generateBillNo, getStaffLogin } from "../../utils";
import _ from "lodash";
import { Image, Modal, Spinner } from "react-bootstrap";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Logo from "../../assets/images/ans-logo-red.svg";
import HikingSignature from "./signature/hikingSignature";
import HRSignature from "./signature/hrSignature";
import SummarySignatureTotal from "./signature/SummaryTotal";
import { url_upload_Profile } from "../../configs/Environment";

export default function ListShippingsTwo() {
  const navigate = useNavigate();
  const [items, setItems] = useState<any[]>();
  const [params, setParams] = useSearchParams();
  const getID = params.get("ConsolidateID");
  const [search, setSearch] = useState("");
  const [eventSearch, setEventSearch] = useState(false);

  const [fetchData, { data, loading }] = useLazyQuery(Schema.SHIPPING_ROUTE_CONSOLIDATE, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    fetchData({
      variables: {
        where: {
          consolidate_ID: Number(getID) ? Number(getID) : undefined,
        },
      },
    });
  }, [fetchData, getID]);
  useEffect(() => {
    setItems(data?.shippingRoutes?.data || []);
  }, [data]);

  //  search objects

  useEffect(() => {
    const results = data?.shippingRoutes?.data;
    if (search) {
      const filter = results?.filter((obj: any) => {
        if (obj?.routeLocationID?.title?.includes(search) || obj?.vehicleID?.vehicleName?.includes(search)) {
          return obj;
        }
      });
      setItems(filter);
    } else {
      setItems(results);
    }
  }, [eventSearch, data]);

  const Petroleum = _.sumBy(items, "petroleum");
  const TotalAmountValue = _.sumBy(items, "amountValue");
  const TotalItems = _.sumBy(items, "totalItem");
  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ລາຍງານຂໍ້ມູນການສະຫລຸບທັງໝົດ</li>
          <li className="breadcrumb-item">ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ</li>
        </ol>
      </div>
      <div className="container-print">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-3">
                <button className="btn btn-primary me-2" onClick={() => navigate(`${ParentPath.summarizeDestinationVehicleTwo}`)} disabled={loading}>
                  <i className="fas fa-arrow-left"></i> ກັບຄືນ
                </button>
                <ExportRouteList />
              </div>
              <div className="col-sm-9">
                <h4 style={{ marginInlineStart: "20%" }}>ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ</h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div style={{ marginBottom: 10, width: 450, float: "right" }} className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="ຄົ້ນຫາຂໍ້ມູນ........."
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
              <button type="button" className="btn btn-primary" onClick={() => setEventSearch(!eventSearch)} disabled={!search}>
                {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-search" />}ຄົ້ນຫາ
              </button>
            </div>

            <div className="table table-responsive">
              <table className="table" border={1}>
                <thead className="text-white">
                  <tr>
                    <th className="text-nowrap">ລຳດັບ</th>
                    <th className="text-nowrap">ເບີລົດ</th>
                    <th className="text-nowrap">ຜູ້ຈັດຖ້ຽວ</th>
                    <th className="text-nowrap">ສາຍທີ່ແລ່ນ</th>
                    <th className="text-nowrap">ເລກທີໂກຕ່າ</th>
                    <th className="text-nowrap">ປະເພດສາຍ</th>
                    <th className="text-nowrap">ກະອອກລົດ</th>
                    <th className="text-nowrap">ປະເພດຖ້ຽວ</th>
                    <th className="text-nowrap">ກວດສອບ</th>
                    <th className="text-nowrap">ກວດສອບໂດຍ </th>
                    <th className="text-nowrap">ຈຳນວນພັດສະດຸ </th>
                    <th className="text-nowrap">ນ້ຳມັນ(ລິດ)</th>
                    <th className="text-nowrap">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                    <th className="text-nowrap">ເວລາອອກຕົ້ນທາງ</th>
                    <th className="text-nowrap">ຮອດປາຍທາງ</th>
                    <th className="text-nowrap">ໃຊ້ເວລາເດີນທາງ</th>
                    <th className="text-nowrap">ຢືນຢັນຮອດປາຍທາງ</th>
                    <th className="text-nowrap">ວັນທີກວດສອບ</th>
                    <th className="text-nowrap">ວັນທີສະແກນອອກ</th>
                    <th className="text-nowrap">ໝາຍເຫດ</th>
                  </tr>
                </thead>
                <tbody>
                  {items?.map((value, index) => (
                    <>
                      <tr key={index}>
                        <td className="no">{index + 1}</td>
                        <td className="text-nowrap">{value?.vehicleID?.vehicleName || "-----"}</td>
                        <td className="text-nowrap">
                          {value?.createdBy?.first_name || "----"} {value?.createdBy?.last_name || "---"}
                        </td>
                        <td className="text-nowrap">{value?.routeLocationID?.title || "---"}</td>
                        <td className="text-nowrap">{value?.billNoRout || "----"}</td>
                        <td className="text-nowrap">{value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</td>
                        <td className="text-nowrap">{value?.isDay === "1" ? "ພາກແລງ" : " ພາກເຊົ້າ"}</td>
                        <td className="text-nowrap">{value?.isDif === "1" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>
                        <td className="text-nowrap">
                          {value?.checkScan === 0 ? (
                            <span className="text-danger text-nowrap">ຍັງບໍ່ກວດສອບ</span>
                          ) : value?.checkScan === 1 ? (
                            <span className="text-success text-nowrap">ກວດສອບ(ຜ່ານ)</span>
                          ) : value?.checkScan === 2 ? (
                            <span className="text-danger text-nowrap">ກວດສອບ(ບໍ່ຜ່ານ)</span>
                          ) : null}
                        </td>
                        <td className="text-nowrap">
                          {value?.checkBy?.first_name || "---"}
                          {value?.checkBy?.last_name || "---"}
                        </td>
                        <td className="text-nowrap">{currency(value?.totalItem || 0)}</td>
                        <td className="text-nowrap">{value?.petroleum || 0}</td>
                        <td className="text-nowrap">{currency(value?.amountValue ? value?.amountValue : 0)}</td>
                        <td className="text-nowrap">{value?.startTime ? formatDash(value.startTime) : "----"}</td>
                        <td className="text-nowrap">{value?.endTime ? formatDash(value.endTime) : "----"}</td>
                        <td className="text-nowrap">{value?.endTime ? Calculator(value?.startTime, value?.endTime) : "-----"}</td>
                        <td className="text-nowrap">
                          {value?.newLocation === "NO" ? (
                            "----"
                          ) : (
                            <>
                              {value?.newLocation === "NO" ? (
                                <div className="btn btn-success disable-cursor btn-sm">
                                  <i className="fas fa-check-circle"></i> ລົດກຳລັງອອກເດີນທາງ
                                </div>
                              ) : (
                                <div className="btn btn-success disable-cursor btn-sm">
                                  <i className="fas fa-check-circle"></i> ຮອດປາຍທາງເເລ້ວ
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td className="text-nowrap">{value?.checkDate ? formatDate(value?.checkDate) : "-----"}</td>
                        <td className="text-nowrap">{value?.dateScan_out ? formatDateTime(value.dateScan_out) : "----"}</td>
                        <td className="text-nowrap">{value?.difNote || "----"}</td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td colSpan={10} className="text-center">
                      ຍອດລວມທັງໝົດ
                    </td>
                    <td className="text-nowrap">{currency(TotalItems || 0)} ລາຍການ</td>
                    <td>{currency(Petroleum || 0)} ລິດ</td>
                    <td colSpan={8}>{currency(TotalAmountValue || 0)} ກີບ</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

// export table to excel

function ExportRouteList() {
  const [show, setShow] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  const [params, setParams] = useSearchParams();
  const getData: any = params.get("data");
  const Items = JSON.parse(getData);
  let bilNoToday = moment().format("YYYYMMDD");
  const getGenerate = generateBillNo("");
  const SaveID = params.get("ConsolidateID");
  const [results, setResults] = useState<any[]>();
  const [signatureSummary, setSignatureSummary] = useState<any>();
  const [signatureHiking, setSignatureHiking] = useState<any>();
  const [signatureHr, setSignatureHr] = useState<any>();
  const [searchParams] = useSearchParams();
  const _startConsolidate = searchParams.get("_startConsolidate");
  const _endConsolidate = searchParams.get("_endConsolidate");

  const [fetchResults, { data, loading }] = useLazyQuery(Schema.SHIPPING_ROUTE_CONSOLIDATE, { fetchPolicy: "cache-and-network" });

  const [querySignatureSummary, { data: imageSignatureSummary }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureHiking, { data: imageSignatureHiking }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });
  const [querySignatureHr, { data: imageSignatureHr }] = useLazyQuery(Schema.QUERY_SIGNATURE, { fetchPolicy: "cache-and-network" });

  const NewNoID = imageSignatureSummary?.signatures?.data[0]?.noID;

  const SignatureAdmin = imageSignatureHiking?.signatures?.data[0]?.signatureTwo;
  const SignatureManager = imageSignatureHr?.signatures?.data[0]?.signatureTwo;
  const SignatureSummary = imageSignatureSummary?.signatures?.data[0]?.signatureTwo;

  const vehicle = Items?.consolidateVehicleID?._id;

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureSummary({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "SUMMARIES",
        },
        limit: 1,
      },
    });
  }, [show, _startConsolidate, _endConsolidate, vehicle, querySignatureSummary]);

  useEffect(() => {
    setSignatureSummary(imageSignatureSummary?.signatures?.data || []);
  }, [imageSignatureSummary]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureHiking({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "HIKING",
        },
        limit: 1,
      },
    });
  }, [show, _startConsolidate, _endConsolidate, vehicle, querySignatureHiking]);

  useEffect(() => {
    setSignatureHiking(imageSignatureHiking?.signatures?.data || []);
  }, [imageSignatureHiking]);

  useEffect(() => {
    if (vehicle === undefined) return;
    querySignatureHr({
      variables: {
        where: {
          dateBetween: [_startConsolidate, _endConsolidate],
          vehicleID: Number(vehicle) ? Number(vehicle) : undefined,
          driverStatus: "HR",
        },
        limit: 1,
      },
    });
  }, [querySignatureHr, show, _startConsolidate, _endConsolidate, vehicle]);

  useEffect(() => {
    setSignatureHr(imageSignatureHr?.signatures?.data || []);
  }, [imageSignatureHr]);

  useEffect(() => {
    if (!show) return;
    fetchResults({
      variables: {
        where: {
          consolidate_ID: Number(SaveID) ? Number(SaveID) : undefined,
        },
      },
    });
  }, [fetchResults, SaveID, show]);

  useEffect(() => {
    setResults(data?.shippingRoutes?.data || []);
  }, [data]);

  const Petroleum = _.sumBy(results, "petroleum");
  const TotalAmountValue = _.sumBy(results, "amountValue");
  const TotalItems = _.sumBy(results, "totalItem");

  return (
    <React.Fragment>
      <button type="button" className="btn btn-secondary" onClick={() => setShow(true)} disabled={loading}>
        <i className="fas fa-print"></i>ພິມລາຍງານ
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          ພິມລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4">
              <div className="flex-control-group">
                <button onClick={handlePrint} type="button" className="btn btn-dark ml-3 me-2">
                  <BiPrinter /> ພິມລາຍງານ
                </button>
                <DownloadTableExcel filename="ຂໍ້ມູນພະນັກງານ" sheet="users" currentTableRef={elmRef.current}>
                  <button className="btn btn-success">
                    <BsFileEarmarkExcel /> Export excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
            <div className="col-sm-8"></div>
          </div>
          <div ref={elmRef} id="table-to-xls" className="container-print mt-5" style={{ fontFamily: "phetsarath OT" }}>
            <h4 className="text-center">ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫລຸບແລ້ວ</h4>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div>
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະຫວ່າງ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                {NewNoID  ? (
                  <>
                    <div className="text-end">ເລກທີ່: {NewNoID}</div>
                  </>
                ) : (
                  <>
                    <div className="text-end">
                      ເລກທີ່: ANS-L{bilNoToday}
                      {getGenerate}
                    </div>
                  </>
                )}
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            <h4 className="mt-3">ລາຍງານການຄົ້ນຫາຂໍ້ມູນ ໃນລາຍການສະຫລຸບຖ້ຽວລົດ</h4>
            <table className="table" border={1}>
              <thead>
                <tr>
                  <th className="text-nowrap">ລາຍການສະຫລຸບວັນທີ</th>
                  <th className="text-nowrap">ຖ້ຽວລົດວັນທີ</th>
                  <th className="text-nowrap"> ຊື່ໂຊເຟີລົດ</th>
                  <th className="text-nowrap">ບໍລິສັດ</th>
                  <th className="text-nowrap"> ການຊຳລະ</th>
                  <th className="text-nowrap"> ກະອອກລົດ</th>
                  <th className="text-nowrap"> ປະເພດສາຍ</th>
                  <th className="text-nowrap"> ເບີລົດ</th>
                  <th className="text-nowrap"> ຖ້ຽວລົດ</th>
                  <th className="text-nowrap"> ສູນ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-nowrap">
                    {_startConsolidate || "---"} ເຖິງ {_endConsolidate || "---"}
                  </td>
                  <td className="text-nowrap">{Items?.consolidateDate ? formatDateUpdate(Items?.consolidateDate) : ""}</td>
                  <td className="text-nowrap">
                    <div>
                      {Items?.consolidateDriverID?.first_name} {Items?.consolidateDriverID?.last_name}
                    </div>
                    <br />
                    <div>
                      {Items?.driverTwo?.first_name} {Items?.driverTwo?.last_name}
                    </div>
                  </td>
                  <td className="text-nowrap">{Items?.consolidateDriverID?.stStatus?.cp_name || Items?.driverTwo?.stStatus?.cp_name}</td>
                  <td className="text-nowrap">{Items?.consolidatePaidStatus === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : "ຊຳລະແລ້ວ"}</td>
                  <td className="text-nowrap">{Items?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</td>
                  <td className="text-nowrap">{Items?.checkRoute === "IN" ? "ສາຍໃນ" : Items?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</td>
                  <td className="text-nowrap">{Items?.consolidateVehicleID?.vehicleName || ""}</td>
                  <td className="text-nowrap">{Items?.isDif === "TRUE" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>
                  <td className="text-nowrap">{Items?.consolidateDriverID?.center?.centerName}</td>
                </tr>
              </tbody>
            </table>
            &nbsp;&nbsp;
            <h4 className="mt-3">ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ</h4>
            <table className="table" border={1}>
              <thead className="text-white">
                <tr style={{ fontSize: "11px" }}>
                  <th>ລຳດັບ</th>
                  <th>ເບີລົດ</th>
                  <th>ຜູ້ຈັດຖ້ຽວ</th>
                  <th>ສາຍທີ່ແລ່ນ</th>
                  <th>ເລກທີໂກຕ່າ</th>
                  <th>ປະເພດສາຍ</th>
                  <th>ກະອອກລົດ</th>
                  <th>ປະເພດຖ້ຽວ</th>
                  <th>ກວດສອບ</th>
                  <th>ກວດສອບໂດຍ </th>
                  <th>ຈຳນວນພັດສະດຸ </th>
                  <th>ນ້ຳມັນ(ລິດ)</th>
                  <th>ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                  <th>ວັນທີກວດສອບ</th>
                  <th>ວັນທີສະແກນອອກ</th>
                  <th>ໝາຍເຫດ</th>
                </tr>
              </thead>
              <tbody>
                {results?.map((value, index) => (
                  <>
                    <tr key={index}>
                      <td className="no">{index + 1}</td>
                      <td>{value?.vehicleID?.vehicleName || "-----"}</td>
                      <td>
                        {value?.createdBy?.first_name || "----"} {value?.createdBy?.last_name || "---"}
                      </td>
                      <td>{value?.routeLocationID?.title || "---"}</td>
                      <td>{value?.billNoRout || "----"}</td>
                      <td>{value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</td>
                      <td>{value?.isDay === "1" ? "ພາກແລງ" : " ພາກເຊົ້າ"}</td>
                      <td>{value?.isDif === "1" ? "ຖ້ຽວເສີມ" : "ຖ້ຽວປົກກະຕິ"}</td>
                      <td>
                        {value?.checkScan === 0 ? (
                          <span className="text-danger text-nowrap">ຍັງບໍ່ກວດສອບ</span>
                        ) : value?.checkScan === 1 ? (
                          <span className="text-success text-nowrap">ກວດສອບ(ຜ່ານ)</span>
                        ) : value?.checkScan === 2 ? (
                          <span className="text-danger text-nowrap">ກວດສອບ(ບໍ່ຜ່ານ)</span>
                        ) : null}
                      </td>
                      <td>
                        {value?.checkBy?.first_name || "---"}
                        {value?.checkBy?.last_name || "---"}
                      </td>
                      <td>{currency(value?.totalItem || 0)}</td>
                      <td>{value?.petroleum || 0}</td>
                      <td>{currency(value?.amountValue ? value?.amountValue : 0)}</td>
                      <td>{value?.checkDate ? formatDate(value?.checkDate) : "-----"}</td>
                      <td>{value?.dateScan_out ? formatDateTime(value.dateScan_out) : "----"}</td>
                      <td>{value?.difNote || "----"}</td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td colSpan={10} className="text-center">
                    ຍອດລວມທັງໝົດ
                  </td>
                  <td>{currency(TotalItems || 0)} ລາຍການ</td>
                  <td>{currency(Petroleum || 0)} ລິດ</td>
                  <td colSpan={4}>{currency(TotalAmountValue || 0)} ກີບ</td>
                </tr>
              </tbody>
            </table>
            <div className="row" style={{ height: 200 }}>
              <div className="col-sm-4 mt-4 text-center">
                <h6>ຫົວໜ້າພະແນກຈັດຈ້າງ</h6>
              </div>
              <div className="col-sm-4  mt-4 text-center">
                <h6>ຫົວໜ້າພະແນກບຸກຄະລາກອນ</h6>
              </div>
              <div className="col-sm-4  mt-4 text-center">
                <h6>ຜູ້ສະຫຼຸບ</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 text-center">
                {SignatureAdmin ? (
                  <>
                    <div className="text-center" style={{ marginTop: -180 }}>
                      {signatureHiking?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={280} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div style={{ marginTop: -100 }}>
                      <HikingSignature
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        NewNoID={NewNoID}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                        
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-4 text-center" style={{ marginTop: -180 }}>
                {SignatureManager ? (
                  <>
                    <div className="text-center">
                      {signatureHr?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={220} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div style={{ marginTop: 80 }}>
                      <HRSignature
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        NewNoID={NewNoID}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                        
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-sm-4 text-center">
                {SignatureSummary ? (
                  <>
                    <div className="text-center" style={{ marginTop: -180 }}>
                      {signatureSummary?.map((value: any, index: number) => (
                        <>
                          <Image src={url_upload_Profile + value?.signatureTwo} width={220} height={180} />
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div style={{ marginTop: -100 }}>
                      <SummarySignatureTotal
                        bilNoToday={bilNoToday}
                        getGenerate={getGenerate}
                        startDate={_startConsolidate}
                        endDate={_endConsolidate}
                        vehicle={Items?.consolidateVehicleID?._id}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
