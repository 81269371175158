import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import Schema from "./Schema";
import TableData from "../../utils/components/TableData";
import { currency, formatDate, RootVariable } from "../../utils";
import { useSearchParams } from "react-router-dom";
import SearchDateRangeBetweenConsolidate from "../../utils/components/SearchDateRangeBetweenConsolidate";
import SearchDateRangeConsolidateSummary from "../../utils/components/SearchDateRangeConsolidateSummary";
import { Form } from "react-bootstrap";
import SelectDriverRoute from "../../utils/components/SelectDriverRoute";
import moment from "moment";
import { IoCloudDownload } from "react-icons/io5";
import { DownloadTableExcel } from "react-export-table-to-excel";
import DownloadFiles from "./downloadFile";

export default function ExportIBank() {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [vehicle, setVehicle] = useState<any>();
  const [paid, setPaid] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateConsolidateBetween, setDateConsolidateBetween] = useState<string[]>([]);
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [QUERY_IBANK, { data, loading }] = useLazyQuery(Schema.QUERY_IBANK_REPORT, { fetchPolicy: "cache-and-network" });
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const vehicleIDs = vehicle === 0 ? undefined : Number(vehicle);
    QUERY_IBANK({
      variables: {
        where: {
          consolidateVehicleID: vehicleIDs ? vehicleIDs : undefined,
          consolidatePaidStatus: paid ? paid : undefined,
          consolidatedDateBetween: [dateConsolidateBetween[0], dateConsolidateBetween[1]],
          consolidatedDateSummary: [dateBetween[0], dateBetween[1]],
          driverConfirmed: 1,
          isDif: ["TRUE", "FALSE"],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [QUERY_IBANK, dateConsolidateBetween, dateBetween, paid, vehicle, searchValue, numberRow, pageNumber]);

  useEffect(() => {
    setItems(data?.ReportSumIBank?.data || []);
    setTotal(data?.ReportSumIBank?.total || 0);
  }, [data]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _vehicle = searchParams.get("vehicle");
    const _paid = searchParams.get("paid");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
    const decodeVehicle = _vehicle ? atob(_vehicle) : undefined;
    const decodePaid = _paid ? atob(_paid) : undefined;

    setPaid(decodePaid ? decodePaid : undefined);
    setVehicle(decodeVehicle ? decodeVehicle : undefined);
    const _startConsolidate = searchParams.get("startConsolidateDate");
    const _endConsolidate = searchParams.get("endConsolidateDate");
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");

    if (_startConsolidate && _endConsolidate) setDateConsolidateBetween([_startConsolidate, _endConsolidate]);
    else setDateConsolidateBetween([moment().clone().startOf("month").format("YYYY-MM-DD"), moment().clone().endOf("month").format("YYYY-MM-DD")]);

    if (_startDate && _endDate) {
      setDateBetween([_startDate, _endDate]);
    } else {
      setDateBetween([moment().clone().startOf("month").format("YYYY-MM-DD"), moment().clone().format("YYYY-MM-DD")]);
    }
  }, [searchParams]);

  return (
    <>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-6">
                <h3>ຈັດການຂໍ້ມູນລາຍງານເລກບັນຊີຂອງໂຊເຟີລົດ</h3>
              </div>
              <div className="col-sm-6">
                <div style={{ float: "right" }}>
                  <DownloadFiles data={items} startDate={dateConsolidateBetween[0]} endDate={dateConsolidateBetween[1]} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-1">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-3">
                    <SearchDateRangeBetweenConsolidate sMonth eMonth />
                  </div>
                  <div className="col-sm-3">
                    <SearchDateRangeConsolidateSummary sDay eDay />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ຄົ້ນຫາຕາມການຊຳລະ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("paid", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="PAID">ຊຳລະແລ້ວ</option>
                      <option value="UNPAID">ຍັງບໍ່ຊຳລະ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ຄົ້ນຫາຕາມເບີລົດ</label>
                    <SelectDriverRoute
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableData
            total={total}
            loading={loading}
            pageNumber={pageNumber}
            numberRow={numberRow}
            onSearchText={(val) => {
              setSearchValue(val || "");
            }}
            header={
              <tr>
                <th className="text-nowrap">ລຳດັບ</th>
                <th className="text-nowrap">ຊື່ໂຊເຟີ້ລົດ</th>
                <th className="text-nowrap">ຊື່ສາຍ</th>
                <th className="text-nowrap">ເງິນສຸດທິຂອງໂຊເຟີ(ກີບ)</th>
                <th className="text-nowrap">ຊື່ບັນຊີທະນາຄານ</th>
                <th className="text-nowrap">ເລກບັນຊີທະນາຄານ</th>
                <th className="text-nowrap">ວັນທີ່ສະຫຼຸບ</th>
              </tr>
            }
            body={
              <>
                {items?.map((value: any, index: number) => (
                  <tr key={index} className={value?.consolidatePaidStatus === "PAID" ? "table-success" : ""}>
                    <td className="text-center">
                      <div className="text-center">{index + 1}</div>
                    </td>
                    <td className="text-nowrap">
                      {value?.consolidateDriverID?.first_name} {value?.consolidateDriverID?.last_name}
                    </td>
                    <td className="text-nowrap">{value?.consolidateRouteID?.title}</td>
                    <td className="text-nowrap text-end">{currency(value?.paymentBalanceAmount)}</td>
                    <td className="text-nowrap">{value?.consolidateDriverID?.account_bank_name}</td>
                    <td className="text-nowrap">{value?.consolidateDriverID?.account_bank_number}</td>
                    <td className="text-nowrap">{formatDate(value?.consolidateDate)}</td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
