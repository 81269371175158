import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_SUMMARY_SHIPPING_ROUTE = gql`
    query ConsolidateSummaries($where: consolidateSummaryWhereInput, $limit: Int, $skip: Int) {
      consolidateSummaries(where: $where, limit: $limit, skip: $skip) {
        TotalVehicle
        SumTotalDrivers
        SumTotalBalance
        petroleum
        consolidateTotalRoute
        SumTotalAmountRoutes
        extraPayment
        SumTotalSalary
        SumDeductionTotal
        consolidateAmountRouteService
        total
        data {
          consolidateID
          consolidateDriverID {
            _id
            first_name
            last_name
            account_bank_name
            account_bank_number
            gender
            stStatus {
              cp_id
              servicesMoney
              cp_name
            }
            center {
              st_id
              centerName
            }
          }
          consolidateVehicleID {
            _id
            vehicleName
          }
          consolidateRouteID {
            _id
            title
            petroleum
            priceDay
            priceNight
          }
          consolidateTotalRoute
          consolidateAmountRoute
          consolidateDate
          consolidateBy {
            _id
            first_name
            last_name
            gender
          }
          deductionPayment
          extraPayment
          borrowPayment
          bonusPayment
          textIncome
          paymentBalanceAmount
          consolidatePaidStatus
          consolidatePaidDate
          paidBy {
            _id
            first_name
            last_name
            gender
          }
          isDif
          consolidateUpdatedAt
          isDay
          checkRoute
          pay
          petroleum
          startDate
          endDate
          noID
          auditorConfirm
          auditorID
          auditorConfirmDate
          driverTwo {
            _id
            first_name
            last_name
            account_bank_name
            account_bank_number
            gender
            stStatus {
              cp_id
              servicesMoney
              cp_name
            }
            center {
              st_id
              centerName
            }
          }
          basicSalaryOne
          basicSalaryTwo
          consolidateAmountRouteTwo
          deductionPaymentTwo
          extraPaymentTwo
          bestExtraAmountTwo
          bestExtraAmount
          paymentBalanceAmountTwo
          centerID {
            st_id
            centerName
          }
          companyID {
            cp_id
            servicesMoney
            cp_name
          }
          driverConfirmedTwo
          driverConfirmed
        }
      }
    }
  `;

  static UPDATE_CANCEL_CONSOLIDATE_ROUTE = gql`
    mutation UpdateConsolidateSummary($where: whereCID!, $data: consolidateSummaryInput) {
      updateConsolidateSummary(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static SHIPPING_ROUTE_CONSOLIDATE = gql`
    query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
      shippingRoutes(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          sp_id
          isDif
          difNote
          driverID {
            first_name
            last_name
            gender
          }
          routeLocationID {
            title
          }
          vehicleID {
            vehicleName
          }
          createdAt
          createdBy {
            first_name
            last_name
            gender
          }
          checkRoute
          amountValue
          startTime
          dateScan_out
          endTime
          checkScan
          acceptStatus
          acceptBy {
            first_name
            last_name
            gender
          }
          checkDate
          noID
          noRID
          totalItem
          billNoRout
          petroleum
          checkBy {
            first_name
            last_name
            gender
          }
          acceptArrivedBy {
            _id
            first_name
            last_name
            gender
          }
          driverTwo {
            _id
            first_name
            last_name
            gender
          }
          centerRoute {
            st_id
            centerName
            centerCode
          }
        }
      }
    }
  `;
  static EXTRA_PAYMENT_CONSOLIDATE = gql`
    query ExtraPayments($where: extraPaymentWhereInput, $limit: Int, $skip: Int) {
      extraPayments(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          exTraID
          extraDriverID {
            _id
            first_name
            last_name
            gender
          }
          exTraAmount
          exTraDate
          exTraNote
          exTraCheck
          noID
          isExtra
          exTraCreatedAt
          exTraStatus
          exTraCreatedBy {
            last_name
            _id
            first_name
            gender
          }
          consolidate_ID {
            consolidateID
          }
        }
      }
    }
  `;

  static DEDUCTION_PAYMENTS = gql`
    query DeductionPayments($where: deductionPaymentWhereInput, $limit: Int, $skip: Int) {
      deductionPayments(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          deductionID
          deductionAmount
          deductionDate
          deductionStatus
          deductionNote
          deductionCreatedAt
          deductionCreatedBy {
            _id
            first_name
            last_name
            gender
          }
          deductionCheck
          noID
          deductionDriverID {
            _id
            first_name
            last_name
            gender
          }
        }
      }
    }
  `;
  static VERIFY_PAID_PAYMENTS = gql`
    mutation UpdateConsolidateSummaryVerify($where: whereVerifyID!, $data: consolidateSummaryInput) {
      updateConsolidateSummaryVerify(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static VERIFY_PAID_PAYMENTS_ALL = gql`
    mutation UpdateConsolidateSummaryVerifyAll($where: WhereByMultipleId!, $data: consolidateSummaryInput) {
      updateConsolidateSummaryVerifyAll(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static Query_center = gql`
    query SeparationCenters($where: SeparationCenterWhereInput, $limit: Int, $skip: Int) {
      separationCenters(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          st_id
          centerName
        }
      }
    }
  `;
  static Query_company = gql`
    query DriverPartners($where: driverPartnerInput, $limit: Int, $skip: Int) {
      driverPartners(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          cp_id
          cp_name
          servicesMoney
        }
      }
    }
  `;
  static Query_users = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          first_name
          last_name
        }
      }
    }
  `;
  static Query_vehicle = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          vehicleName
          _id
        }
      }
    }
  `;

  static CREATE_SIGNATURE = gql`
    mutation CreateSignature($data: signatureInput!) {
      createSignature(data: $data) {
        status
      }
    }
  `;
  static QUERY_SIGNATURE = gql`
    query Query($where: signatureWhereInput, $limit: Int, $skip: Int) {
      signatures(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          dePartment
          signatureDate
          status
          noID
          routeStartDate
          routeEndDate
          signatureTwo
          signatureStatus
          driverStatus
        }
      }
    }
  `;
  static UPDATE_TB_CONSOLIDATE = gql`
    mutation UpdateConsolidateSummary($where: whereCID!, $data: consolidateSummaryInput) {
      updateConsolidateSummary(where: $where, data: $data) {
        status
      }
    }
  `;

  static QUERY_COMPANY_SERVICE = gql`
    query DriverPartners($where: driverPartnerInput, $limit: Int, $skip: Int) {
      driverPartners(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          cp_id
          servicesMoney
        }
      }
    }
  `;
}
