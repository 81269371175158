import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_ROUTES_LOCATION = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          locationCode
          isPublic
          note
          createdAt
          priceDay
          priceNight
          runOn
          lg_isDay
          checkRoute
          km
          isAuction
          petroleum
          code
          priceNew
          inSide
        }
      }
    }
  `;

  static CREATE_ROUTES_LOCATION = gql`
    mutation CreateRouteLocation($data: routeLocationInput!) {
      createRouteLocation(data: $data) {
        status
      }
    }
  `;
  static DELETE_ROUTES_LOCATION = gql`
    mutation DeleteRouteLocation($where: WhereById!) {
      deleteRouteLocation(where: $where) {
        status
      }
    }
  `;

  static UPDATE_STATUS_ROUTE = gql`
  mutation UpdateRouteLocationOne($where: WhereById!, $data: routeLocationInput) {
  updateRouteLocationOne(where: $where, data: $data) {
    status
    data
  }
}
  `;
  static UPDATE_ROUTES_LOCATION = gql`
    mutation UpdateRouteLocation($where: WhereById!, $data: routeLocationInput) {
      updateRouteLocation(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static QUERY_LIST_VEHICLE = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          vehicleName
        }
      }
    }
  `;
  static UPDATE_VEHICLE = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static QUERY_BRANCH_GROUP = gql`
    query LocationGroupBranches($where: locationGroupBranchInput, $limit: Int, $skip: Int) {
      locationGroupBranches(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          branch_id {
            id_branch
            branch_name
          }
        }
      }
    }
  `;
  static DELETE_BRANCH_GROUP = gql`
    mutation DeleteLocationGroupBranch($where: WhereById!) {
      deleteLocationGroupBranch(where: $where) {
        status
      }
    }
  `;
  static QUERY_VEHICLE_ROUTE_LOCATION = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          vehicleName
          VehicleType {
            _id
            name
          }
          brand
          colour
          power
          machineNumber
          tankNumber
          sign
          isPublic
          isDeleted
          insurance
          insuranceOver
          dateIncome
          status
          audit
          isRent
          driver {
            _id
            first_name
            last_name
            gender
          }
          createdBy {
            _id
            first_name
            last_name
            gender
          }
          center {
            st_id
            centerName
          }
          routeID {
            _id
            title
          }
          note
          runStatus
          createdAt
        }
      }
    }
  `;
  static QUERY_BRANCH_ROUTE_LOCATION = gql`
   query OfficeBranches($limit: Int, $skip: Int, $where: officeBranchWhereInput) {
      officeBranches(limit: $limit, skip: $skip, where: $where) {
        total
        data {
          id_branch
          branch_name
          branch_address
          branch_code
        }
      }
    }
  `;

  static UPDATE_BRANCH_LOCATION = gql`
    mutation UpdateLocationGroupBranch($where: WhereById!, $data: locationGroupBranchInput) {
      updateLocationGroupBranch(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static UPDATE_VEHICLE_LOCATION = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static CREATE_BRANCH_LOCATION = gql`
    mutation CreateLocationGroupBranch($data: locationGroupBranchInput!) {
      createLocationGroupBranch(data: $data) {
        status
      }
    }
  `;
}
