import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_VEHICLE_UPDATE = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static QUERY_VEHICLE_ITEMS = gql`
  query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
    vehicles(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        vehicleName
      }
    }
  }
  `;
}
