import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_VEHICLE = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          vehicleName
          VehicleType {
            _id
            name
          }
          brand
          colour
          power
          machineNumber
          tankNumber
          sign
          isPublic
          isDeleted
          insurance
          insuranceOver
          dateIncome
          status
          audit
          isRent
          driver {
            _id
            first_name
            last_name
            gender
          }
          createdBy {
            _id
            first_name
            last_name
          }
          center {
            st_id
            centerName
          }
          routeID {
            _id
            title
          }
          note
          runStatus
          createdAt
        }
      }
    }
  `;
  static CREATE_VEHICLE = gql`
    mutation CreateVehicle($data: vehicleInput!) {
      createVehicle(data: $data) {
        status
      }
    }
  `;
  static UPDATE_VEHICLE = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static DELETE_VEHICLE = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static QUERY_TYPE_VEHICLE = gql`
    query TypeVehicles($where: typeVehicleWhereInput, $limit: Int, $skip: Int) {
      typeVehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          name
        }
      }
    }
  `;
}
