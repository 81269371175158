import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_TYPE_VEHICLE = gql`
  query TypeVehicles($where: typeVehicleWhereInput, $limit: Int, $skip: Int) {
    typeVehicles(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        name
        isPublic
        createdAt
        updatedAt
      }
    }
  }
  `;

  static CREATE_TYPE_VEHICLE = gql`
    mutation CreateTypeVehicle($data: typeVehicleInput!) {
      createTypeVehicle(data: $data) {
        status
      }
    }
  `;

  static DELETE_TYPE_VEHICLE = gql`
    mutation DeleteTypeVehicle($where: WhereById!) {
      deleteTypeVehicle(where: $where) {
        status
      }
    }
  `;

  static UPDATE_TYPE_VEHICLE = gql`
    mutation UpdateTypeVehicle($where: WhereById!, $data: typeVehicleInput) {
      updateTypeVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;
}
