import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "./Schema";
import { currency } from "..";

type SelectDriverProps = {
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  onChange?: (e: { value: number; label: string }) => void;
  styleConfig?: StylesConfig;
  isDay?: any;
  checkRoute?: any;
};

type Options = {
  value: number;
  label: string;
};

export default function SearchRouteTitle({ disabled, value, onChange, styleConfig, isDay, checkRoute }: SelectDriverProps) {
  const [selectedOption, setSelectedOption] = useState<Options>();
  const [items, setItems] = useState<any>([0]);
  const [fetchData, { data, loading }] = useLazyQuery(Schema.SELECT_ROUTE_LOCATION_TITLE, {
    fetchPolicy: "cache-and-network",
  });

  //  ປະເພດສາຍ

  let ListRoute: any = "";
  if (checkRoute === "IN") {
    ListRoute = "ສາຍໃນ";
  } else if (checkRoute === "OUT") {
    ListRoute = "ສາຍນອກ";
  } else {
    ListRoute = "ສາຍສຳຮອງ";
  }
  // check day
  let ListDay: any = "";
  if (isDay === "1") {
    ListDay = "(ພາກແລງ)";
  } else {
    ListDay = "(ພາກເຊົ້າ)";
  }
  useEffect(() => {
    fetchData({
      variables: {
        where: {
           lg_isDay: String(isDay),
           isPublic: 1,
          checkRoute: checkRoute ? checkRoute : undefined,
        },
        limit: 500,
      },
    });
  }, [fetchData, disabled, isDay, checkRoute]);

  useEffect(() => {
    if (!data?.routeLocations?.data?.length) {
      setItems([]);
      return;
    }
    const newData = data?.routeLocations?.data?.map((item: any) => {
      return {
        ...item,
        value: item?._id,
        label:
          "ລະຫັດສາຍ: " +
          item?.code +
          " | " +
          item?.title +
          " | " +
          "ປະເພດສາຍ: " +
          `${ListRoute}` +
          " | " +
          "ລາຄາຕໍ່ຖ້ຽວ" +
          `${ListDay}:` +
          currency(item?.priceDay) +
          " ກີບ " +
          "|" +
          currency(item?.priceNight) +
          "ກີບ",
      };
    });
    setItems(newData);
  }, [data, ListRoute, ListDay]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item: any) => item?._id === value);
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption({ value: 0, label: "ເລືອກຄ່າເລີ່ມຕົ້ນ" });
    }
  }, [items, value]);

  return (
    <div>
      <Select
        isDisabled={disabled}
        value={selectedOption}
        placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກສາຍ"}
        isLoading={loading}
        onChange={(res: any) => {
          onChange?.(res);
        }}
        options={[{ value: 0, label: "ເລືອກຄ່າເລີ່ມຕົ້ນ" }, ...items]}
        styles={styleConfig}
      />
    </div>
  );
}
