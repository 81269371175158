import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Report } from "notiflix";
import { clearLocalStorage, LocalStorageName } from "../utils";
import Environment from "./Environment";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LocalStorageName.token);
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError?.message === "Failed to fetch") {
    Report.warning("ແຈ້ງເຕືອນ", "ມີຂໍ້ຜິດພາດເກີດຂື້ນ. ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.", "ຕົກລົງ");
    return;
  }

  if (!graphQLErrors?.length) return;
  const _error: any = graphQLErrors[0];


  if (_error === "jwt expired") {
    clearLocalStorage();
    window.location.replace("/login");
  }
  if (_error === "jwt must be provided") {
    clearLocalStorage();
    window.location.replace("/login");
  }
  if (_error === "invalid signature") {
    clearLocalStorage();
    window.location.replace("/login");
  }
});

const client = new ApolloClient({
  link: from([
    errorLink,
    authLink.concat(
      createHttpLink({
        uri: Environment.API,
      })
    ),
  ]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          items: {
            merge: true,
          },
        },
      },
    },
  }),
});

export default function ApolloClientProvider(props: any) {
  return (
    <>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </>
  );
}
