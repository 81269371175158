import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_CENTER = gql`
    query SeparationCenters($where: SeparationCenterWhereInput, $limit: Int, $skip: Int) {
      separationCenters(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          st_id
          centerName
          centerCode
          isPublic
          isMain
          note
          position
          createdAt
        }
      }
    }
  `;

  static CREATE_CENTER = gql`
    mutation CreateSeparationCenter($data: SeparationCenterInput!) {
      createSeparationCenter(data: $data) {
        status
      }
    }
  `;

  static DELETE_CENTER = gql`
    mutation DeleteSeparationCenter($where: WhereId!) {
      deleteSeparationCenter(where: $where) {
        status
      }
    }
  `;
  static UPDATE_CENTER = gql`
    mutation UpdateSeparationCenter($where: WhereId!, $data: SeparationCenterInput) {
      updateSeparationCenter(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static UPDATE_CLOSED = gql`
    mutation UpdateSeparationCenter($where: WhereId!, $data: SeparationCenterInput) {
      updateSeparationCenter(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static QUERY_VEHICLE = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          vehicleName
          VehicleType {
            _id
            name
          }
          brand
          colour
          power
          machineNumber
          tankNumber
          sign
          isPublic
          isDeleted
          insurance
          insuranceOver
          dateIncome
          status
          audit
          isRent
          driver {
            _id
            first_name
            last_name
          }
          createdBy {
            _id
            first_name
            last_name
          }
          center {
            st_id
            centerName
          }
          routeID {
            _id
            title
          }
          note
          runStatus
          createdAt
        }
      }
    }
  `;

  static QUERY_LIST_VEHICLE = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          vehicleName
        }
      }
    }
  `;
}
