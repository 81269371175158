import ContainDriver from './containDriver';
import DestinationVehicle from './destinationVehicle';
import ModifyDestinationVehicle from './modifyDestinationVehicle';
import TypeVehicle from './typeVehicle';
import ManageVehicle from './manageVehicle';
import HistoryComponent from './modifyDestinationVehicle/components';

const transportationVehicle = {
    ContainDriver,
    DestinationVehicle,
    ModifyDestinationVehicle,
    TypeVehicle,
    ManageVehicle,
    HistoryComponent
};
export default transportationVehicle;