import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_SHIPPING_ROUTE = gql`
    query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
      shippingRoutes(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          sp_id
          isDif
          pay
          isDay
          difNote
          driverID {
            _id
            first_name
            last_name
            gender
          }
          routeLocationID {
            _id
            title
          }
          vehicleID {
            _id
            vehicleName
          }
          createdAt
          createdBy {
            _id
            first_name
            last_name
            gender
          }
          checkRoute
          amountValue
          startTime
          endTime
          acceptStatus
          acceptBy {
            _id
            first_name
            last_name
            gender
          }
          checkDate
          noID
          noRID
          inSide
          totalItem
          billNoRout
          acceptArrivedBy {
            _id
            first_name
            last_name
            gender
          }
          driverTwo {
            _id
            first_name
            last_name
            gender
          }
          centerRoute {
            st_id
            centerName
            centerCode
          }
        }
      }
    }
  `;
  static CREATE_SHIPPING_ROUTE = gql`
    mutation CreateShippingRoute($data: ShippingRouteWhereInput!) {
      createShippingRoute(data: $data) {
        status
      }
    }
  `;

  static UPDATE_SHIPPING_ROUTE = gql`
    mutation UpdateShippingRouteStatus($where: whereStatusID!, $data: ShippingRouteWhereInput) {
      updateShippingRouteStatus(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static DELETE_SHIPPING_ROUTE = gql`
    mutation Mutation($where: whereSID!) {
      deleteShippingRoute(where: $where) {
        data
        status
      }
    }
  `;
  static GET_USER = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          first_name
          last_name
        }
      }
    }
  `;

  static UPDATE_SHIPPING_ROUTE_DELETE = gql`
    mutation UpdateShippingRouteDelete($where: whereIDD!, $data: ShippingRouteDeleteInput) {
      updateShippingRouteDelete(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static UPDATE_SHIPPING_ROUTE_STATUS = gql`
    mutation UpdateShippingRouteStatus($where: whereStatusID!, $data: ShippingRouteWhereInput) {
      updateShippingRouteStatus(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static QUERY_HISTORY_SHIPPING = gql`
    query Query($skip: Int, $limit: Int, $where: ShippingSupWhere) {
      HistoryShippingRoutes(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          _id
          sping_route
          route_id {
            _id
          }
          is_day
          service
          note
          branch_id {
            id_branch
            branch_name
          }
        }
      }
    }
  `;

  static UPDATE_HISTORY_SHIPPING = gql`
    mutation UpdateHistoryRoutes($data: ShippingSupInput!, $where: WhereBySpId!) {
      updateHistoryRoutes(data: $data, where: $where) {
        status
      }
    }
  `;

  static QUERY_LOCATION_BRANCH = gql`
    query LocationGroupBranches($where: locationGroupBranchInput, $limit: Int, $skip: Int) {
      locationGroupBranches(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          branch_id {
            id_branch
            branch_name
            branch_address
          }
          isDay
          service_type
          locationID {
            _id
            title
          }
        }
      }
    }
  `;

  static UPDATE_LOCATION_BRANCH = gql`
    mutation Mutation($where: WhereById!, $data: locationGroupBranchInput) {
      updateLocationGroupBranch(where: $where, data: $data) {
        status
      }
    }
  `;
  static UPDATE_SHIPPING_ROUTE_LOCATION = gql`
    mutation UpdateShippingRoute($where: whereSID!, $data: ShippingRouteWhereInput) {
      updateShippingRoute(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static QUERY_VEHICLE_SHIPPING_ROUTES = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          vehicleName
          driver {
            _id
            first_name
            last_name
            gender
          }
        }
      }
    }
  `;

  static QUERY_SIZE = gql`
    query ReportItemFinalSummary($skip: Int, $limit: Int, $where: ItemFinalSummaryWhereInput) {
      ReportItemFinalSummary(skip: $skip, limit: $limit, where: $where) {
        FinalWeight
        FinalSumWidth
      }
    }
  `;
}
