import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ParentPath } from "../../routes/Path";
import { Form, Spinner } from "react-bootstrap";
import SearchDriverCompany from "../../utils/components/SelectDriverCompany";
import SelectDriverRoute from "../../utils/components/SelectDriverRoute";
import SearchCenter from "../../utils/components/SelectCenter";
import SearchDriverVehicle from "../../utils/components/SelectDriver";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { Gender, RootVariable, checkUserRole, currency, formatDate } from "../../utils";
import TableData from "../../utils/components/TableData";
import moment from "moment";
import SearchDateRangeConsolidateSummary from "../../utils/components/SearchDateRangeConsolidateSummary";
import ExportTotal from "./ExportTotal";
import TotalSummary from "./TotalSummaries";
import { LuListMinus } from "react-icons/lu";
import SearchDateRangeBetweenConsolidate from "../../utils/components/SearchDateRangeBetweenConsolidate";
import { DeleteBack, Paid, PaidTotal } from "./component";
export default function SummarizeDestinationVehicle() {
  const navigate = useNavigate();
  const [TotalDrivers, setTotalDrivers] = useState<any>();
  const [TotalVehicles, setTotalVehicles] = useState<any>();
  const [totalBalance, setTotalBalance] = useState<any>();
  const [totalPetroleum, setTotalPetroleum] = useState<any>();
  const [consolidateTotalRoute, setConsolidateTotalRoute] = useState<any>();
  const [SumTotalAmountRoutes, setSumTotalAmountRoutes] = useState<any>();
  const [extraPayment, setExtraPayment] = useState<any>();
  const [SumTotalSalary, setSumTotalSalary] = useState<any>();
  const [SumDeductionTotal, setSumDeductionTotal] = useState<any>();
  const [totalServices, setTotalServices] = useState<any>();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [company, setCompany] = useState<any>();
  const [driver, setDriver] = useState<any>();
  const [paid, setPaid] = useState<any>();
  const [isDif, setIsDif] = useState<any>();
  const [vehicle, setVehicle] = useState<any>();
  const [isDay, setIsDay] = useState<any>();
  const [checkRoute, setCheckRoute] = useState<any>();
  const [center, setCenter] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [reloading, setReloading] = useState(false);
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [dateConsolidateBetween, setDateConsolidateBetween] = useState<string[]>([]);
  const _startDate = searchParams.get("startDate");
  const _endDate = searchParams.get("endDate");
  const _startConsolidate = searchParams.get("startConsolidateDate");
  const _endConsolidate = searchParams.get("endConsolidateDate");
  const [status, setStatus] = useState<any>();
  const [checkStatus, setCheckStatus] = useState<number>(1);
  const [fetchCompany, { data: newData }] = useLazyQuery(Schema.QUERY_COMPANY_SERVICE, { fetchPolicy: "cache-and-network" });
  const [Summary, { data, loading }] = useLazyQuery(Schema.QUERY_SUMMARY_SHIPPING_ROUTE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    const vehicleIDs = vehicle === 0 ? undefined : Number(vehicle);
    const driverIDs = driver === 0 ? undefined : Number(driver);
    const companyIDs = company === 0 ? undefined : Number(company);
    const centerIDs = center === 0 ? undefined : Number(center);

    const whereClause = {
      isDay: isDay ? isDay : undefined,
      consolidateVehicleID: vehicleIDs ? vehicleIDs : undefined,
      isDif: isDif ? isDif : ["FALSE", "TRUE"],
      checkRoute: checkRoute ? checkRoute : undefined,
      consolidatePaidStatus: paid ? paid : undefined,
      consolidateDriverID: driverIDs ? driverIDs : undefined,
      driverConfirmed: 1,
      consolidatedDateBetween: [dateConsolidateBetween[0], dateConsolidateBetween[1]],
      consolidatedDateSummary: [dateBetween[0], dateBetween[1]],
      centerID: centerIDs ? centerIDs : undefined,
      companyID: companyIDs ? companyIDs : undefined,
    };

    Summary({
      variables: {
        where: whereClause,
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [
    Summary,
    searchValue,
    numberRow,
    pageNumber,
    isDay,
    vehicle,
    isDif,
    checkRoute,
    paid,
    driver,
    dateBetween,
    center,
    company,
    dateConsolidateBetween,
    reloading,
  ]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.consolidateSummaries?.data || []);
    setTotal(data?.consolidateSummaries?.total || 0);
    setTotalDrivers(data?.consolidateSummaries?.SumTotalDrivers || 0);
    setTotalVehicles(data?.consolidateSummaries?.TotalVehicle || 0);
    setTotalBalance(data?.consolidateSummaries?.SumTotalBalance || 0);
    setTotalPetroleum(data?.consolidateSummaries?.petroleum || 0);
    setConsolidateTotalRoute(data?.consolidateSummaries?.consolidateTotalRoute || 0);
    setSumTotalAmountRoutes(data?.consolidateSummaries?.SumTotalAmountRoutes || 0);
    setExtraPayment(data?.consolidateSummaries?.extraPayment || 0);
    setSumTotalSalary(data?.consolidateSummaries?.SumTotalSalary || 0);
    setSumDeductionTotal(data?.consolidateSummaries?.SumDeductionTotal || 0);
    setTotalServices(data?.consolidateSummaries?.consolidateAmountRouteService || 0);
  }, [data, loading]);

  // consolidateID

  const consolidateID = data?.consolidateSummaries?.data[0]?.consolidateID;

  // companyService

  useEffect(() => {
    fetchCompany({
      variables: {
        where: {
          cp_id: Number(company),
        },
      },
    });
  }, [fetchCompany, company]);

  const servicesMoney = newData?.driverPartners?.data[0]?.servicesMoney;

  const servicesMoneyTotal = (totalServices * servicesMoney) / 100;

  const FinalServicesTotal = Number(servicesMoneyTotal + totalServices);

  // within of month

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _center = searchParams.get("center");
    const _driver = searchParams.get("driver");
    const _company = searchParams.get("company");
    const _paid = searchParams.get("paid");
    const _isDif = searchParams.get("isDif");
    const _vehicle = searchParams.get("vehicle");
    const _isDay = searchParams.get("isDay");
    const _checkRoute = searchParams.get("checkRoute");

    const decodeCenter = _center ? atob(_center) : undefined;
    const decodeDriver = _driver ? atob(_driver) : undefined;
    const decodeCompany = _company ? atob(_company) : undefined;
    const decodePaid = _paid ? atob(_paid) : undefined;
    const decodeIsDif = _isDif ? atob(_isDif) : undefined;
    const decodeVehicle = _vehicle ? atob(_vehicle) : undefined;
    const decodeIsDay = _isDay ? atob(_isDay) : undefined;
    const decodeCheckRoute = _checkRoute ? atob(_checkRoute) : undefined;

    setCheckRoute(decodeCheckRoute ? decodeCheckRoute : undefined);
    setIsDay(decodeIsDay ? decodeIsDay : undefined);
    setVehicle(decodeVehicle ? decodeVehicle : undefined);
    setIsDif(decodeIsDif ? decodeIsDif : undefined);
    setPaid(decodePaid ? decodePaid : undefined);
    setCompany(decodeCompany ? decodeCompany : undefined);
    setDriver(decodeDriver ? decodeDriver : undefined);
    setCenter(decodeCenter ? decodeCenter : undefined);
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
    const _startConsolidate = searchParams.get("startConsolidateDate");
    const _endConsolidate = searchParams.get("endConsolidateDate");
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");

    if (_startConsolidate && _endConsolidate) setDateConsolidateBetween([_startConsolidate, _endConsolidate]);
    else setDateConsolidateBetween([moment().clone().startOf("month").format("YYYY-MM-DD"), moment().clone().endOf("month").format("YYYY-MM-DD")]);

    if (_startDate && _endDate) {
      setDateBetween([_startDate, _endDate]);
    } else {
      setDateBetween([moment().clone().startOf("month").format("YYYY-MM-DD"), moment().clone().format("YYYY-MM-DD")]);
    }
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ລາຍງານຂໍ້ມູນການສະຫລຸບທັງໝົດ</li>
          <li className="breadcrumb-item">ຈຳແນກຈັດຖ້ຽວລົດອອກ</li>&nbsp;/&nbsp;&nbsp;
          <li className="text-center text-nowrap" style={{ backgroundColor: "#b5b3b3", width: 80, borderRadius: 20 }}>
            ຖ້ຽວປົກກະຕິ
          </li>
          &nbsp;
          <li className="text-center" style={{ backgroundColor: "#ecccc1", width: 80, borderRadius: 20 }}>
            ຖ້ຽວເສີມ
          </li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-6">
                <h3>ລາຍງານຂໍ້ມູນການສະຫລຸບທັງໝົດ</h3>
              </div>
              <div className="col-sm-6">
                <div style={{ float: "right" }}>
                  {checkUserRole(["ACCOUNTANT", "HR", "AUDITOR", "SUPER_ADMIN"]) ? (
                    <>
                      <ExportTotal
                        item={items}
                        data={data}
                        driver={driver}
                        company={company}
                        paid={paid}
                        isDay={isDay}
                        isDif={isDif}
                        vehicle={vehicle}
                        checkRoute={checkRoute}
                        center={center}
                        startDate={_startDate}
                        endDate={_endDate}
                        TotalDrivers={TotalDrivers}
                        totalBalance={totalBalance}
                        consolidateID={consolidateID}
                        _startConsolidate={_startConsolidate}
                        _endConsolidate={_endConsolidate}
                      />
                      <PaidTotal onSuccess={() => setReloading(!reloading)} consolidateID={items} />
                      &nbsp;&nbsp;
                      <button type="button" className="btn btn-primary" onClick={() => navigate(`${ParentPath.summaryTotal}`)}>
                        <i className="fas fa-repeat"></i> ສະຫຼຸບຖ້ຽວລົດ
                      </button>
                    </>
                  ) : null}
                  {checkUserRole(["ADMIN"]) ? (
                    <>
                      <ExportTotal
                        items={items}
                        driver={driver}
                        company={company}
                        paid={paid}
                        isDay={isDay}
                        isDif={isDif}
                        vehicle={vehicle}
                        checkRoute={checkRoute}
                        center={center}
                        startDate={_startDate}
                        endDate={_endDate}
                        _startConsolidate={_startConsolidate}
                        _endConsolidate={_endConsolidate}
                      />
                      <button type="button" className="btn btn-primary" onClick={() => navigate(`${ParentPath.summaryTotal}`)}>
                        <i className="fas fa-repeat"></i> ສະຫຼຸບຖ້ຽວລົດ
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-1">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-3">
                    <SearchDateRangeBetweenConsolidate sMonth eMonth />
                  </div>
                  <div className="col-sm-3">
                    <SearchDateRangeConsolidateSummary sDay eDay />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມສູນ</label>
                    <SearchCenter
                      onChange={(option: any) => {
                        searchParams.set("center", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ພະນັກງານຂັບລົດ</label>
                    <SearchDriverVehicle
                      onChange={(option: any) => {
                        searchParams.set("driver", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມບໍລິສັດ</label>
                    <SearchDriverCompany
                      onChange={(option: any) => {
                        searchParams.set("company", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-4" style={{ marginBottom: "60px" }}>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມການຊຳລະ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("paid", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="PAID">ຊຳລະແລ້ວ</option>
                      <option value="UNPAID">ຍັງບໍ່ຊຳລະ</option>
                    </Form.Select>
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມກໍລະນີອອກລົດ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("isDif", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="FALSE">ປົກກະຕິ</option>
                      <option value="TRUE">ເສີມ</option>
                    </Form.Select>
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
                    <SelectDriverRoute
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option.value));
                        setSearchParams(searchParams);
                        setCheckStatus(option?.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມກະອອກລົດ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("isDay", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="1">ພາກແລງ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມປະເພດສາຍ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("checkRoute", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="OUT">ສາຍນອກ</option>
                      <option value="IN">ສາຍໃນ</option>
                      <option value="SPARE">ສາຍສຳຮອງ</option>
                    </Form.Select>
                  </div>
                </div>
                <hr />
                <div className="text-right" style={{ marginTop: "50px" }}>
                  <TotalSummary
                    loading={loading}
                    TotalDrivers={TotalDrivers}
                    TotalVehicles={TotalVehicles}
                    totalBalance={totalBalance}
                    totalPetroleum={totalPetroleum}
                    consolidateTotalRoute={consolidateTotalRoute}
                    SumTotalAmountRoutes={SumTotalAmountRoutes}
                    extraPayment={extraPayment}
                    SumTotalSalary={SumTotalSalary}
                    SumDeductionTotal={SumDeductionTotal}
                    servicesMoneyTotal={servicesMoneyTotal}
                    servicesMoney={servicesMoney}
                    FinalServicesTotal={FinalServicesTotal}
                  />
                  <hr />
                </div>
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? <th className="text-nowrap">ຢືນຢັນ</th> : null}
                      {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? <th className="text-nowrap">ພິມລາຍການ</th> : null}
                      {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? (
                        <th className="text-nowrap">ລາຍລະອຽດຖ້ຽວ/ເງິນເພີ່ມ/ເງິນຫັກ/ລົບ</th>
                      ) : null}
                      <th className="text-nowrap">ຊື່ໂຊເຟີ້</th>
                      <th className="text-nowrap">ຂໍ້ມູນຖ້ຽວລົດ</th>
                      <th className="text-nowrap">ການຊຳລະ</th>
                      <th className="text-nowrap">ຂໍ້ມູນບັນຊີທະນາຄານ</th>
                      <th className="text-nowrap">ສາຍທີ່ແລ່ນ</th>
                      <th className="text-nowrap">ລາຄາສາຍ(ກີບ)</th>
                      <th className="text-nowrap">ນ້ຳມັນລວມ(ລິດ)</th>
                      <th className="text-nowrap">ຈຳນວນຖ້ຽວ(ຖ້ຽວ)</th>
                      <th className="text-nowrap">ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(1)(ກີບ)</th>
                      <th className="text-nowrap">ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(2)(ກີບ)</th>
                      <th className="text-nowrap">ການສະຫຼຸບ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index} className={value?.consolidatePaidStatus === "PAID" ? "table-success" : ""}>
                          <td className={value?.isDif === "TRUE" ? "table-danger text-center" : ""}>
                            <div className="text-center" style={{ marginTop: 25 }}>
                              {index + 1}
                            </div>
                          </td>
                          {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? (
                            value?.consolidatePaidStatus !== "PAID" ? (
                              <>
                                <td className="text-nowrap">
                                  <Paid
                                    consolidateID={value?.consolidateID}
                                    onSuccess={() => setReloading(!reloading)}
                                    disabled={value?.consolidatePaidStatus === "PAID"}
                                  />
                                </td>
                              </>
                            ) : (
                              <td className="text-nowrap">
                                <i className="fas fa-check-circle" style={{ color: "green", marginRight: "5px" }} />
                                <span>ຊຳລະແລ້ວ</span>
                              </td>
                            )
                          ) : null}
                          {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? (
                            <td className="text-nowrap">
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ marginTop: 25 }}
                                onClick={() =>
                                  navigate(
                                    `${ParentPath.PrintInvoice}?ConsolidateDriverID=${value?.consolidateID}&&DriverTwo=${value?.driverTwo?._id}&&driverOne=${value?.consolidateDriverID?._id}&&No=${value?.consolidateID}&&noID=${value?.noID}&&Status=${status}&&checkDate=${vehicle}`
                                  )
                                }
                              >
                                <i className="fas fa-print"></i>ພິມ
                              </button>
                            </td>
                          ) : null}

                          {checkUserRole(["ACCOUNTANT", "AUDITOR", "SUPER_ADMIN"]) ? (
                            <>
                              <td className="text-nowrap">
                                <div className="btn-group  btn-group-vertical">
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    style={{ color: "#fff", borderRadius: "5px" }}
                                    onClick={() =>
                                      navigate(
                                        `${ParentPath.listShipping}?ConsolidateID=${
                                          value?.consolidateID
                                        }&&checkDate=${checkStatus}&&_startConsolidate=${_startConsolidate}&&_endConsolidate=${_endConsolidate}&&data=${JSON.stringify(
                                          value
                                        )}`
                                      )
                                    }
                                  >
                                    <LuListMinus />
                                    ລາຍລະອຽດຖ້ຽວ
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-info "
                                    style={{ color: "#fff", borderRadius: "5px" }}
                                    onClick={() =>
                                      navigate(
                                        `${ParentPath.ExtraPayments}?ConsolidateID=${
                                          value?.consolidateID
                                        }&&checkDate=${checkStatus}&&_startConsolidate=${_startConsolidate}&&_endConsolidate=${_endConsolidate}&&data=${JSON.stringify(
                                          value
                                        )}`
                                      )
                                    }
                                  >
                                    <LuListMinus />
                                    ເງິນເພີ່ມ
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    style={{ color: "#fff", borderRadius: "5px" }}
                                    onClick={() =>
                                      navigate(
                                        `${ParentPath.DeductionPayments}?ConsolidateID=${
                                          value?.consolidateID
                                        }&&checkDate=${checkStatus}&&_startConsolidate=${_startConsolidate}&&_endConsolidate=${_endConsolidate}&&data=${JSON.stringify(
                                          value
                                        )}`
                                      )
                                    }
                                  >
                                    <LuListMinus />
                                    ເງິນຫັກ
                                  </button>

                                  {checkUserRole(["SUPER_ADMIN"]) ? (
                                    value?.consolidatePaidStatus !== "PAID" ? (
                                      <>
                                        <DeleteBack
                                          items={value}
                                          onSuccess={() => setReloading(!reloading)}
                                          disabled={value?.consolidatePaidStatus === "PAID"}
                                        />
                                      </>
                                    ) : null
                                  ) : null}
                                </div>
                              </td>
                            </>
                          ) : null}

                          <td className="text-nowrap ">
                            <div>
                              ໂຊເຟີ້(1): {value?.consolidateDriverID?.gender ? Gender(value?.consolidateDriverID?.gender) : ""}
                              &nbsp;
                              {value?.consolidateDriverID?.first_name || "---"}&nbsp;
                              {value?.consolidateDriverID?.last_name || "---"}
                            </div>

                            <br />
                            <div>
                              ໂຊເຟີ້(2): {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp;
                              {value?.driverTwo?.first_name || "----"}&nbsp;
                              {value?.driverTwo?.last_name || "----"}
                            </div>
                          </td>
                          <td className="text-nowrap">
                            <div>ກະອອກລົດ: {value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</div>
                            <div>ເບີລົດ: {value?.consolidateVehicleID?.vehicleName || "-----"}</div>
                            <div className="text-success"> ກວດສອບຜ່ານ</div>
                            <div className={value?.isDif === "FALSE" ? "text-success" : " text-danger text-nowrap"}>
                              ປະເພດຖ້ຽວ: {value?.isDif === "FALSE" ? "ຖ້ຽວປົກກະຕິ" : "ຖ້ຽວເສີມ"}
                            </div>
                            <div>ສາຍລົດ: {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</div>
                          </td>
                          <td className="text-nowrap">
                            <div>
                              ການຊຳລະ:{" "}
                              <span className={value?.consolidatePaidStatus === "UNPAID" ? "text-danger" : "text-success"}>
                                {value?.consolidatePaidStatus === "UNPAID" ? "ຍັງບໍ່ຊຳລະ" : "ຊຳລະແລ້ວ"}
                              </span>{" "}
                            </div>
                            <div>
                              ຜູ້ຊຳລະ: {value?.paidBy?.gender ? Gender(value?.paidBy?.gender) : ""}&nbsp;
                              {value?.paidBy?.first_name || "----"}&nbsp;
                              {value?.paidBy?.last_name || "----"}
                            </div>
                            <div>ວັນທີຊຳລະ: {value?.consolidatePaidDate ? formatDate(value?.consolidatePaidDate) : "------"}</div>
                          </td>
                          <td className="text-nowrap">
                            <div>ໂຊເຟີ້(1)(ຊື່ບັນຊີ): {value?.consolidateDriverID?.account_bank_name || "-----"}</div>
                            <div>ໂຊເຟີ້(1)(ເລກບັນຊີ): {value?.consolidateDriverID?.account_bank_number || "-----"}</div>
                            <div>ໂຊເຟີ້(2)(ຊື່ບັນຊີ): {value?.driverTwo?.account_bank_name || "-----"}</div>
                            <div>ໂຊເຟີ້(2)(ເລກບັນຊີ):{value?.driverTwo?.account_bank_number || "-----"}</div>
                          </td>
                          <td className="text-nowrap">
                            <div>{value?.consolidateRouteID?.title || "-----"}</div>
                            <hr />
                            <div>ຈຳນວນສາຍ: 1 ສາຍ</div>
                          </td>
                          <td className="text-center">
                            {value?.checkRoute === "IN" ? (
                              <span>{currency(value?.consolidateRouteID?.priceDay) || 0}</span>
                            ) : value?.checkRoute === "OUT" ? (
                              <span>{currency(value?.consolidateRouteID?.priceDay) || 0}</span>
                            ) : (
                              <span>{currency(value?.consolidateRouteID?.priceDay) || 0}</span>
                            )}
                          </td>
                          <td className="text-center">
                            <div>{value?.petroleum || 0}</div>
                          </td>
                          <td className="text-center">
                            <div>{value?.consolidateTotalRoute || 0}</div>
                          </td>
                          <td className="text-nowrap">
                            <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRoute ? currency(value?.consolidateAmountRoute) : 0} ກີບ</div>
                            <div>ເງິນເດືອນຂອງໂຊເຟີ້(1): {value?.basicSalaryOne ? currency(value?.basicSalaryOne) : 0} ກີບ</div>
                            <div>ເງິນເພີ່ມທົ່ວໄປ(ລວມ): {value?.extraPayment ? currency(value?.extraPayment) : 0} ກີບ</div>
                            <div>ເງິນເພີ່ມພິເສດ(ລວມ): {value?.bestExtraAmount ? currency(value?.bestExtraAmount) : 0} ກີບ </div>
                            <div className="text-danger">ເງິນຫັກ(ລວມ): {value?.deductionPayment ? currency(value?.deductionPayment) : 0} ກີບ</div>
                            <div className="text-success">
                              ເງິນລວມສຸດທິ: {value?.paymentBalanceAmount ? currency(value?.paymentBalanceAmount) : 0} ກີບ
                            </div>
                          </td>
                          <td className="text-nowrap">
                            <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRouteTwo ? currency(value?.consolidateAmountRouteTwo) : 0} ກີບ</div>
                            <div>ເງິນເດືອນຂອງໂຊເຟີ້(2): {value?.basicSalaryTwo ? currency(value?.basicSalaryTwo) : 0} ກີບ</div>
                            <div>ເງິນເພີ່ມທົ່ວໄປ(ລວມ): {value?.extraPaymentTwo ? currency(value?.extraPaymentTwo) : 0} ກີບ</div>
                            <div>ເງິນເພີ່ມພິເສດ(ລວມ): {value?.bestExtraAmountTwo ? currency(value?.bestExtraAmountTwo) : 0} ກີບ</div>
                            <div className="text-danger">
                              ເງິນຫັກ(ລວມ): {value?.deductionPaymentTwo ? currency(value?.deductionPaymentTwo) : 0} ກີບ
                            </div>
                            <div className="text-success">
                              ເງິນລວມສຸດທິ: {value?.paymentBalanceAmountTwo ? currency(value?.paymentBalanceAmountTwo) : 0} ກີບ
                            </div>
                          </td>
                          <td className="text-nowrap">
                            <div>ວັນທີສະຫລຸບ: {value?.consolidateDate ? formatDate(value?.consolidateDate) : "-----"}</div>
                            <div>
                              ວັນທີເລືອກຖ້ຽວລົດສະຫລຸບ: {value?.startDate ? formatDate(value?.startDate) : "----"} ຫາ{" "}
                              {value?.endDate ? formatDate(value?.endDate) : "-----"}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
