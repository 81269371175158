import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { InvalidText, MessageErrors, MessageSuccess, formatDash, notifyError, notifySuccess } from "../../../utils";
import SearchDriverVehicle from "../../../utils/components/SelectDriver";
import SearchDriverVehicleTwo from "../../../utils/components/SelectDriverTwo";
import SearchNumberVehicleOut from "../../../utils/components/SelectNumberVehicleOut";
import SearchNumberVehicle from "../../../utils/components/SelectNumberVehicle";
import SearchRouteTitle from "../../../utils/components/SelectRouteTitle";
import SearchCenterRoute from "../../../utils/components/SelectCenterRoute";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import Schema from "../shippingRoute/Schema";

type TypeValue = {
  routeLocationID: number;
  vehicleID: number;
  driverID: number;
  driverTwo: number;
  isDif: string;
  difNote: string;
  isDay: string;
  checkRoute: string;
  pay: string;
  centerRoute: number;
  createdAt: any;
  endTime: any;
};

const initialValues: TypeValue = {
  routeLocationID: 0,
  vehicleID: 0,
  driverID: 0,
  driverTwo: 0,
  isDif: "",
  difNote: "",
  isDay: "",
  checkRoute: "",
  pay: "",
  centerRoute: 0,
  createdAt: "",
  endTime: "",
};

// create component
export default function UpdateShippingRouteChecking({ Items }: any) {
  const [show, setShow] = useState(false);
  const [checkRoute, setCheckRoute] = useState<any>(Items?.checkRoute);
  const [isDay, setIsDay] = useState<any>(Items?.isDay);
  const [isDif, setIsDif] = useState<any>(Items?.isDif);
  const [listItems, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [RouteID, setRouteID] = useState<any>(Items?.routeLocationID?._id);
  const [UPdateData, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_LOCATION);
  const [FetchData, { data }] = useLazyQuery(Schema.QUERY_LOCATION_BRANCH, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    if (!RouteID) return;
    FetchData({
      variables: {
        where: {
          locationID: Number(RouteID),
        },
      },
    });
  }, [FetchData, RouteID]);
  useEffect(() => {
    setItems(data?.locationGroupBranches?.data || []);
    setTotal(data?.locationGroupBranches?.total || 0);
  }, [data]);

  // useFormik
  const { values, handleChange, submitForm, isSubmitting, setValues, setSubmitting, resetForm, setFieldValue } = useFormik<TypeValue>({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        const checking_route = await UPdateData({
          variables: {
            data: {
              ...values,
              pay: String(values?.pay) ? String(values?.pay) : "0",
              endTime: formatDash(values?.endTime) ? formatDash(values?.endTime) : "",
            },
            where: {
              sp_id: Items?.sp_id,
            },
          },
        });
        if (checking_route) {
          notifySuccess(MessageSuccess.update);
          setSubmitting(false);
          resetForm();
          setShow(false);
        }
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });
  useEffect(() => {
    if (!show) return;
    setValues({
      routeLocationID: Items?.routeLocationID?._id || 0,
      vehicleID: Items?.vehicleID?._id || 0,
      driverID: Items?.driverID?._id || 0,
      driverTwo: Items?.driverTwo?._id || 0,
      isDif: Items?.isDif || "",
      difNote: Items?.difNote || "",
      isDay: Items?.isDay || "",
      checkRoute: Items?.checkRoute || "",
      pay: Items?.pay || "",
      centerRoute: Items?.centerRoute?.st_id || 0,
      createdAt: Items?.createdAt ? formatDash(Items?.createdAt) : "",
      endTime: Items?.endTime ? formatDash(Items?.endTime) : "",
    });
  }, [setValues, show, Items]);

  return (
    <>
      <button disabled={Items?.newLocation === "YES"} className="btn btn-primary" onClick={() => setShow(true)}>
        <i className="fas fa-edit"></i>
      </button>
      <Modal show={show} dialogClassName="modal-width-95" animation={false}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-edit"></i>ຟອມແກ້ໄຂຖ້ຽວລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-9">
              <Row>
                <Col xs={6} md={2}>
                  ເລືອກປະເພດສາຍ:{InvalidText()}
                </Col>
                <Col xs={12} md={9}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      name="checkRoute"
                      value={values?.checkRoute}
                      onChange={(e: any) => {
                        setFieldValue("checkRoute", e.target.value);
                        setCheckRoute(e.target.value);
                      }}
                    >
                      <option>ເລືອກປະເພດສາຍ</option>
                      <option value="IN">ສາຍໃນ</option>
                      <option value="OUT">ສາຍນອກ</option>
                      <option value="SPARE">ສາຍສຳຮອງ</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {values?.checkRoute === "IN" ? (
                  <>
                    <Col xs={6} md={2} className="mt-2">
                      ໂຊເຟີ້(1):
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <SearchDriverVehicle
                          value={values?.driverID}
                          onChange={(res: any) => {
                            setFieldValue("driverID", res.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={2} className="mt-2">
                      ໂຊເຟີ້(2):
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <SearchDriverVehicleTwo
                          value={values?.driverTwo}
                          onChange={(res: any) => {
                            setFieldValue("driverTwo", res?.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : null}
                {values?.checkRoute !== "IN" ? (
                  <>
                    <Col xs={6} md={2} className="mt-2">
                      ເບີລົດ:{InvalidText()}
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <SearchNumberVehicleOut
                          value={values?.vehicleID}
                          onChange={(res: any) => {
                            setFieldValue("vehicleID", res?.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={6} md={2} className="mt-2">
                      ເບີລົດ:{InvalidText()}
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <SearchNumberVehicle
                          value={values?.vehicleID}
                          onChange={(res: any) => {
                            setFieldValue("vehicleID", res?.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col xs={6} md={2} className="mt-2">
                  ກະອອກລົດ:{InvalidText()}
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group className="mb-3">
                    <Form.Select
                      name="isDay"
                      value={values?.isDay}
                      onChange={(e: any) => {
                        setFieldValue("isDay", e.target.value);
                        setIsDay(e.target.value);
                      }}
                    >
                      <option>ເລືອກກະອອກລົດ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                      <option value="1">ພາກແລງ</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {values?.isDay ? (
                  <>
                    <Col xs={6} md={2} className="mt-2">
                      ສາຍຂົນສົ່ງ:{InvalidText()}
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <SearchRouteTitle
                          isDay={isDay}
                          checkRoute={checkRoute}
                          value={values?.routeLocationID}
                          onChange={(res: any) => {
                            setFieldValue("routeLocationID", res?.value);
                            setRouteID(res?.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={6} md={2} className="mt-2">
                      ສາຍຂົນສົ່ງ:{InvalidText()}
                    </Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <Form.Select>
                          <option value="#">ບໍ່ມີຂໍ້ມູນສາຍຂົນສົ່ງ</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col xs={6} md={2} className="mt-2">
                  ກໍລະນີຈັດສາຍ:{InvalidText()}
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group className="mb-3">
                    <Form.Select
                      name="isDif"
                      value={values?.isDif}
                      onChange={(e: any) => {
                        setFieldValue("isDif", e.target.value);
                        setIsDif(e.target.value);
                      }}
                    >
                      <option value="">---ເລືອກຈັດສາຍ---</option>
                      <option value="0">ປົກກະຕິ</option>
                      <option value="1">ເສີມ</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                {values?.isDif === "1" ? (
                  <>
                    <Col xs={6} md={2} className="mt-2"></Col>
                    <Col xs={12} md={9} className="mt-2">
                      <Form.Group className="mb-3">
                        <Form.Label>ກໍລະນີເປັນຖ້ຽວເສີມ</Form.Label>
                        <Form.Select
                          name="pay"
                          value={values?.pay}
                          onChange={(e: any) => {
                            setFieldValue("pay", e.target.value);
                          }}
                        >
                          <option value="">---ເລືອກ---</option>
                          <option value="0">ຈ່າຍແລ້ວ</option>
                          <option value="1">ຍັງບໍ່ທັນຈ່າຍ</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </>
                ) : null}

                <Col xs={6} md={2} className="mt-2">
                  ເລືອກສູນປາຍທາງ:{InvalidText()}
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group className="mb-3">
                    <SearchCenterRoute
                      value={values?.centerRoute}
                      onChange={(res: any) => {
                        setFieldValue("centerRoute", res?.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2} className="mt-2">
                  ວັນທີຈັດຖ້ຽວ:
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group className="mb-3">
                    <Form.Control type="dateTime" name="createAt" value={values?.createdAt} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2} className="mt-2">
                  ວັນທີຮອດປາຍທາງ:
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group className="mb-3">
                    <Form.Control type="dateTime" name="endTime" value={formatDash(values?.endTime)} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col xs={6} md={2} className="mt-2">
                  ໝາຍເຫດ:
                </Col>
                <Col xs={12} md={9} className="mt-2">
                  <Form.Group>
                    <Form.Control name="difNote" as="textarea" rows={3} onChange={handleChange} value={values?.difNote} />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="col-sm-3">
              <div className="card" style={{ marginInlineStart: -80 }}>
                <div className="card-header">ຊື່ສາຂາໃນສາຍ/ທີ່ຢູ່</div>
                {total ? (
                  <div className="card-body">
                    {listItems?.map((item: any, index: number) => (
                      <>
                        <div className="btn btn-sm btn-light2 justify-content-between d-flex" key={index}>
                          <h6>{item?.branch_id?.branch_name}</h6> | <h6>{item?.branch_id?.branch_address}</h6>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                ) : (
                  <>
                    <h6 className="text-center mt-2">ບໍ່ມີສາຂາ</h6>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
