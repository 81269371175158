import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "../../../utils/components/Schema";
import { Gender } from "../../../utils";

type SelectDriverProps = {
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  onChange?: (e: { value: number; label: string }) => void;
  styleConfig?: StylesConfig;
  selectDrivers: any;
};

type Options = {
  value: number;
  label: string;
};

export default function SearchDriverVehicle({ disabled, value, onChange, styleConfig, selectDrivers }: SelectDriverProps) {
  const [selectedOption, setSelectedOption] = useState<Options>();
  const [items, setItems] = useState<any>([]);
  const [fetchData, { data, loading }] = useLazyQuery(Schema.SELECT_USER_DRIVER, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (disabled) return;
    fetchData({
      variables: {
        where: {
          stStatus: 1,
          role: "DRIVER" || "DRIVER_OWN",
        },
        limit: 600,
      },
    });
  }, [fetchData, disabled]);

  useEffect(() => {
    if (!data?.users?.data?.length) {
      setItems([]);
      return;
    }
    const newData = data?.users?.data?.map((item: any) => {
      return { ...item, value: item?._id, label: Gender(item?.gender) + " " + item?.first_name + " " + item?.last_name };
    });
    setItems(newData);
  }, [data]);


  //   set value
  useEffect(() => {
    let newSelectedOption = { value: 0, label: "ເລືອກຄ່າເລີ່ມຕົ້ນ" };

    if (value) {
      const result = items?.find((item: any) => item.value === value);
      if (result) {
        newSelectedOption = result;
      }
    } else if (selectDrivers?.driver?._id) {
      newSelectedOption = {
        value: selectDrivers.driver._id,
        label: selectDrivers.driver.last_name || "ບໍ່ມີຂໍ້ມູນ",
      };
    }

    setSelectedOption(newSelectedOption);
  }, [items, value, selectDrivers]);


  return (
    <div>
    <Select
      isDisabled={disabled}
      value={selectedOption}
      placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກໂຊເຟີ້ລົດ"}
      isLoading={loading}
      onChange={(res: any) => {
        onChange?.(res);
      }}
      options={[{ value: 0, label: "ເລືອກຄ່າເລີ່ມຕົ້ນ" }, ...items]}
      styles={styleConfig}
    />
  </div>
  );
}
