import React, { useEffect, useState } from "react";
import TableData from "../../../utils/components/TableData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import Schema from "../centralSeparation/Schema";
import { ChildPath, ParentPath } from "../../../routes/Path";
import _ from "lodash";
import { Updated } from "./component";

export default function AddVehicle() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [params, setParams] = useSearchParams();
  const getCenterID = params.get("centerID");
  const getTitle = params.get("titleCenter");
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    FetchData({
      variables: {
        where: {},
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, searchValue, pageNumber, reloading, numberRow]);
  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  // lodash

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li
            className="breadcrumb-item active"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${ParentPath.driverPartnerSeparation}/${ChildPath.containVehicleCentral}`)}
          >
            ລາຍຊື່ສູນຄັດແຍກ
          </li>
          <li className="breadcrumb-item active">ບັນຈຸລົດເຂົ້າສູນ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header border-bottom">
            <h4>{getTitle}</h4>
          </div>
          <div className="card-body">
            <div className="row blog">
              <div className="col-md-12 p-2">
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ເບີລົດ</th>
                      <th className="text-nowrap ">ປະເພດລົດ</th>
                      <th className="text-nowrap ">ທະບຽບລົດ</th>
                      <th className="text-nowrap">ບັນຈຸຢູ່ສູນ</th>
                      <th className="text-nowrap text-center">ບູລິມະສິດ</th>
                      <th className="text-nowrap text-center">ຈັດການ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-center text-nowrap">{value?.vehicleName || "----"}</td>
                          <td className="text-nowrap">{value?.VehicleType?.name || "----"}</td>
                          <td className="text-nowrap">{value?.sign || "---"}</td>
                          <td className="text-nowrap">{value?.center?.centerName || "----"}</td>
                          {value?.isRent === 1 ? (
                            <td className="text-center text-nowrap text-success">ລົດຮ່ວມ</td>
                          ) : (
                            <td className="text-center text-nowrap text-danger">ລົດບໍລິສັດ</td>
                          )}
                          <td className="text-center text-nowrap">
                            <Updated
                              ID={getCenterID}
                              _id={value?._id}
                              onSuccess={() => setReloading(!reloading)}
                              disabled={value?.center?.st_id > 0}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
