import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Schema from "./Schema";
import { Gender, RootVariable, checkUserRole, currency, endOfMonth, formatDateTime, startOfMonth } from "../../../utils";
import TableData from "../../../utils/components/TableData";
import SearchCenter from "../../../utils/components/SelectCenter";
import { useAppContext } from "../../../store";
import SearchDriverVehicleConsolidate from "../../../utils/components/SelectDriverConsolidate";
import { CancelBackAll, ConsolidateAll, ConsolidateCenter, ConsolidateDriver, DeleteBack } from "./component";

export default function SummaryTotal() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [reloading, setReloading] = useState(false);
  const [driverID, setDriverID] = useState<any>();
  const [startDate, setStartDate] = useState(startOfMonth());
  const [endDate, setEndDate] = useState(endOfMonth());
  const [center, setCenter] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { reloadData, setReloadData } = useAppContext();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_CONSOLIDATE_ROUTES, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
           driverConfirmed: 0,
          isDif: ["FALSE" ,"TRUE"],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [numberRow, pageNumber, searchValue, reloadData.deleteItemBack, FetchData , reloading]);
  useEffect(() => {
    if (loading) {
      setTotal(0);
      setItems([]);
    }
    setItems(data?.consolidateSummaries?.data || []);
    setTotal(data?.consolidateSummaries?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _driver = searchParams.get("driver");
    const _center = searchParams.get("center");

    const decodeDriver = _driver ? atob(_driver) : undefined;
    const decodeCenter = _center ? atob(_center) : undefined;

    setCenter(decodeCenter ? decodeCenter : undefined);
    setDriverID(decodeDriver ? decodeDriver : undefined);

    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item" onClick={() => navigate(`/summarize_destination_vehicle`)} style={{ cursor: "pointer" }}>
            ລາຍງານຂໍ້ມູນການສະຫລຸບທັງໝົດ
          </li>
          <li className="breadcrumb-item">ສະຫຼຸບຖ້ຽວລົດ</li>
        </ol>
      </div>
      <div className="main-container">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-8">
                <h3>ສະຫຼຸບຖ້ຽວລົດ</h3>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["ADMIN", "SUPER_ADMIN"]) ? (
                    <CancelBackAll onSuccess={() => setReloading(!reloading)} _data={items} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-8">
                    <label className="mb-3">ເລືອກວັນທີຈັດຖ້ຽວລົດເພື່ອສະຫລຸບ</label>
                    <div className="input-group">
                      <input type="date" value={startDate} className="form-control" onChange={(e: any) => setStartDate(e.target.value)} />
                      <input type="date" value={endDate} className="form-control" onChange={(e: any) => setEndDate(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-4 mt-4">
                    <div className="d-grid gap-2 mt-3">
                      {checkUserRole(["ADMIN", "SUPER_ADMIN", "HR" , "AUDITOR"]) ? (
                        <ConsolidateAll onSuccess={() => setReloading(!reloading)} _startDate={startDate} _endDate={endDate} />
                      ) : null}
                    </div>
                  </div>
                </div>
                &nbsp;
                <div className="row">
                  <div className="col-sm-8">
                    <label className="mb-3">ສະຫຼຸບສະເພາະລາຍຊື່ </label>
                    <SearchDriverVehicleConsolidate
                      onChange={(option: any) => {
                        searchParams.set("driver", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-4 mt-4">
                    <div className="d-grid gap-2 mt-3">
                      {checkUserRole(["ADMIN", "SUPER_ADMIN", "HR" , "AUDITOR"]) ? (
                        <ConsolidateDriver
                          onSuccess={() => setReloading(!reloading)}
                          driverID={driverID}
                          _startDate={startDate}
                          _endDate={endDate}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                &nbsp;
                <div className="row">
                  <div className="col-sm-8">
                    <label className="mb-3">ສະຫຼຸບຕາມສູນ</label>
                    <SearchCenter
                      onChange={(option: any) => {
                        searchParams.set("center", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-4 mt-4">
                    <div className="d-grid gap-2 mt-3">
                      {checkUserRole(["ADMIN", "SUPER_ADMIN", "HR" , "AUDITOR"]) ? (
                        <ConsolidateCenter
                          onSuccess={() => setReloading(!reloading)}
                          centerID={center}
                          _startDate={startDate}
                          _endDate={endDate}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <h5 className="mt-5">ລາຍການສະຫຼຸບລໍຖ້າໂຊເຟີ້ຢືນຢັນ</h5>
                <hr />
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ຊື່ໂຊເຟີ້</th>
                      <th className="text-nowrap">ຂໍ້ມູນຖ້ຽວລົດ</th>
                      <th className="text-nowrap">ສາຍທີ່ແລ່ນ</th>
                      <th className="text-nowrap">ລາຄາສາຍ(ກີບ)</th>
                      <th className="text-nowrap">ນ້ຳມັນລວມ(ລິດ)</th>
                      <th className="text-nowrap">ຈຳນວນຖ້ຽວລົດ(ຖ້ຽວ)</th>
                      <th className="text-nowrap">ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(1)(ກີບ)</th>
                      <th className="text-nowrap">ເງິນທີ່ສະຫລຸບຂອງໂຊເຟີ້(2)(ກີບ)</th>
                      <th className="text-nowrap">ວັນທີສະຫຼຸບ</th>
                      {checkUserRole(["ADMIN", "SUPER_ADMIN", "HR"]) ? <th className="text-nowrap">ຈັດການ</th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {total === 0 ? (
                        <h5 style={{ marginTop: 20 }}>ບໍ່ມີຂໍ້ມູນ............</h5>
                      ) : (
                        <>
                          {items?.map((value, index) => (
                            <tr className={value?.isDif === "FALSE" ? "table-success" : ""} key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-nowrap">
                                <div>
                                  ໂຊເຟີ້(1): {value?.consolidateDriverID?.gender ? Gender(value?.consolidateDriverID?.gender) : ""}&nbsp;
                                  {value?.consolidateDriverID?.first_name || "----"}&nbsp;
                                  {value?.consolidateDriverID?.last_name || "----"}
                                </div>
                                <br />
                                <div>
                                  ໂຊເຟີ້(2): {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp;
                                  {value?.driverTwo?.first_name || "----"}&nbsp;
                                  {value?.driverTwo?.last_name || "----"}
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div>ກະອອກລົດ: {value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</div>
                                <div>ເບີລົດ: {value?.consolidateVehicleID?.vehicleName || "-----"}</div>
                                <div>ທະບຽບລົດ:{value?.consolidateVehicleID?.sign || "---"}</div>
                                <div>ສາຍລົດ: {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}</div>
                                <div className={value?.isDif === "FALSE" ? "text-success" : "text-danger text-nowrap"}>
                                  ປະເພດຖ້ຽວ: {value?.isDif === "FALSE" ? "ຖ້ຽວປົກກະຕິ" : "ຖ້ຽວເສີມ"}
                                </div>
                                <div>ປະຈຳສູນ: {value?.centerID?.centerName || "---"}</div>
                              </td>
                              <td className="text-nowrap">{value?.consolidateRouteID?.title || "----"}</td>
                              <td className="text-nowrap">{value?.consolidateRouteID?.priceDay || 0} ກີບ</td>
                              <td className="text-nowrap">{value?.petroleum || 0} ລິດ</td>
                              <td className="text-nowrap">{value?.consolidateTotalRoute || 0}</td>
                              <td className="text-nowrap">
                                <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRoute ? currency(value?.consolidateAmountRoute) : 0} ກີບ</div>
                                <div>ເງິນເດືອນພື້ນຖານຂອງໂຊເຟີ້(1): {value?.basicSalaryOne ? currency(value?.basicSalaryOne) : 0} ກີບ</div>
                                <div>ເງິນເພີ່ມທົ່ວໄປ(ລວມ): {value?.extraPayment ? currency(value?.extraPayment) : 0} ກີບ</div>
                                <div>ເງິນເພີ່ມພິເສດ(ລວມ): {value?.bestExtraAmount ? currency(value?.bestExtraAmount) : 0} ກີບ </div>
                                <div className="text-danger">ເງິນຫັກ(ລວມ):{value?.deductionPayment ? currency(value?.deductionPayment) : 0} ກີບ</div>
                                <div className="text-success">
                                  ເງິນລວມສຸດທິ:{value?.paymentBalanceAmount ? currency(value?.paymentBalanceAmount) : 0} ກີບ
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div>ລວມມູນຄ່າຖ້ຽວ: {value?.consolidateAmountRouteTwo ? currency(value?.consolidateAmountRouteTwo) : 0} ກີບ</div>
                                <div>ເງິນເດືອນພື້ນຖານຂອງໂຊເຟີ້(2): {value?.basicSalaryTwo ? currency(value?.basicSalaryTwo) : 0} ກີບ</div>
                                <div>ເງິນເພີ່ມທົ່ວໄປ(ລວມ): {value?.extraPaymentTwo ? currency(value?.extraPaymentTwo) : 0} ກີບ</div>
                                <div>ເງິນເພີ່ມພິເສດ(ລວມ): {value?.bestExtraAmountTwo ? currency(value?.bestExtraAmountTwo) : 0} ກີບ</div>
                                <div className="text-danger">
                                  ເງິນຫັກ(ລວມ): {value?.deductionPaymentTwo ? currency(value?.deductionPaymentTwo) : 0} ກີບ
                                </div>
                                <div className="text-success">
                                  ເງິນລວມສຸດທິ:{value?.paymentBalanceAmountTwo ? currency(value?.paymentBalanceAmountTwo) : 0} ກີບ
                                </div>
                              </td>
                              <td className="text-nowrap">{value?.consolidateDate ? formatDateTime(value?.consolidateDate) : "----"}</td>
                              {checkUserRole(["ADMIN", "SUPER_ADMIN", "HR"]) ? (
                                <td className="text-nowrap">
                                  <DeleteBack onSuccess={() => setReloadData("deleteItemBack")} items={value} />
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </>
                      )}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
