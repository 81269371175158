import { gql } from "@apollo/client";
export default class Schema {
  static QUERY_CONSOLIDATE_ROUTES = gql`
    query ConsolidateSummaries($where: consolidateSummaryWhereInput, $limit: Int, $skip: Int) {
      consolidateSummaries(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          consolidateID
          consolidateDriverID {
            _id
            first_name
            last_name
            account_bank_name
            account_bank_number
            gender
            stStatus {
              cp_id
              servicesMoney
              cp_name
            }
            center {
              st_id
              centerName
            }
          }
          consolidateVehicleID {
            _id
            vehicleName
            sign
          }
          consolidateRouteID {
            _id
            title
            petroleum
            priceDay
            priceNight
          }
          consolidateTotalRoute
          consolidateAmountRoute
          consolidateDate
          consolidateBy {
            _id
            first_name
            last_name
            gender
          }
          deductionPayment
          extraPayment
          borrowPayment
          bonusPayment
          textIncome
          paymentBalanceAmount
          consolidatePaidStatus
          consolidatePaidDate
          paidBy {
            _id
            first_name
            last_name
            gender
          }
          isDif
          consolidateUpdatedAt
          isDay
          checkRoute
          pay
          petroleum
          startDate
          endDate
          noID
          auditorConfirm
          auditorID
          auditorConfirmDate
          driverTwo {
            _id
            first_name
            last_name
            account_bank_name
            account_bank_number
            gender
            stStatus {
              cp_id
              servicesMoney
              cp_name
            }
            center {
              st_id
              centerName
            }
          }
          basicSalaryOne
          basicSalaryTwo
          consolidateAmountRouteTwo
          deductionPaymentTwo
          extraPaymentTwo
          paymentBalanceAmountTwo
          driverConfirmedTwo 
          driverConfirmed
          centerID {
            st_id
            centerName
          }
          companyID {
            cp_id
            cp_name
            cp_code
            servicesMoney
          }
          bestExtraAmountTwo
          bestExtraAmount
        }
      }
    }
  `;
  static UPDATE_ITEM_BACK = gql`
    mutation UpdateConsolidateSummaryItemBack($where: whereItemBackID!, $data: consolidateSummaryInput) {
      updateConsolidateSummaryItemBack(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static CREATE_CONSOLIDATE = gql`
  mutation CreateConsolidateSummary($data: consolidateSummaryInput!) {
    createConsolidateSummary(data: $data) {
      status
      data
    }
  }
  `;


  static CONSOLIDATE_CENTER_ROUTES = gql`
    mutation CreateConsolidateCenter($data: ConsolidateCenterInput!) {
      createConsolidateCenter(data: $data) {
        status
        data
      }
    }
  `;

  static CONSOLIDATE_ALL_ROUTES = gql`
    mutation CreateConsolidateAll($data: ConsolidateAllInput!) {
      createConsolidateAll(data: $data) {
        status
        data
      }
    }
  `;

  static DELETE_ITEMS_ROUTES_CANCEL_ALL = gql`
    mutation UpdateConsolidateSummaryCancelAll($where: WhereByMultipleId!, $data: consolidateSummaryInput) {
      updateConsolidateSummaryCancelAll(where: $where, data: $data) {
        status
        data
      }
    }
  `;


}
