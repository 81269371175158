import { useState, useEffect } from "react";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, notifyError, notifySuccess } from "../../../utils";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import { IoAddCircleSharp } from "react-icons/io5";

const FormLayout = ({ values, errors, handleChange }: any) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>ປະເພດລົດ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="ປະເພດລົດ"
          onChange={handleChange}
          value={values?.name}
          isInvalid={errors?.name ? true : false}
        />
      </Form.Group>
    </>
  );
};

const initialValues: any = {
  name: "",
};
const validate = (values: any) => {
  const errors: any = {};
  if (!values?.name) errors.name = "ກະລຸນາປ້ອນປະເພດລົດ";
  return errors;
};

// create component
const Create = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_TYPE_VEHICLE);

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<any>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values) => {
      try {
        await createData({
          variables: { data: values },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
      </Form>
      <Button disabled={isSubmitting || loading} onClick={submitForm}>
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <IoAddCircleSharp />}
        <span className="ms-1">ເພີ່ມປະພດລົດ</span>
      </Button>
    </>
  );
};

// delete component
type DeleteProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
};

const Delete = ({ _id, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_TYPE_VEHICLE);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  _id: _id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};
const Update = ({ item, onSuccess, className }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_TYPE_VEHICLE);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<any>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: { data: values, where: { _id: item?._id } },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      name: item?.name || "",
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show}>
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ແກ້ໄຂຂໍ້ມູນປະເພດລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const TypeVehicle = {
  Create,
  Delete,
  Update,
};

export default TypeVehicle;
