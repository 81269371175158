import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, notifyError, notifySuccess } from "../../../utils";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import SearchDriver from "./SelectDriver";

type DataProps = {
  onSuccess: () => void;
  className?: string;
  _id: number;
  disabled: any;
  items: any;
};

export const AddDriverPropsContainDriver = ({ onSuccess, className, _id, disabled ,items }: DataProps) => {
  const [show, setShow] = useState(false);
  const [selectDrivers1, setSelectDrivers1] = useState<any>(null);
  const [getData, setData] = useState<any>();
  const [getData2, setData2] = useState<any>();
  const [updateData, { loading }] = useMutation(Schema.UPDATE_DRIVER_TO_VEHICLE);

  const Submit = async () => {
    try {
      if (getData !== getData2) {
        await updateData({
          variables: {
            data: {
              driver: Number(getData) ? Number(getData) : undefined,
            },
            where: {
              _id: _id,
            },
          },
        });
        notifySuccess(MessageSuccess.insert);
        onSuccess?.();
        setShow(false);
      } else {
        notifyError(MessageErrors.checkNameDriver);
      }
    } catch (error) {
      notifyError(MessageErrors.insert);
    }
  };

  return (
    <React.Fragment>
      <button type="button" className="btn btn-primary text-nowrap" onClick={() => setShow(true)} disabled={disabled}>
        <i className="fas fa-plus" /> ເພີ່ມ
      </button>
      <Modal show={show}>
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-add"></i>ເລືອກໂຊເຟີລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>ລາຍຊື່ໂຊເຟີລົດ(1){InvalidText()}</Form.Label>
            <SearchDriver
              value={getData}
              onChange={(res: any) => {
                setData(res?.value);
                setSelectDrivers1(items);
              }}

              selectDrivers={selectDrivers1}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className={className} disabled={!getData} onClick={Submit}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />} ສົ່ງຟອມ
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

// delete component
type DeleteProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
  type?: string;
};

export const DeleteContainDriver = ({ _id, onSuccess, className, type }: DeleteProps) => {
  const [updateDriver, { loading }] = useMutation(Schema.UPDATE_DRIVER_TO_VEHICLE);

  const handleDelete = async (driverType: any) => {
    try {
      if (driverType === "driver") {
        const updateData = { driver: 0 };
        await updateDriver({
          variables: {
            data: updateData,
            where: {
              _id: _id,
            },
          },
        });
        notifySuccess(MessageSuccess.deleteDriver);
        onSuccess?.();
      }
    } catch (error) {
      notifyError(MessageErrors.deleteDriver);
    }
  };

  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.deleteDriver, async () => {
          await handleDelete(type);
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};
