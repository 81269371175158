import { useState } from "react";
import "./App.scss";
import { ContextValues, AppContext } from "./store";
import Routes from "./routes/index";
import ApolloClientProvider from "./configs/ApolloClientProvider";

function App() {
  const [reloadTotal, setStateReloadTotal] = useState<ContextValues>({
    deleteItemBack: false,
  });
  const [reloadData, setStateReloadData] = useState<ContextValues>({
    deleteItemBack: false,
  });

  const setReloadTotal = (keyName: string) => {
    const key = keyName as keyof typeof reloadTotal;
    setStateReloadTotal((state) => ({ ...state, [key]: !state[key] }));
  };

  const setReloadData = (keyName: string) => {
    const key = keyName as keyof typeof reloadData;
    setStateReloadData((state) => ({ ...state, [key]: !state[key] }));
  };

  

  return (
    <AppContext.Provider value={{ reloadTotal, reloadData, setReloadTotal, setReloadData }}>
      <ApolloClientProvider>
        <Routes />
      </ApolloClientProvider>
    </AppContext.Provider>
  );
}

export default App;
