import React, { useEffect, useState } from "react";
import TableData from "../../utils/components/TableData";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Gender, RootVariable, checkUserRole, currency, formatDate, formatDateTime } from "../../utils";
import { Button, Image } from "react-bootstrap";
import Users from "../../img/user_null.png";
import Female from "../../img/female-icon.png";
import { ParentPath } from "../../routes/Path";
import ExportFile from "./ExportFile";
import EditUser from "./editUser";
import { url_upload_Profile } from "../../configs/Environment";
import { Create, Delete } from "./Component";

export default function User() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [reloading, setReloading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_USERS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          first_name: String(searchValue) ? String(searchValue) : undefined,
          status: "ACTIVED",
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, numberRow, pageNumber, searchValue, reloading]);
  
  useEffect(() => {
    if (loading) {
      setTotal(0);
      setItems([]);
      return;
    }
    setItems(data?.users?.data || []);
    setTotal(data?.users?.total || 0);
  }, [loading, data]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item active">ລາຍການຂໍ້ມູນຜູ້ໃຊ້ລະບົບ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-8">
                    <h4> ລາຍການຂໍ້ມູນຜູ້ໃຊ້ລະບົບ</h4>
                  </div>
                  <div className="col-sm-4">
                    <div style={{ float: "right" }}>
                      {checkUserRole(["SUPER_ADMIN"]) ? (
                        <>
                          <ExportFile data={data} loading={loading} />
                          <Create onSuccess={() => setReloading(!reloading)} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ຮູບໂປຣໄຟລ໌</th>
                      <th className="text-nowrap">ຊື່ ແລະ ນາມສະກຸນ</th>
                      <th className="text-nowrap">ອາຍຸ</th>
                      <th className="text-nowrap">ທີ່ຢູ່/ປະຈຳການ</th>
                      <th className="text-nowrap">ເບີໂທ</th>
                      <th className="text-nowrap">ເລກບັດປະຈຳຕົວ</th>
                      <th className="text-nowrap">ເລກທີໃບຂັບຂີ່</th>
                      <th className="text-nowrap">ເງິນເດືອນພື້ນຖານ(ກີບ)</th>
                      <th className="text-nowrap">ສິດການນຳໃຊ້</th>
                      <th className="text-nowrap">ສະຖານະ</th>
                      <th className="text-nowrap">ຊື່ບັນຊີທະນາຄານ</th>
                      <th className="text-nowrap">ເລກບັນຊີທະນາຄານ</th>
                      <th className="text-nowrap">ວັນທີເຂົ້າ - ອອກ</th>
                      <th className="text-nowrap">ວັນທີສ້າງ</th>
                      <th className="text-nowrap">ສ້າງໂດຍ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap text-center">
                            <div className="badge badge-primary mt-1" style={{ display: "block" }}>
                              {value?.image ? (
                                <Image src={url_upload_Profile + value?.image} style={{ width: 80, height: 69, borderRadius: 3 }} />
                              ) : (
                                <>
                                  {value?.gender === "FEMALE" ? (
                                    <Image src={Female} style={{ width: 80, height: 72 }} />
                                  ) : (
                                    <Image src={Users} style={{ width: 80, height: 69 }} />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="disable-cursor">
                              <Button variant="primary" size="sm" style={{ width: 80 }}>
                                ID:{value?._id}
                              </Button>
                            </div>
                          </td>
                          <td className="text-nowrap">
                            <div style={{ marginTop: 30 }}>
                              <div>
                                {value?.gender ? Gender(value?.gender) : ""} {value?.first_name || "---"} {value?.last_name || "---"}
                              </div>
                              <hr className="m-1" />
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-light"
                                  onClick={() => navigate(`${ParentPath.InformationDriver}?Driver=${value?._id}`)}
                                >
                                  <i className="fa fa-list" />
                                </button>
                                {checkUserRole(["SUPER_ADMIN"]) ? (
                                  <>
                                    <EditUser item={value} onSuccess={() => setReloading(!reloading)} />
                                    <Delete _id={value?._id} onSuccess={() => setReloading(!reloading)} />
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}> {value?.age || "---"}</div>
                          </td>
                          <td className="text-nowrap">
                            <div style={{ marginTop: 30 }}>
                              {value?.address || "---"}
                              <hr />
                              ປະຈຳການ: {value?.center?.centerName || "---"}
                            </div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}> {value?.phoneNumber || 0} </div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}> {value?.cardID || "---"}</div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}> {value?.passID || "---"} </div>
                          </td>
                          <td className="text-nowrap text-right">
                            <div style={{ marginTop: 40 }}>{currency(value?.basicSalary || 0)}</div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}>
                              {value?.role === "SUPER_ADMIN" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="success" size="sm" className="disable-cursor">
                                    ຜູ້ດູແລລະບົບ
                                  </Button>
                                </div>
                              ) : value?.role === "ADMIN" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ຜູ້ຈັດຖ້ຽວ
                                  </Button>
                                </div>
                              ) : value?.role === "USERS" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ຜູ້ໃຊ້
                                  </Button>
                                </div>
                              ) : value?.role === "DRIVER_OWN" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ໂຊເຟີ້ສ່ວນບຸກຄົນ
                                  </Button>
                                </div>
                              ) : value?.role === "AUDITOR" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ກວດສອບຖ້ຽວລົດ
                                  </Button>
                                </div>
                              ) : value?.role === "ACCOUNTANT" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ບັນຊີ
                                  </Button>
                                </div>
                              ) : (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ໂຊເຟີ້ບໍລິສັດ
                                  </Button>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}>
                              {value?.status === "ACTIVED" ? (
                                <div className="d-grid gap-2">
                                  <Button variant="success" size="sm" className="disable-cursor">
                                    ກຳລັງໃຊ້ງານ
                                  </Button>
                                </div>
                              ) : (
                                <div className="d-grid gap-2">
                                  <Button variant="danger" size="sm" className="disable-cursor">
                                    ອອກແລ້ວ
                                  </Button>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}>{value?.account_bank_name || "----"}</div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}>{value?.account_bank_number || "----"}</div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}></div>
                            ວັນທີເຂົ້າ: {value?.startDate ? formatDate(value?.startDate) : "----"}
                            <hr />
                            ວັນທີອອກ:{value?.endDate ? formatDate(value?.endDate) : "----"}
                          </td>
                          <td className="text-nowrap text-center">
                            <div style={{ marginTop: 40 }}>{value?.createdAt ? formatDateTime(value?.createdAt) : "----"}</div>
                          </td>
                          <td className="text-nowrap">
                            <div style={{ marginTop: 40 }}>
                              {value?.gender ? Gender(value?.createdBy?.gender) : ""} {value?.createdBy?.first_name}
                              {value?.createdBy?.last_name || "----"}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
