import { Button, Card, Form } from "react-bootstrap";
import "./Style.scss";
import LogoDriver from "../../img/LOGO-DRIVER06-removebg.png";
import { useFormik } from "formik";
import { notifyError, MessageErrors, LocalStorageName } from "../../utils";
import { ParentPath } from "../../routes/Path";
import { useMutation } from "@apollo/client";
import Notiflix from "notiflix";
import Schema from "./Schema";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";



type Values = {
  phoneNumber: number;
  password: string;
};

type Errors = {
  phoneNumber?: string;
  password?: string;
};

export default function Login() {
  const [login] = useMutation(Schema.login);
  const [showPassword, setShowPassword] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { handleChange, values, errors, handleSubmit } = useFormik<Values>({


    initialValues: { phoneNumber: 0, password: "" },
    validate: (values: Values) => {
      const errors: Errors = {};
      if (!values?.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
      if (!values?.password) errors.password = "ກະລຸນາປ້ອນລະຫັດຜ່ານ";
      return errors;
    },
    onSubmit: async (values: Values) => {
      Notiflix.Loading.dots();
      try {
        const { data } = await login({
          variables: {
            data: {
              phoneNumber: Number(values?.phoneNumber),
              password: values?.password,
            },
          },
        });

        Notiflix.Loading.remove();
        if (data?.userLogin?.accessToken) {
          localStorage.setItem(LocalStorageName.token, data?.userLogin?.accessToken);
          window.location.replace(ParentPath.home);
          return;
        }
        notifyError(MessageErrors.process);
      } catch (error) {
        Notiflix.Loading.remove();
        const _error = JSON.stringify(error);
        if (_error?.indexOf("INVALID_PHONE_NUMBER_OR_PASSWORD") > -1) {
          notifyError(MessageErrors.invalidPassword);
          return;
        }
        if (_error?.indexOf("BRANCH_NOT_FOUND") > -1) {
          notifyError(MessageErrors.branchNotFound);
          return;
        }
        notifyError(MessageErrors.process);
      }
    },
  });
  return (
    <div className="app-login">
      <Card>
        <Card.Header>
          <img className="logo" src={LogoDriver} alt="" />
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>
                <i className="fas fa-phone" />
                ເບີໂທລະສັບ
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="ເບີໂທລະສັບ"
                onWheel={(e) => e.currentTarget.blur()}
                name="phoneNumber"
                value={values?.phoneNumber || ""}
                onChange={handleChange}
                isInvalid={errors?.phoneNumber ? true : false}
                required
              />
              <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
            </Form.Group>
            &nbsp;
            <Form.Group className="mb-3">
              <Form.Label>
                <i className="fas fa-lock" />
                ລະຫັດຜ່ານ
              </Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword ? "password" : "text"}
                  placeholder="ລະຫັດຜ່ານ"
                  name="password"
                  value={values?.password}
                  onChange={handleChange}
                  isInvalid={errors?.password ? true : false}
                  required
                />
                <Button
                  variant="outline-secondary"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            &nbsp;
            <Button type="submit" variant="primary" className="w-100">
              <i className="fas fa-right-to-bracket" />
              ເຂົ້າສູ່ລະບົບ
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
