const Environment = {
         API: "https://api.driverpro.anousith.express/graphql",
        //    API: "http://localhost:7001/graphql",
};
export default Environment;
export const url_upload_Profile = "https://storage.googleapis.com/anousith-bucket/profileUser/";

// production
export const url_upload_file = "https://api.driverpro.anousith.express/upload_file";

export const url_upload_cardImage = "https://api.driverpro.anousith.express/upload_file_cardImages";
export const url_upload_passImage = "https://api.driverpro.anousith.express/upload_file_passImage";
export const url_upload_docFile = "https://api.driverpro.anousith.express/upload_file_docFiles";
export const url_upload_signature = "https://api.driverpro.anousith.express/upload_file_signature";
