import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootVariable, checkUserRole } from "../../../utils";
import TableData from "../../../utils/components/TableData";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { ChildPath, ParentPath } from "../../../routes/Path";
import { AddingVehicleRouteLocation_destinationVehicles } from "./component";

export default function RecordVehicle() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [params, setParams] = useSearchParams();
  const getVehicleID = params.get("routeID");
  const getTitle = params.get("title");
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLE_ROUTE_LOCATION, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    FetchData({
      variables: {
        where: {},
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, searchValue, pageNumber, reloading, numberRow]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);
  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li
            className="breadcrumb-item active"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.destinationVehicle}`)}
          >
            ຈັດການສາຍຂົນສົ່ງ
          </li>
          <li className="breadcrumb-item active">{getTitle}</li>
        </ol>
      </div>
      <TableData
        total={total}
        loading={loading}
        pageNumber={pageNumber}
        numberRow={numberRow}
        onSearchText={(val) => {
          setSearchValue(val || "");
        }}
        header={
          <tr>
            <th className="text-center text-nowrap">ລຳດັບ</th>
            <th className="text-nowrap">ປະເພດລົດ</th>
            <th className="text-nowrap">ເບີລົດ</th>
            <th className="text-nowrap">ເລກທະບຽນ</th>
            {checkUserRole(["SUPER_ADMIN"]) ? (
              <th className="text-center text-nowrap">
                <i className="fas fa-gear"></i>ຈັດການ
              </th>
            ) : null}
          </tr>
        }
        body={
          <>
            {items?.map((value, index) => (
              <tr key={index}>
                <td className="text-center text-nowrap">{index + 1}</td>
                <td className="text-nowrap">{value?.VehicleType?.name || "---"}</td>
                <td className="text-nowrap">{value?.vehicleName || "---"}</td>
                <td className="text-nowrap">{value?.sign || "----"}</td>
                {checkUserRole(["SUPER_ADMIN"]) ? (
                  <td className="text-center text-nowrap">
                    <AddingVehicleRouteLocation_destinationVehicles
                      _id={value?._id}
                      vehicleID={getVehicleID}
                      onSuccess={() => setReloading(!reloading)}
                      disabled={value?.routeID?._id > 0}
                    />
                  </td>
                ) : null}
              </tr>
            ))}
          </>
        }
      />
    </React.Fragment>
  );
}
