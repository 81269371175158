import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChildPath, ParentPath } from "../../../routes/Path";
import { useLazyQuery } from "@apollo/client";
import { RootVariable, checkUserRole, currency } from "../../../utils";
import Schema from "./Schema";
import TableData from "../../../utils/components/TableData";
import { FormLayout, Updated, UpdatedOnlyOne } from "./component";

export default function ModifyDestinationVehicle() {
  const navigate = useNavigate();
  const [items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);

  const [FetchData, { data, loading }] = useLazyQuery(Schema.UPDATE_ROUTE_LOCATION_PRICE, { fetchPolicy: "cache-and-network" });

  let _data = data?.updatePriceNews?.data[0]?.priceNew;


  useEffect(() => {
    FetchData({
      variables: {
        where: {},
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue]);
  useEffect(() => {
    if (loading) {
      setItems([]);
      return;
    }
    setItems(data?.updatePriceNews?.data || []);
    setTotal(data?.updatePriceNews?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ແກ້ໄຂລາຄາສາຍຂົນສົ່ງ</li>
        </ol>
      </div>
      <div className="main-container mt-4">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-6">
                <h3>ລາຍງານການແກ້ໄຂລາຄາສາຍຂົນສົ່ງ</h3>
              </div>
              <div className="col-sm-6">
                <div style={{ float: "right" }}>
                  {/* update price all  */}
                  {checkUserRole(["SUPER_ADMIN"]) ? (
                    <>
                      <Updated onSuccess={() => setReloading(!reloading)} item={items} disabled={_data} />
                      &nbsp;&nbsp;
                    </>
                  ) : null}
                  <button className="btn btn-primary" onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.HistoryComponent}`)}>
                    <i className="fas fa-list"></i>ປະຫວັດການແກ້ໄຂລາຄາສາຍ
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                {/* update price  */}
                <FormLayout onSuccess={() => setReloading(!reloading)}  />
                <br />
                <br />
                <>
                  <TableData
                    total={total}
                    loading={loading}
                    pageNumber={pageNumber}
                    numberRow={numberRow}
                    onSearchText={(val) => {
                      setSearchValue(val || "");
                    }}
                    header={
                      <tr>
                        <th className="text-nowrap text-center">ລຳດັບ</th>
                        <th className="text-nowrap">ຊື່ສາຍ</th>
                        <th className="text-nowrap ">ລະຫັດສາຍ</th>
                        <th className="text-nowrap text-center">ສາຍ(ພາກ)</th>
                        <th className="text-nowrap text-center">ລາຄາເກົ່າ(ກີບ)</th>
                        <th className="text-nowrap text-center">ລາຄາທີ່ຈະອັບເດດ(ກີບ)</th>
                        <th className="text-nowrap text-center">ຈັດການ</th>
                      </tr>
                    }
                    body={
                      <>
                        {items?.map((value, index) => (
                          <tr className="tbody" key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-nowrap">{value?.title || "----"}</td>
                            <td className="text-nowrap">{value?.code || "----"}</td>
                            <td className="text-nowrap text-center">{value?.lg_isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</td>
                            <td className="text-nowrap text-center ">
                              {value?.lg_isDay === "1" ? <p>{currency(value?.priceNight || 0)} ກີບ</p> : <p>{currency(value?.priceDay || 0)} ກີບ</p>}
                            </td>
                            <td className="text-center">{currency(value?.priceNew || 0)} ກີບ</td>
                            <td className="text-center">
                            <UpdatedOnlyOne onSuccess={() => setReloading(!reloading)} item={value} disabled={_data} />
                            </td>
                          </tr>
                        ))}
                      </>
                    }
                  />
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
