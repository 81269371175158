import React, { useState, useEffect, useRef } from "react";
import {
  InvalidText,
  MessageConfirms,
  MessageErrors,
  MessageSuccess,
  confirmShow,
  currency,
  formatDate,
  formatDateTime,
  formatDateUpdate,
  getStaffLogin,
  notifyError,
  notifySuccess,
} from "../../../utils";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import SearchDriver from "../../../utils/components/SelectDriver";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { BiPrinter } from "react-icons/bi";
import { CiMoneyBill } from "react-icons/ci";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BsFileEarmarkExcel } from "react-icons/bs";
import Logo from "../../../assets/images/ans-logo-red.svg";
import _ from "lodash";

type TypeValue = {
  extraDriverID: number;
  exTraAmount: number;
  exTraDate: string;
  exTraNote: string;
  exTraStatus: string;
  isExtra: number;
};

type TypeError = {
  exTraNote?: string;
  extraDriverID?: string;
  exTraAmount?: string;
  exTraDate?: string;
  exTraStatus?: string;
};

type FormLayoutProp = {
  values: TypeValue;
  errors: TypeError;
  handleChange: any;
  setFieldValue: any;
  NotDisplay: any;
  display: any;
};

const FormLayout = ({ values, errors, handleChange, setFieldValue, NotDisplay, display }: FormLayoutProp) => {
  const [stStatus, setStStatus] = useState<any>();

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>ໂຊເຟີ້ລົດ {InvalidText()}</Form.Label>
        <SearchDriver
          value={values?.extraDriverID}
          onChange={(res: any) => {
            setFieldValue("extraDriverID", res?.value);
            setStStatus(res?.stStatus?.cp_id);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ຈຳນວນເງິນເພີ່ມ {InvalidText()}</Form.Label>
        <Form.Control
          type="number"
          name="exTraAmount"
          placeholder="ຈຳນວນເງິນ"
          onChange={handleChange}
          value={values?.exTraAmount}
          isInvalid={errors?.exTraAmount ? true : false}
          onWheel={(e: any) => e.target.blur()}
        />
      </Form.Group>
      {stStatus === 1 ? (
        <Form.Group className="mb-3">
          <Form.Label>ເລືອກໝວດ</Form.Label>
          <Form.Select
            value={values?.isExtra}
            onChange={(e: any) => {
              setFieldValue("isExtra", e.target.value);
            }}
          >
            <option value="0">ເງິນເພີ່ມທົ່ວໄປ</option>
            <option value="1">ເງິນເພີ່ມພິເສດ</option>
          </Form.Select>
        </Form.Group>
      ) : null}

      <Form.Group className="mb-3">
        <Form.Label>ວັນທີ່ {InvalidText()}</Form.Label>
        <Form.Control
          type="date"
          name="exTraDate"
          placeholder="ວັນທີ"
          onChange={handleChange}
          value={values?.exTraDate}
          isInvalid={errors?.exTraDate ? true : false}
        />
      </Form.Group>
      {NotDisplay === 1 ? (
        <></>
      ) : display === 2 ? (
        <Form.Group className="mb-3">
          <Form.Label>ເລືອກສະຖານະຊຳລະ</Form.Label>
          <Form.Select
            value={values?.exTraStatus}
            name="exTraStatus"
            onChange={(e: any) => {
              setFieldValue("exTraStatus", e.target.value);
            }}
          >
            <option value="PAID">ຊຳລະແລ້ວ</option>
            <option value="UNPAID">ຍັງບໍ່ທັນຊຳລະ</option>
          </Form.Select>
        </Form.Group>
      ) : null}

      <Form.Group className="mb-3">
        <Form.Label>ໝາຍເຫດ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="exTraNote"
          placeholder="ໝາຍເຫດ"
          onChange={handleChange}
          value={values?.exTraNote}
          isInvalid={errors?.exTraNote ? true : false}
          as="textarea"
          rows={4}
        />
      </Form.Group>
    </>
  );
};

const initialValues: TypeValue = {
  exTraDate: "",
  exTraNote: "",
  exTraAmount: 0,
  extraDriverID: 0,
  exTraStatus: "UNPAID",
  isExtra: 0,
};
const validate = (values: TypeValue) => {
  const errors: TypeError = {};
  if (!values?.exTraAmount) errors.exTraAmount = "ກະລຸນາປ້ອນຈຳນວນເງິນເພີ່ມ";
  if (!values?.exTraNote) errors.exTraNote = "ກະລຸນາປ້ອນໝາຍເຫດ";
  if (!values?.exTraDate) errors.exTraDate = "ກະລຸນາເລືອກວັນທີ";
  return errors;
};

// create component
export const Create = ({ onSuccess, NotDisplay, display }: any) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CERATE_EXTRA_PAYMENT);
  const userID = getStaffLogin()?._id;

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<TypeValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values) => {
      try {
        await createData({
          variables: {
            data: {
              ...values,
              exTraCreatedBy: Number(userID),
              isExtra: Number(values?.isExtra),
            },
          },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button type="button" variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add"></i>ເພີ່ມຂໍ້ມູນເງິນເພີ່ມ
      </Button>

      <Modal show={show} size="lg">
        <Modal.Header closeButton={!loading} onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-add"></i>ເພີ່ມເງິນໃຫ້ໂຊເຟີ້
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              NotDisplay={NotDisplay}
              display={display}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  exTraID: number;
  onSuccess: () => void;
  className?: string;
};
export const Delete = ({ exTraID, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_EXTRA_PAYMENT);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  exTraID: exTraID,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
  display?: any;
};
export const Update = ({ item, onSuccess, className, display }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_EXTRA_PAYMENT);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<TypeValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: { data: { ...values, isExtra: Number(values?.isExtra) }, where: { exTraID: item?.exTraID } },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      exTraDate: item?.exTraDate ? formatDateUpdate(item?.exTraDate) : "" || "",
      extraDriverID: item?.extraDriverID?._id || 0,
      exTraAmount: item?.exTraAmount || 0,
      exTraNote: item?.exTraNote || "",
      exTraStatus: item?.exTraStatus || "",
      isExtra: item?.isExtra || 0,
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-edit" />
            ຟອມແກ້ໄຂເງິນເພີ່ມໂຊເຟີ້
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} NotDisplay display={display} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// export excel and print data

export const ExportData = ({ data, loading }: { data: any; loading: any }) => {
  const [show, setShow] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    if (!show) return;
    setItems(data?.extraPayments?.data || []);
  }, [data, show]);

  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });

  const ExtraPaymentTotal = _.sumBy(items, "exTraAmount");

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary  me-2 shadow-none"
        onClick={() => setShow(true)}
        style={{ position: "relative", top: "0px" }}
      >
        <BiPrinter className="me-2" />
        ພິມລາຍງານ
      </button>

      <Modal show={show} animation={false} dialogClassName="modal-width-95">
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <CiMoneyBill style={{ fontSize: "25px" }} /> ລາຍງານຂໍ້ມູນເງິນເພີ່ມ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 flex-control-group">
            <button onClick={handlePrint} type="button" className="btn btn-lg btn-dark ml-3 me-2">
              <BiPrinter /> ພິມລາຍງານ
            </button>
            <DownloadTableExcel filename="ຂໍ້ມູນລາຍງານເງິນເພີ່ມ" sheet="ລາຍງານເງິນເພີ່ມ" currentTableRef={elmRef.current}>
              <button className="btn btn-lg btn-success">
                <BsFileEarmarkExcel /> Export excel{" "}
              </button>
            </DownloadTableExcel>
          </div>
          &nbsp;&nbsp;
          <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
            <div className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
              ຂໍ້ມູນລາຍງານເງິນເພີ່ມ
            </div>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div className="justify-content-between">
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະຫວ່າງ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            &nbsp;&nbsp;
            <div className="table">
              <table className="table" border={1}>
                <thead>
                  <tr>
                    <th className="text-nowrap">ລຳດັບ</th>
                    <th className="text-nowrap">ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th className="text-nowrap">ຈຳນວນເງິນ</th>
                    <th className="text-nowrap">ວັນທີ</th>
                    <th className="text-nowrap">ສະຖານະການຊຳລະ</th>
                    <th className="text-nowrap">ເລກທີສະຫຼຸບ</th>
                    <th className="text-nowrap">ໝາຍເຫດ</th>
                    <th className="text-nowrap">ສ້າງຂຶ້ນວັນທີ</th>
                    <th className="text-nowrap">ສ້າງໂດຍ</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {items?.map((value: any, index: number) => (
                      <tr className="tbody" key={index}>
                        <td className="no">{index + 1}</td>
                        <td >
                          {value?.extraDriverID?.first_name || "---"} {value?.extraDriverID?.last_name || "----"}
                        </td>
                        <td className="text-nowrap">{currency(value?.exTraAmount || 0)} ກີບ</td>
                        <td className="text-nowrap">{value?.exTraDate ? formatDate(value?.exTraDate) : "-----"}</td>
                        <td className={value?.exTraStatus === "PAID" ? "text-success text-center" : "text-danger text-center"}>
                          {value?.exTraStatus === "PAID" ? "ຊຳລະແລ້ວ" : "ຍັງບໍ່ທັນຊຳລະ"}
                        </td>
                        <td >{value?.exTraCheck || 0}</td>
                        <td>{value?.exTraNote || "---"}</td>
                        <td className="text-nowrap ">{value?.exTraCreatedAt ? formatDateTime(value?.exTraCreatedAt) : "-----"}</td>
                        <td className="text-center">
                          {value?.exTraCreatedBy?.first_name || "----"} {value?.exTraCreatedBy?.last_name || "----"}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2} className="text-center">
                        ຍອດລວມເງິນທັງໝົດ:
                      </td>
                      <td className="text-nowrap">
                        {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(ExtraPaymentTotal)} ກີບ
                      </td>
                      <td colSpan={6}></td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
