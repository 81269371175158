import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "./Schema";

type SelectRouteLocationProps = {
  containerStyle?: React.CSSProperties;
  onSearchTexts?: (val?: string) => void;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  onChange?: (e: { value: number; label: string }) => void;
  styleConfig?: StylesConfig;
};

type Options = {
  value: number;
  label: string;
};

export default function SelectNumberCar({ disabled, value, onChange, styleConfig}: SelectRouteLocationProps) {
  const [selectedOption, setSelectedOption] = useState<Options | null>(null);
  const [items, setItems] = useState<any>([0]);
  const [fetchData, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLES, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (disabled) return;
    fetchData({
      variables: {
        where: {
          isPublic: 1,
        },
        limit: 500,
      },
    });
  }, [fetchData, disabled]);

  useEffect(() => {
    if (!data?.vehicles?.data?.length) {
      setItems([]);
      return;
    }
    const newData = data?.vehicles?.data?.map((item: any) => {
      return { ...item, value: item?._id, label: item?.vehicleName };
    });
    setItems(newData);
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item: any) => item?._id === value);
      setSelectedOption(result[0] || null);
    } else {
      setSelectedOption({ value: 0, label: "ທັງໝົດ" });
    }
  }, [items, value]);
  
  const handleChange = (option: Options | null) => {
    setSelectedOption(option);
    if (option) {
      onChange?.(option as { value: number; label: string });
    } else {
      onChange?.({ value: 0, label: "ທັງໝົດ" });
    }
  };
  

  return (
    <div>
      <Select
        isDisabled={disabled}
        value={selectedOption}
        placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກໝາຍເລກລົດ"}
        isLoading={loading}
        onChange={(option) => handleChange(option as Options)}
        options={[{ value: 0, label: "ທັງໝົດ" }, ...items]}
        styles={styleConfig}
      />
    </div>
  );
}
