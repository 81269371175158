import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Gender, RootVariable, checkUserRole } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import TableData from "../../../utils/components/TableData";
import { ParentPath } from "../../../routes/Path";
import SearchTypeVehicle from "../../../utils/components/SelectTypeVehicle";
import { AddDriverPropsContainDriver, DeleteContainDriver } from "./component";

export default function ContainDriver() {
  const [searchParams , setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [reloading, setReloading] = useState(false);
  const [getType, setType] = useState<any>();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_CONTAIN_VEHICLE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    const TypeID = getType === 0 ? undefined : Number(getType); 
    FetchData({
      variables: {
        where: {
          isDeleted: 0,
          VehicleType: TypeID ? TypeID : undefined,
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue, getType]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _typeVehicle = searchParams.get("TypeVehicle");
    const decodeTypeVehicle =  _typeVehicle ? atob(_typeVehicle) : ""
    setType(decodeTypeVehicle ? decodeTypeVehicle : "")
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ບັນຈຸໂຊເຟີລົດ</li>
        </ol>
      </div>
      <div className="main-container">
        <div className="card">
          <div className="card-header border-bottom">
            <h3>ບັນຈຸໂຊເຟີ້ລົດ</h3>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="col-md-3">
                  <span>ຄົ້ນຫາຕາມປະເພດລົດ</span>
                  {" "}
                  <SearchTypeVehicle 
                  onChange={(option: any) => {
                    searchParams.set("TypeVehicle", btoa(option?.value));
                    setSearchParams(searchParams);
                  }}
                   />
                </div>
                <br />
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th>ລຳດັບ</th>
                      <th className="text-nowrap">ປະເພດລົດ</th>
                      <th className="text-nowrap">ເບີລົດ</th>
                      <th className="text-nowrap">ທະບຽນລົດ</th>
                      <th className="text-nowrap">ໂຊເຟີ້ລົດ(1)</th>
                      {checkUserRole(["SUPER_ADMIN"]) ? <th className="text-nowrap">ຈັດການ</th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">{value?.VehicleType?.name || "----"}</td>
                          <td className="text-center text-nowrap d-flex justify-content-between">
                            {value?.vehicleName || "----"}
                            <button
                              type="button"
                              className="btn btn-sm btn-light float-right"
                              onClick={() => navigate(`${ParentPath.DetailDriver}?Driver=${value?._id}`)}
                            >
                              <i className="fas fa-list"></i>
                            </button>
                          </td>
                          <td className="text-nowrap">{value?.sign || "----"}</td>
                          <td className="text-nowrap">
                            {value?.driver?.first_name ? Gender(value?.driver?.gender) : ""}&nbsp;
                            {value?.driver?.last_name || "----"}
                            <div style={{ float: "right" }}>
                              <button
                                type="button"
                                className="btn btn-sm btn-light float-right"
                                onClick={() => navigate(`${ParentPath.DetailUser}?User=${value?.driver?._id}`)}
                              >
                                <i className="fas fa-list"></i>
                              </button>
                              {checkUserRole(["SUPER_ADMIN"]) ? (
                                <DeleteContainDriver _id={value?._id} onSuccess={() => setReloading(!reloading)} type="driver" />
                              ) : null}
                            </div>
                          </td>
                          {checkUserRole(["SUPER_ADMIN"]) ? (
                            <>
                              <td className="text-center text-nowrap">
                                <AddDriverPropsContainDriver
                                  _id={value?._id}
                                  onSuccess={() => setReloading(!reloading)}
                                  disabled={value?.driver?._id > 0}
                                  items={value} 
                                />
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
