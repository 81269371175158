import react, { useRef, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import ReactSignatureCanvas from "react-signature-canvas";
import compressImage, { LocalStorageName, notifyError, notifySuccess, notifyWarning } from "../../../utils";
import axios from "axios";
import { url_upload_Profile, url_upload_signature } from "../../../configs/Environment";
import Schema from "../Schema";
import { useMutation } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

export default function SummarySignatureTotal({ getGenerate, bilNoToday, startDate, endDate, vehicle }: any) {
  const [show, setShow] = useState(false);
  const sigCanvas = useRef<any>();
  const [signature, setSignature] = useState<any>();
  const [createSignature] = useMutation(Schema.CREATE_SIGNATURE);
  const [searchParams] = useSearchParams();
  const checkDate = searchParams.get("checkDate");


  const title = "ລາຍງານຂໍ້ມູນຖ້ຽວລົດທີ່ສະຫຼຸບແລ້ວ";
  const Summary = "ຜູ້ສະຫຼຸບ";
  const BillNo = "ANS-L" + `${bilNoToday}` + `${getGenerate}`;

  const clear = () => {
    sigCanvas.current.clear();
  };

  const Save = async () => {
    try {
      if (checkDate === "1") return notifyWarning("ກະລຸນາເລືອກງວດວັນທີສະຫລຸບ ແລະ ເບີລົດກ່ອນ!");

      const signatureData = sigCanvas.current.toDataURL("image/png");

      const actualBase64String = signatureData.split(",")[1];

      // Decode base64 string to binary
      const binaryString = atob(actualBase64String);

      // Convert binary string to ArrayBuffer
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }

      // Create Blob from ArrayBuffer
      const blob = new Blob([uint8Array], { type: "image/png" });

      // Create File from Blob
      const file = new File([blob], "image.png", { type: "image/png" });

      // Now 'file' can be used or uploaded as needed

      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setSignature(e.target?.value);
        const formData = new FormData();
        formData.append("signatureTwo", compressedFile);
        const res = await axios.post(url_upload_signature, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename && startDate && endDate) {
          setSignature(filename);
          // insert Data
          await createSignature({
            variables: {
              data: {
                signatureTwo: String(filename),
                title: String(title),
                dePartment: String(Summary),
                driverStatus: "SUMMARIES",
                noID: String(BillNo),
                routeStartDate: String(startDate),
                routeEndDate: String(endDate),
                vehicleID: Number(vehicle),
              },
            },
          });

          notifySuccess("ເຊັນສຳເລັດແລ້ວ");
          setShow(false);
          clear();
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSignature("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ເຊັນບໍ່ສຳເລັດ");
    }
  };
  return (
    <react.Fragment>
      {signature ? (
        <>
          <div>
            <Image src={url_upload_Profile + signature} width={220} height={180} />
          </div>
        </>
      ) : (
        <div onClick={() => setShow(true)} style={{ cursor: "pointer", color: "#A09D9D" }}>
          ກົດເພື່ອເຊັນ
        </div>
      )}
      <Modal show={show} size="lg">
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-pencil"></i>ຜູ້ສະຫຼຸບ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactSignatureCanvas
            penColor="black"
            canvasProps={{ width: 700, height: 300, style: { marginTop: 70 }, className: "signatureCanvas" }}
            ref={sigCanvas}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={Save}>
            <i className="fas fa-save"></i>ບັນທຶກ
          </button>
          <button type="button" className="btn btn-danger" onClick={clear}>
            <i className="fas fa-trash"></i>ລົບ
          </button>
        </Modal.Footer>
      </Modal>
    </react.Fragment>
  );
}
