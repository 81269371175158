import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { EndOfDayTwo, Gender, RootVariable, StartOfDay, checkUserRole, currency, formatDateTime } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import TableData from "../../../utils/components/TableData";
import AddShippingRoute from "./addShippingRouteTwo";
import UpdateShippingRoute from "./updateShippingRouteTwo";
import { TbArrowAutofitWidth } from "react-icons/tb";
import { GiWeight } from "react-icons/gi";
import { Cancel, UpdateHistorySup, UpdateRoute, Verified } from "./componentTwo";
import { IoMdCloseCircle } from "react-icons/io";
import { FaList } from "react-icons/fa";

import "../../../App.scss";

export default function ShippingRouteTwo() {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [showItems, setShowItems] = useState<Record<string, any[]>>({});
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [reloadhistory, setReloadhistory] = useState(false);
  const [newWidth, setNewWidth] = useState();
  const [newWeight, seNewtWeight] = useState();
  const [newStartDate, setNewStartDate] = useState("");
  const [NewEndDate, setNewEndDate] = useState("");
  const [DataRows, setDataRows] = useState<boolean[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [QuerySizeData, { data: SizeData }] = useLazyQuery(Schema.QUERY_SIZE, { fetchPolicy: "cache-and-network" });
  const [FetchData, { data: result }] = useLazyQuery(Schema.QUERY_HISTORY_SHIPPING, { fetchPolicy: "cache-and-network" });
  const [fetchData, { data, loading }] = useLazyQuery(Schema.QUERY_SHIPPING_ROUTE, { fetchPolicy: "cache-and-network" });

  const toggleRow = (index: number, sp_id: string) => {
    setDataRows((prev) => {
      const newDataRows = [...prev];
      newDataRows[index] = !newDataRows[index];
      return newDataRows;
    });
    setSelectedId(sp_id);
  };

  useEffect(() => {
    if (selectedId) {
      FetchData({
        variables: {
          where: {
            sping_route: Number(selectedId),
            service: "OPEN",
          },
        },
      });
    }
  }, [FetchData, selectedId, reloadhistory]);

  useEffect(() => {
    if (selectedId && result?.HistoryShippingRoutes?.data) {
      setShowItems((prevState) => ({
        ...prevState,
        [selectedId]: result?.HistoryShippingRoutes?.data || [],
      }));
    }
  }, [result, selectedId]);

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          newLocation: "NO",
          isDif: "2",
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
    let shippingRoutesData = data?.shippingRoutes?.data;
    if (shippingRoutesData && shippingRoutesData.length > 0) {
      let vehicleIDs = shippingRoutesData.map((route: any) => route?.vehicleID?._id);
      QuerySizeData({
        variables: {
          where: {
            vehicleId: vehicleIDs,
            startDate: newStartDate,
            endDate: NewEndDate,
          },
          limit: 100,
        },
      });
    }
  }, [QuerySizeData, fetchData, data, pageNumber, numberRow, reloading, searchValue, newStartDate, NewEndDate]);

  useEffect(() => {
    setItems(data?.shippingRoutes?.data || []);
    setTotal(data?.shippingRoutes?.total || 0);
  }, [data]);

  useEffect(() => {
    if (SizeData?.ReportItemFinalSummary) {
      setNewWidth(SizeData.ReportItemFinalSummary.map((item: any) => item.FinalSumWidth));
      seNewtWeight(SizeData.ReportItemFinalSummary.map((item: any) => item.FinalWeight));
    }
  }, [SizeData]);

  useEffect(() => {
    const newStartDate = StartOfDay();
    const newEndDate = EndOfDayTwo();
    setNewStartDate(newStartDate);
    setNewEndDate(newEndDate);
  }, []);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams, setPageNumber, setNumberRow]);

  const handleSuccess = () => {
    setReloadhistory(!reloadhistory);
  };

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ຈັດການຖ້ຽວລົດເສີມພິເສດ</li>
        </ol>
      </div>
      &nbsp;&nbsp;
      <div className="card">
        <div className="card-header border-bottom">
          <div className="row">
            <div className="col-sm-8">
              <h4>
                ລາຍງານການຈັດຖ້ຽວລົດ<span style={{ color: "red" }}>(ເສີມພິເສດ)</span>
              </h4>
            </div>
            <div className="col-sm-4">
              <div style={{ float: "right" }}>
                {checkUserRole(["SUPER_ADMIN", "ADMIN"]) ? <AddShippingRoute onSuccess={() => setReloading(!reloading)} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <TableData
            total={total}
            loading={loading}
            pageNumber={pageNumber}
            numberRow={numberRow}
            onSearchText={(val) => {
              setSearchValue(val || "");
            }}
            header={
              <tr>
                <th className="text-nowrap text-center">ລຳດັບ</th>
                <th className="text-nowrap text-center">ໂຊເຟີ້(1)</th>
                <th className="text-nowrap text-center">ໂຊເຟີ້(2)</th>
                <th className="text-nowrap text-center">ເບີລົດ</th>
                <th className="text-nowrap text-center">ຂະໜາດແລະນ້ຳໜັກ/ມື້</th>
                <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ສາຍທີ່ແລ່ນ</th>
                <th className="text-nowrap text-center">ເລກທີໂກຕ່າ</th>
                <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                <th className="text-nowrap text-center">ກະອອກລົດ</th>
                <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                <th className="text-nowrap text-center">ຢືນຢັນອອກລົດ</th>
                <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                <th className="text-nowrap text-center">ໝາຍເຫດ</th>
                <th className="text-nowrap text-center">ຢືນຢັນອອກໂດຍ</th>
                <th className="text-nowrap text-center">ຢືນຢັນຮອດໂດຍ</th>
                <th className="text-nowrap text-center">ສູນປາຍທາງ</th>
                {checkUserRole(["SUPER_ADMIN", "ADMIN", "AUDITOR"]) ? <th className="text-nowrap text-center">ຈັດການ</th> : null}
              </tr>
            }
            body={
              <>
                {items?.map((value, index) => (
                  <tr key={index} className="table-danger">
                    <td className="text-center">{index + 1}</td>
                    <td className="text-nowrap">
                      {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""}&nbsp;
                      {value?.driverID?.first_name || "----"}&nbsp;{value?.driverID?.last_name || "----"}
                    </td>
                    <td className="text-center text-nowrap">
                      {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp;
                      {value?.driverTwo?.first_name || "----"} {value?.driverTwo?.last_name || "----"}
                    </td>
                    <td className="text-nowrap">{value?.vehicleID?.vehicleName || "----"}</td>
                    <td className="text-nowrap">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <span style={{ color: "green" }}>
                          <TbArrowAutofitWidth />:{" "}
                          {newWidth && newWidth[index] ? (
                            `${currency(newWidth[index])} cm`
                          ) : (
                            <>
                              <Spinner animation="border" variant="danger" size="sm" /> cm
                            </>
                          )}
                        </span>
                        <span style={{ color: "red" }}>
                          <GiWeight />:{" "}
                          {newWeight && newWeight[index] ? (
                            `${currency(newWeight[index])} kg`
                          ) : (
                            <>
                              <Spinner animation="border" variant="danger" size="sm" /> kg
                            </>
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="text-nowrap">
                      {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""} {value?.createdBy?.first_name || "----"}{" "}
                      {value?.createdBy?.last_name || "----"}
                    </td>
                    <td className="text-nowrap ">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          onClick={() => toggleRow(index, value?.sp_id || "")}
                          style={{ border: "none", background: "transparent", cursor: "pointer" }}
                        >
                          {DataRows[index] ? <IoMdCloseCircle size={20} /> : <FaList size={20} />}
                        </button>
                        {value?.routeLocationID?.title || "----"}
                      </div>
                      {DataRows[index] && (
                        <div className="location-info">
                          {showItems[value?.sp_id]?.length > 0 ? (
                            showItems[value?.sp_id].map((item, itemIndex) => (
                              <p key={itemIndex}>
                                <UpdateHistorySup item={item} className="delete-button" onSuccess={handleSuccess} /> {itemIndex + 1}: ສາຂາ{" "}
                                {item?.branch_id?.branch_name || "---"}
                              </p>
                            ))
                          ) : (
                            <p>--- ບໍ່ມີຂໍ້ມູນສາຂາ ---</p>
                          )}
                        </div>
                      )}
                    </td>
                    <td className="text-center">{value?.billNoRout || "----"}</td>
                    <td className="text-nowrap text-center">
                      {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}
                    </td>
                    <td className="text-center text-nowrap">{value?.isDay === "1" ? "ພາກແລງ" : "ພາກເຊົ້າ"}</td>
                    <td className={value?.isDif === "2" ? "text-danger text-nowrap" : "text-center text-nowrap"}>
                      {value?.isDif === "2" ? "ຖ້ຽວເສີມພິເສດ" : ""}
                    </td>
                    <td className="text-nowrap">{currency(value?.amountValue || 0)} ກີບ</td>
                    <td className="text-center text-nowrap">{value?.startTime ? formatDateTime(value?.startTime) : "----"}</td>
                    <td className="text-center text-nowrap">
                      {value?.acceptStatus === "UNACCEPTED" ? (
                        <>
                          <h5>
                            <Button variant="danger" className="disable-cursor" size="sm">
                              <Spinner animation="border" size="sm" variant="warning" /> ລໍຖ້າຢືນຢັນອອກລົດ...
                            </Button>
                          </h5>
                        </>
                      ) : (
                        <>
                          <h5>
                            <Button variant="success" className="disable-cursor" size="sm">
                              <i className="fas fa-check-circle"></i> ຢືນຢັນອອກລົດແລ້ວ
                            </Button>
                          </h5>
                        </>
                      )}
                    </td>
                    <td className="text-center text-nowrap">{formatDateTime(value?.createdAt)}</td>
                    <td className="text-nowrap">{value?.difNote || "----"}</td>
                    <td className="text-nowrap">
                      {value?.acceptBy?.gender ? Gender(value?.acceptBy?.gender) : ""} {value?.acceptBy?.first_name || "----"}{" "}
                      {value?.acceptBy?.last_name || "----"}
                    </td>
                    <td className="text-nowrap">
                      {value?.acceptArrivedBy?.gender ? Gender(value?.acceptArrivedBy?.gender) : ""} {value?.acceptArrivedBy?.first_name || "---"}{" "}
                      {value?.acceptArrivedBy?.last_name || "---"}
                    </td>
                    <td className="text-nowrap">{value?.centerRoute?.centerName || "----"}</td>
                    <td className=" text-nowrap">
                      <div className="btn-group">
                        {checkUserRole(["SUPER_ADMIN"]) ? (
                          <>
                            <UpdateShippingRoute Items={value} onSuccess={() => setReloading(!reloading)} />
                            <UpdateRoute item={value} onSuccess={() => setReloading(!reloading)} />
                          </>
                        ) : null}

                        {value?.acceptStatus === "UNACCEPTED" ? (
                          <>
                            {checkUserRole(["ADMIN", "SUPER_ADMIN", "AUDITOR"]) ? (
                              <>
                                <Verified items={value} onSuccess={() => setReloading(!reloading)} />
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>{checkUserRole(["SUPER_ADMIN"]) ? <Cancel items1={value} onSuccess={() => setReloading(!reloading)} /> : null}</>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
