import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_SHIPPING_ROUTES = gql`
query Query($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
  shippingRoutes(where: $where, limit: $limit, skip: $skip) {
  total
    data {
      sp_id
      routeLocationID {
        _id
        title
        isPublic
      }
      vehicleID {
        _id
        vehicleName
        isPublic
      }
      status
      startTime
      endTime
      amountValue
      paidStatus
      paidDate
      acceptStatus
      acceptBy {
        _id
        first_name
        last_name
        gender
      }
      newLocation
      driverID {
        _id
        first_name
        last_name
        gender
      }
      driverTwo{
        first_name
        last_name
        gender
      }
      createdAt
      createdBy {
        _id
        first_name
        last_name
        gender
      }
      isDay
      checkRoute
      checkScan
      dateScan_out
      checkBy {
        _id
        first_name
        last_name
        gender
      }
      checkDate
      consolidate_ID {
        consolidateID
      }
      isConsolidated
      consolidatePaymentID
      isDif
      difNote
      petroleum
      pay
      noID
      noRID
      totalItem
      billNoRout
      acceptArrivedBy {
        _id
        first_name
        gender
        last_name
      }
      centerRoute {
        st_id
        centerName
      }
    }
  }
}`;


  static QUERY_ROUTES_LOCATION = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          locationCode
          isPublic
          note
          createdAt
          priceDay
          priceNight
          runOn
          lg_isDay
          checkRoute
          km
          isAuction
          petroleum
          code
          priceNew
        }
      }
    }
  `;

  static UPDATE_ALL = gql`
mutation UpdateShippingRoutePrice($where: wherePrice!, $data: ShippingRouteWhereInput) {
  updateShippingRoutePrice(where: $where, data: $data) {
    data
    status
  }
}
`;

  static UPDATE_ALL_PRICE_SHIPPING_ROUTE = gql`
mutation UpdateShippingRoutePriceAll($where: WhereByMultipleId!, $data: ShippingRouteWhereInput) {
  updateShippingRoutePriceAll(where: $where, data: $data) {
    status
    data
  }
}
`;

  static QUERY_ITEM_MODIFY_PRICE = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          title
          locationCode
          isPublic
          note
          createdAt
          priceDay
          priceNight
          runOn
          lg_isDay
          checkRoute
          km
          isAuction
          petroleum
          code
          priceNew
        }
      }
    }
  `;

  static DELATE_MODIFY = gql`
mutation Mutation($where: whereId!) {
  deleteShippingRoute(where: $where) {
    status
    data
  }
} `;

}