import React, { useEffect, useState } from "react";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../../utils";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { RiDeleteBin6Fill } from "react-icons/ri";

// cancel component
type CancelProps = {
  items1: any;
  onSuccess: () => void;
  className?: string;
};

export const Cancel = ({ items1, onSuccess, className }: CancelProps) => {
  const [updateData, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_STATUS);
  return (
    <Button
      variant="warning"
      className={className}
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.cancelDriverCar, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  sp_id: items1?.sp_id,
                },
                data: {
                  acceptStatus: "UNACCEPTED",
                },
              },
            });
            notifySuccess(MessageSuccess.cancel);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.cancel);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-close" />}
    </Button>
  );
};

// verify component
type VerifyProps = {
  items: any;
  onSuccess: () => void;
  className?: string;
};

export const Verified = ({ items, onSuccess, className }: VerifyProps) => {
  const [FetchData, { data: result }] = useLazyQuery(Schema.QUERY_LOCATION_BRANCH, { fetchPolicy: "cache-and-network" });
  const [ServiceData] = useMutation(Schema.UPDATE_LOCATION_BRANCH);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_STATUS);
  const userinfo = getStaffLogin();
  const [Item, setItem] = useState<any[]>([]);
  const RouteID = items?.routeLocationID?._id;

  useEffect(() => {
    if (!RouteID) return;
    FetchData({
      variables: {
        where: {
          locationID: Number(RouteID),
          service_type: "OPEN",
        },
      },
    });
  }, [FetchData, RouteID]);
  useEffect(() => {
    setItem(result?.locationGroupBranches?.data || []);
  }, [result]);

  const handleUpdate = async () => {
    confirmShow(MessageConfirms.confirmCheckDriver, async () => {
      try {
        await updateData({
          variables: {
            where: {
              sp_id: items?.sp_id,
            },
            data: {
              acceptStatus: "ACCEPTED",
              acceptBy: userinfo?._id,
            },
          },
        });
        if (Item.length > 0) {
          await Promise.all(
            Item.map(async (values) => {
              await ServiceData({
                variables: {
                  where: { _id: values?._id },
                  data: {
                    service_type: "CLOSE",
                  },
                },
              });
            })
          );
        }

        notifySuccess(MessageSuccess.updateCar);
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.updateCar);
      }
    });
  };
  return (
    <Button variant="success" className={className} disabled={loading} onClick={handleUpdate}>
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
    </Button>
  );
};

// delete component
type DeleteProps = {
  sp_id: number;
  onSuccess: () => void;
  className?: string;
};

export const DeleteRoute = ({ sp_id, onSuccess, className }: DeleteProps) => {
  const [DeleteData, { loading }] = useMutation(Schema.DELETE_SHIPPING_ROUTE);
  return (
    <Button
      variant="danger"
      className={className}
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await DeleteData({
              variables: {
                where: {
                  sp_id: sp_id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update shipping_route component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};

export const UpdateRoute = ({ item, className, onSuccess }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>(item?.difNote);
  const [DeleteShippingRoute, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_DELETE);
  const HandleSubmit = async () => {
    try {
      await DeleteShippingRoute({
        variables: {
          data: {
            difNote: note,
          },
          where: {
            sp_id: parseInt(item?.sp_id),
          },
        },
      });
      notifySuccess(MessageSuccess.delete);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageErrors.delete);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={() => setShow(true)}>
        <i className="fa fa-trash" />
      </button>
      <Modal show={show}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-add"></i>ເຫດຜົນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group className="mb-3">
              <Form.Label>ເຫດຜົນທີ່ຕ້ອງການລົບຖ້ຽວນີ້{InvalidText()}</Form.Label>
              <Form.Control name="difNote" as="textarea" rows={3} onChange={(e: any) => setNote(e.target.value)} value={note} />
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!note || loading} onClick={HandleSubmit}>
            <i className="fas fa-check-circle m-0" />
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface Branch {
  id_branch: number;
  branch_name: string;
}
interface Location {
  _id: number;
  title: string;
}
interface UpdateProp {
  item: {
    _id: Number;
    difNote?: string;
    is_day?: Number;
    branch_id?: Branch;
    route_id?: Location;
    sping_route: Number;
  };
  className?: string;
  onSuccess?: () => void;
}
export const UpdateHistorySup: React.FC<UpdateProp> = ({ item, className, onSuccess }) => {
  // const [show, setShow] = useState(false);
  const [note, setNote] = useState(item?.difNote || "");
  const [UpdateShippingService] = useMutation(Schema.UPDATE_HISTORY_SHIPPING);

  const HandleSubmit = async () => {
    try {
      await UpdateShippingService({
        variables: {
          data: {
            note: note,
            service: "CLOSE",
            is_day: item.is_day,
            branch_id: item?.branch_id?.id_branch,
            route_id: item?.route_id?._id,
            sping_route: item?.sping_route,
          },
          where: { _id: item?._id },
        },
      });
      notifySuccess(MessageSuccess.delete);
      onSuccess?.();
      // setShow(false);
    } catch (error) {
      console.error("Error during update:", error);
      notifyError(MessageErrors.delete);
    }
  };

  return (
    // <>
    //   <RiDeleteBin6Fill style={{ cursor: "pointer", fontSize: "20px", color: "red" }} onClick={() => setShow(true)} />
    //   <Modal show={show} onHide={() => setShow(false)}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>
    //         <i className="fas fa-add"></i> ເຫດຜົນ
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <Form.Group className="mb-3">
    //         <Form.Label>ເຫດຜົນທີ່ຕ້ອງການລົບຖ້ຽວນີ້</Form.Label>
    //         <Form.Control name="difNote" as="textarea" rows={3} onChange={(e) => setNote(e.target.value)} value={note} />
    //       </Form.Group>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <Button disabled={!note || loading} onClick={HandleSubmit}>
    //         <i className="fas fa-check-circle m-0" />
    //         <span className="ms-1">ສົ່ງຟອມ</span>
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    // </>
    <>
      <RiDeleteBin6Fill style={{ cursor: "pointer", fontSize: "20px", color: "red" }} onClick={HandleSubmit} />
    </>
  );
};
