import { gql } from "@apollo/client";
export default class Schema {
  static QUERY_IBANK_REPORT = gql`
    query ReportSumIBank($skip: Int, $limit: Int, $where: consolidateIBankWhereInput) {
      ReportSumIBank(skip: $skip, limit: $limit, where: $where) {
        total
        data {
          consolidateDriverID {
            account_bank_number
            account_bank_name
            _id
            first_name
            last_name
          }
          consolidateRouteID {
            title
          }
          paymentBalanceAmount
          paymentBalanceAmountTwo
          driverTwo {
            account_bank_number
            account_bank_name
            _id
            first_name
            last_name
          }
          consolidateDate
          isDif
          consolidatePaidStatus
        }
      }
    }
  `;
}
