import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_SHIPPING_CHECK = gql`
  query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
    shippingRoutes(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        sp_id
        routeLocationID {
          _id
          title
        }
        vehicleID {
          _id
          vehicleName
        }
        status
        startTime
        endTime
        amountValue
        paidStatus
        paidDate
        acceptStatus
        acceptBy {
          _id
          first_name
          last_name
          gender
        }
        newLocation
        driverID {
          _id
          first_name
          last_name
          gender
        }
        createdAt
        createdBy {
          _id
          first_name
          last_name
          gender
        }
        isConsolidated
        consolidatePaymentID
        isDif
        difNote
        isDay
        checkRoute
        pay
        petroleum
        checkScan
        dateScan_out
        checkBy {
          _id
          first_name
          last_name
          gender
        }
        checkDate
        noID
        noRID
        totalItem
        billNoRout
        acceptArrivedBy {
          _id
          first_name
          last_name
          gender
        }
        driverTwo {
          _id
          first_name
          last_name
          gender
        }
        centerRoute {
          st_id
          centerName
          centerCode
        }
        check_success
        vehicleID {
          _id
          vehicleName
        }
        status
        startTime
        endTime
        paidStatus
        paidDate
        acceptStatus
        checkScan
        acceptBy {
          _id
          first_name
          last_name
          gender
        }
        newLocation
        driverID {
          _id
          first_name
          last_name
          gender
        }
        createdAt
        createdBy {
          _id
          first_name
          last_name
          gender
        }
        isConsolidated
        consolidatePaymentID
        isDif
        checkRoute
        pay
        petroleum
        dateScan_out
        checkBy {
          _id
          first_name
          last_name
          gender
        }
        checkDate
        noID
        noRID
        totalItem
        consolidate_ID {
          consolidateID
        }
      }
    }
  }
  `;

  static CREATE_SHIPPING_ROUTE = gql`
    mutation CreateShippingRoute($data: ShippingRouteWhereInput!) {
      createShippingRoute(data: $data) {
        status
      }
    }
  `;
 
  static UPDATE_SHIPPING_ROUTE = gql`
    mutation UpdateShippingRoute($where: whereSID!, $data: ShippingRouteWhereInput) {
      updateShippingRoute(where: $where, data: $data) {
        data
        status
      }
    }
  `;
  static DELETE_SHIPPING_ROUTE = gql`
    mutation Mutation($where: whereSID!) {
      deleteShippingRoute(where: $where) {
        data
        status
      }
    }
  `;
  static UPDATE_SHIPPING_ROUTE_NEW_LOCATION = gql`
    mutation UpdateShippingRouteChecking($where: whereCheckID!, $data: ShippingRouteWhereInput) {
      updateShippingRouteChecking(where: $where, data: $data) {
        status
      }
    }
  `;
  static UPDATE_SHIPPING_ROUTE_DELETE = gql`
  mutation UpdateShippingRouteDelete($where: whereIDD!, $data: ShippingRouteDeleteInput) {
    updateShippingRouteDelete(where: $where, data: $data) {
      status
      data
    }
  }
`;
static QUERY_LOCATION_BRANCH = gql`
query LocationGroupBranches($where: locationGroupBranchInput, $limit: Int, $skip: Int) {
  locationGroupBranches(where: $where, limit: $limit, skip: $skip) {
    total
    data {
      _id
      branch_id {
        id_branch
        branch_name
        branch_address
      }
      service_type
    }
  }
}
`;
static UPDATE_LOCATION_BRANCH = gql`
    mutation Mutation($where: WhereById!, $data: locationGroupBranchInput) {
      updateLocationGroupBranch(where: $where, data: $data) {
        status
      }
    }
  `;
}
