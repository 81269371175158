export enum ParentPath {
  login = "/login",
  home = "/home",
  calculateFare = "/calculate_fare",
  checkingDestinationVehicle = "/checking_destination_vehicle",
  companyDriverPartner = "/company_driver_partner",
  driverPartnerSeparation = "/driver_partner_separation",
  summarizeDestinationVehicle = "/summarize_destination_vehicle",
  summarizeDestinationVehicleTwo = "/summarize_destination_vehicle_two",
  transportationVehicle = "/transportation_vehicle",
  users = "/users",
  trash = "/trash",
  location_id = "/location",
  vehicleManagement = "/vehicle_management",
  summaryTotal = "/summary_total",
  summaryTotalTwo = "/summary_totalTwo",
  addVehicle = "/add_vehicle",
  RecordBranches = "/record_branches",
  RecordVehicle = "/record_vehicle",
  DetailUser = "/detail_user",
  DetailDriver = "/detail_driver",
  InformationDriver = "/Information_driver",
  AddShippingRoute = "/add_shipping_route",
  UpdateShippingRoute = "/update_shipping_route",
  RouteDetails = "/route_details",
  ExtraPayments = "/extra_payments",
  ExtraPaymentTwo = "/extra_paymentsTwo",
  DeductionPayments = "/deduction_payments",
  DeductionPaymentTwo = "/deduction_payment_Two",
  PrintInvoice = "/print_Invoice",
  ExportTotal = "/export_total",
  listShipping = "/list_shipping",
  ListShippingsTwo = "/listShippingsTwo",
  ExportIBank = "/export_ibank_summary",
  ExportIBankTwo = "/export_ibank_summary_Two",
}
export enum ChildPath {
  deductionPayment = "deduction_payment",
  exTraPayment = "exTra_payment",
  centralSeparation = "central_separation",
  containVehicleCentral = "contain_vehicle_central",
  containDriver = "contain_driver",
  destinationVehicle = "destination_vehicle",
  modifyDestinationVehicle = "modify_destination_vehicle",
  typeVehicle = "type_vehicle",
  informationVehicle = "information_vehicle",
  modifyPriceRoute = "modify_price_route",
  shippingCheckingRoute = "shipping_checking_route",
  shippingCheckingRouteTwo = "shipping_checking_routeTwo",
  shippingRoute = "shipping_Route",
  shippingRouteTwo = "shipping_RouteTwo",
  province = "province",
  district = "district",
  village = "village",
  HistoryComponent = "history_component",
  UpdateShippingRoute = "UpdateShippingRoute",
}
