import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import Schema from "./Schema";
import { Spinner } from "react-bootstrap";
export default function VehicleDetailComponent({ value, name }: any) {
  const [totalVehicle, setTotalVehicle] = useState(0);
  const [totalVehicle1, setTotalVehicle1] = useState(0);
  const [FetchData1, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLE, { fetchPolicy: "cache-and-network" });
  const [FetchData2, { data: result, loading: newLoading }] = useLazyQuery(Schema.QUERY_VEHICLE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData1({
      variables: {
        where: {
          isRent: 1,
          isPublic: 1,
          VehicleType: value,
        },
      },
    });
  }, [FetchData1]);

  useEffect(() => {
    FetchData2({
      variables: {
        where: {
          isRent: 0,
          isPublic: 1,
          VehicleType: value,
        },
      },
    });
  }, [FetchData2]);

  useEffect(() => {
    if (loading) {
      setTotalVehicle(0);
      setTotalVehicle1(0);
      return;
    }
    setTotalVehicle(data?.vehicles?.total || 0);
    setTotalVehicle1(result?.vehicles?.total || 0);
  }, [data, loading, result, newLoading]);

  return (
    <React.Fragment>
      <div className="card-body" style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)", borderRadius: 10 }}>
        <div className="card-title text-center">{name}</div>
        <hr />
        <div className="text-center text-nowrap">
          ລົດບໍລິສັດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : totalVehicle} ຄັນ | ລົດຮ່ວມ:{" "}
          {loading ? <Spinner animation="border" variant="danger" size="sm" /> : totalVehicle1} ຄັນ
        </div>
      </div>
    </React.Fragment>
  );
}
