import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, notifyError, notifySuccess } from "../../../utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import Schema from "./Schema";

type centerValue = {
  centerName: string;
  centerCode: string;
  isMain: number;
  position: string;
  note: string;
  isPublic: number;
};

type centerError = {
  centerName?: string;
  centerCode?: string;
  position?: string;
};

type FormLayoutProp = {
  values: centerValue;
  errors: centerError;
  handleChange: any;
  setFieldValue: any;
};

const FormLayout = ({ values, errors, handleChange, setFieldValue }: FormLayoutProp) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>ຊື່ສູນ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          placeholder="ຊື່ສູນ"
          name="centerName"
          onChange={handleChange}
          value={values?.centerName}
          isInvalid={errors?.centerName ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ອັກສອນຫຍໍ້ {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          placeholder="ອັກສອນຫຍໍ້"
          name="centerCode"
          onChange={handleChange}
          value={values?.centerCode}
          isInvalid={errors?.centerCode ? true : false}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ເລືອກພາກ {InvalidText()}</Form.Label>
        <Form.Select name="position" onChange={handleChange} value={values?.position} isInvalid={errors?.position ? true : false}>
          <option value="">ເລືອກພາກ</option>
          <option value="NORTHERN">ພາກເໜືອ</option>
          <option value="CENTRAL">ພາກກາງ</option>
          <option value="SOUTHERN">ພາກໄຕ້</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ລາຍລະອຽດ</Form.Label>
        <Form.Control type="text" placeholder="ລາຍລະອຽດ" name="note" as="textarea" rows={3} onChange={handleChange} value={values?.note} />
      </Form.Group>
      <Form.Group className="mb-3">
        {values?.isMain === 1 ? (
          <div className="input-group">
            <Form.Label style={{ display: "flex" }}>ສູນໃຫຍ່: </Form.Label>
            &nbsp; &nbsp;ແມ່ນ:&nbsp;&nbsp; <input type="radio" name="isMain" onChange={handleChange} value="1" checked className="me-2" />
            ບໍ່ແມ່ນ:&nbsp;&nbsp; <input type="radio" name="isMain" onChange={handleChange} value="0" />
          </div>
        ) : (
          <div className="input-group">
            <Form.Label style={{ display: "flex" }}>ສູນໃຫຍ່: </Form.Label>
            &nbsp; &nbsp;ແມ່ນ:&nbsp;&nbsp; <input type="radio" name="isMain" onChange={handleChange} value="1" className="me-2" />
            ບໍ່ແມ່ນ:&nbsp;&nbsp; <input type="radio" name="isMain" onChange={handleChange} value="0" />
          </div>
        )}
      </Form.Group>
    </>
  );
};

const initialValues: centerValue = {
  centerName: "",
  centerCode: "",
  isMain: 0,
  note: "",
  position: "",
  isPublic: 1,
};
const validate = (values: centerValue) => {
  const errors: centerError = {};
  if (!values?.centerName) errors.centerName = "ກະລຸນາປ້ອນຊື່ສູນ";
  if (!values?.centerCode) errors.centerCode = "ກະລຸນາປ້ອນລະຫັດຫຍໍ້";
  if (!values?.position) errors.position = "ກະລຸນາປ້ອນພາກ";
  return errors;
};

// create component
export const Create = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_CENTER);

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<centerValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await createData({
          variables: {
            data: {
              centerName: values.centerName,
              centerCode: values.centerCode,
              isMain: Number(values?.isMain),
              note: values.note,
              position: values.position,
              isPublic: Number(values?.isPublic),
            },
          },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add" />
        ເພີ່ມຂໍ້ມູນສູນ
      </Button>

      <Modal show={show}>
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ເພີ່ມຂໍ້ມູນສູນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  st_id: number;
  onSuccess: () => void;
  className?: string;
};

export const Delete = ({ st_id, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_CENTER);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  st_id: st_id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};
export const Update = ({ item, onSuccess, className }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_CENTER);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<centerValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: {
            data: {
              ...values,
              isMain: Number(values?.isMain),
            },
            where: {
              st_id: item?.st_id,
            },
          },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      centerName: item?.centerName || "",
      centerCode: item?.centerCode || "",
      position: item?.position || "",
      isMain: item?.isMain || 0,
      note: item?.note || "",
      isPublic: item?.isPublic || 0,
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show}>
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ຟອມແກ້ໄຂຂໍ້ມູນສູນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// close Center

type UpdateCenter = {
  ID: number;
  onSuccess: () => void;
  className?: string;
};

export const Updated = ({ ID, onSuccess, className }: UpdateCenter) => {
  const [update, { loading }] = useMutation(Schema.UPDATE_CLOSED);
  return (
    <Button
      variant="success"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.updated, async () => {
          try {
            await update({
              variables: {
                data: {
                  isPublic: 0,
                },
                where: {
                  st_id: ID,
                },
              },
            });
            notifySuccess(MessageSuccess.IsClosed);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.NotClosed);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : ""}ເປີດ
    </Button>
  );
};

// list vehicle

type itemVehicles = {
  vehicle: any;
};

export const ListVehicle = ({ vehicle }: itemVehicles) => {
  const [items, setItems] = useState<any>([]);
  const [FetchVehicle, { data, loading }] = useLazyQuery(Schema.QUERY_LIST_VEHICLE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchVehicle({
      variables: {
        where: {
          center: vehicle,
          isPublic: 1,
        },
      },
    });
  }, [FetchVehicle]);
  useEffect(() => {
    if (loading) {
      setItems([]);
      return;
    }
    setItems(data?.vehicles?.data || []);
  }, [data, loading]);

  return (
    <React.Fragment>
      {items?.map((value: any, index: number) => (
        <div key={index}>
          <p className="text-center">{value?.vehicleName}</p>
        </div>
      ))}
    </React.Fragment>
  );
};


