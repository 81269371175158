import { useMutation } from "@apollo/client";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../../utils";
import Schema from "./Schema";

type UpdateCenter = {
  ID: any;
  onSuccess: () => void;
  className?: string;
  _id: number;
  disabled: any;
};

// contain vehicle to center
export const Updated = ({ ID, _id, onSuccess, className, disabled }: UpdateCenter) => {
  const [update, { loading }] = useMutation(Schema.QUERY_VEHICLE_UPDATE);
  return (
    <Button
      variant="success"
      className={className}
      disabled={disabled}
      onClick={() => {
        confirmShow(MessageConfirms.addVehicle, async () => {
          try {
            await update({
              variables: {
                data: {
                  center: Number(ID),
                },
                where: {
                  _id: _id,
                },
              },
            });
            notifySuccess(MessageSuccess.addVehicle);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.addVehicle);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : ""}
      <i className="fas fa-add m-0"></i> ເພີ່ມ
    </Button>
  );
};

type UpdateVehicle = {
  vehicleID: any;
  onSuccess: () => void;
  className?: string;
};
// cancel contain vehicle to center

export const Updating = ({ vehicleID, onSuccess, className }: UpdateVehicle) => {
  const [update, { loading }] = useMutation(Schema.QUERY_VEHICLE_UPDATE);
  return (
    <Button
      variant="light"
      className={className}
      disabled={loading || getStaffLogin()?.role !== "SUPER_ADMIN"}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await update({
              variables: {
                data: {
                  center: 0,
                },
                where: {
                  _id: vehicleID,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : ""}
      <i className="fas fa-close m-0"></i>
    </Button>
  );
};

