import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Schema from "./Schema";
import { Updating } from "./component";

export default function ListVehicle({ whereID }: any) {
  const [reloading, setReloading] = useState(false);
  const [total, setTotal] = useState(0);
  const [listVehicle, setVehicle] = useState<any>([]);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLE_ITEMS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          center: whereID,
          isPublic: 1,
        },
      },
    });
  }, [FetchData, reloading]);
  useEffect(() => {
    if (loading) {
      setTotal(0);
      setVehicle([]);
      return;
    }
    setVehicle(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  return (
    <React.Fragment>
      {listVehicle?.map((value: any, index: number) => (
        <>
          <div className="btn-group mb-1" key={index}>
            <button type="button" className="btn btn-light btn-block">
              {index + 1}
            </button>
            <button className="input-group-text btn">{value?.vehicleName}</button>
            <Updating vehicleID={value?._id} onSuccess={() => setReloading(!reloading)} />
          </div>
          <br />
        </>
      ))}
      <hr />
      <p>ທັງໝົດ {total} ຄັນ</p>
    </React.Fragment>
  );
}
