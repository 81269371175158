import { gql } from "@apollo/client";

export default class Schema {
  static CREATE_USERS = gql`
    mutation CreateUser($data: UserInput!) {
      createUser(data: $data) {
        status
      }
    }
  `;

  static QUERY_USERS = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          first_name
          last_name
          gender
          age
          phoneNumber
          passID
          cardID
          address
          password
          role
          status
          startDate
          endDate
          image
          createdAt
          createdBy {
            _id
            first_name
            last_name
            gender
          }
          cardImage
          passImage
          docFile
          account_bank_name
          account_bank_number
          basicSalary
          dateConsolidate
          center {
            st_id
            centerName
          }
          stStatus{
            cp_id
            cp_name
          }
        }
      }
    }
  `;

  static UPDATE_USERS = gql`
    mutation UpdateUser($data: UserInput!, $where: WhereById!) {
      updateUser(data: $data, where: $where) {
        status
        data
      }
    }
  `;

  static DELETE_USERS = gql`
    mutation DeleteUser($where: WhereById!) {
      deleteUser(where: $where) {
        status
      }
    }
  `;
}
