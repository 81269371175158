import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Schema from "./schema";
import { RootVariable, checkUserRole, formatDate } from "../../utils";
import { useSearchParams } from "react-router-dom";
import TableData from "../../utils/components/TableData";
import { Create, Delete, Update } from "./component";


export default function CompanyDriverPartner() {
  const [items, setItems] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_DRIVER_COMPANY, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (loading) return;
    FetchData({
      variables: {
        where: {},
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, reloading, pageNumber, numberRow, searchValue]);

  useEffect(() => {
    if (data) {
      setItems(data?.driverPartners?.data || []);
      setTotal(data?.driverPartners?.total || 0);
    }
  }, [data]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item active">ເພີ່ມຂໍ້ມູນບໍລິສັດ</li>
        </ol>
      </div>

      <div className="col-md-12 mt-4">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-8">
                <h4>ລາຍງານຂໍ້ມູນບໍລິສັດ</h4>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN", "AUDITOR"]) ? (
                    <>
                      <Create onSuccess={() => setReloading(!reloading)} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog gutters">
              <div className="col-md-12">
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ຊື່ບໍລິສັດ</th>
                      <th className="text-nowrap">ອັກສອນຫຍໍ້</th>
                      <th className="text-nowrap">ເບີໂທ</th>
                      <th className="text-nowrap">ທີ່ຢູ່</th>
                      <th className="text-nowrap">ໝາຍເຫດ</th>
                      <th className="text-nowrap">ສ້າງຂຶ້ນວັນທີ່</th>
                      <th className="text-nowrap">ຄ່າບໍລິຫານ</th>
                      <th className="text-nowrap">ສ້າງໂດຍ</th>
                      {checkUserRole(["SUPER_ADMIN"]) ? <th className="text-nowrap text-center">ຈັດການ</th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((item, index) => (
                        <tr>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">{item?.cp_name || "---"}</td>
                          <td className="text-nowrap">{item?.cp_code || "---"}</td>
                          <td className="text-nowrap">{item?.cp_phoneNumber || 0}</td>
                          <td className="text-nowrap">{item?.cp_address || "---"}</td>
                          <td className="text-nowrap">{item?.cp_remark || "---"}</td>
                          <td className="text-nowrap">{formatDate(item?.createdAt || "---")}</td>
                          <td className="text-nowrap">{item?.servicesMoney || "---"}</td>
                          <td className="text-nowrap">
                            {item?.createdBy?.first_name || "---"} {item?.createdBy?.last_name || "---"}
                          </td>
                          {checkUserRole(["SUPER_ADMIN"]) ? (
                            <td className="text-nowrap text-center">
                              <div className="btn-group">
                                <Update item={item} onSuccess={() => setReloading(!reloading)} />
                                <Delete cp_id={item?.cp_id} onSuccess={() => setReloading(!reloading)} />
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
