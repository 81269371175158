import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_DRIVER_COMPANY = gql`
    query DriverPartners($where: driverPartnerInput, $limit: Int, $skip: Int) {
      driverPartners(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          cp_id
          cp_name
          cp_code
          cp_phoneNumber
          cp_address
          cp_remark
          status
          createdAt
          createdBy {
            _id
            first_name
            last_name
          }
          updatedAt
          servicesMoney
        }
      }
    }
  `;

  static CREATE_DRIVER_PARTners = gql`
    mutation CreateDriverPartner($data: driverPartnerInput!) {
      createDriverPartner(data: $data) {
        status
      }
    }
  `;

  static DELETE_DRIVER_PARTners = gql`
    mutation DeleteDriverPartner($where: whereCpID!) {
      deleteDriverPartner(where: $where) {
        status
      }
    }
  `;

  static UPDATE_DRIVER_PARTNER = gql`
    mutation UpdateDriverPartner($where: whereCpID!, $data: driverPartnerInput) {
      updateDriverPartner(where: $where, data: $data) {
        status
        data
      }
    }
  `;
}
