import React, { useEffect, useState } from "react";
import { RootVariable, checkUserRole } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import Schema from "../centralSeparation/Schema";
import TableData from "../../../utils/components/TableData";
import { ParentPath } from "../../../routes/Path";
import ListVehicle from "./listVehicle";
import _ from "lodash";
import { Delete, Update, Updated } from "../centralSeparation/component";


export default function ContainVehicleCentral() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_CENTER, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    FetchData({
      variables: {
        where: {},
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.separationCenters?.data || []);
    setTotal(data?.separationCenters?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item active">ບັນຈຸລົດເຂົ້າໃນສູນ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header border-bottom">
            <h4>ບັນຈຸລົດເຂົ້າໃນສູນ</h4>
          </div>
          <div className="card-body">
            <div className="row blog">
              <div className="col-md-12 p-2">
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ຊື່ສູນ</th>
                      <th className="text-nowrap text-center">ອັກສອນຫຍໍ້</th>
                      <th className="text-nowrap text-center">ລາຍລະອຽດ</th>
                      <th className="text-nowrap text-center">ລົດໃນສູນ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">{value?.centerName || "---"}</td>
                          <td className="text-nowrap">{value?.centerCode || "---"}</td>
                          <td className="text-nowrap">{value?.note || "----"}</td>
                          <td className="text-nowrap text-center">
                            {checkUserRole(["SUPER_ADMIN"]) ? (
                              <>
                                <div className="btn-group">
                                  <Update item={value} onSuccess={() => setReloading(!reloading)} />
                                  <Delete st_id={value?.st_id} onSuccess={() => setReloading(!reloading)} />
                                  {value?.isPublic === 1 ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-secondary"
                                        onClick={() =>
                                          navigate(`${ParentPath.addVehicle}?centerID=${value?.st_id}&&titleCenter=${value?.centerName}`)
                                        }
                                      >
                                        <i className="fa fa-add" /> ເພີ່ມລົດ
                                      </button>
                                      <Updated ID={value?.st_id} onSuccess={() => setReloading(!reloading)} />
                                    </>
                                  ) : (
                                    <>
                                      <button type="button" className="btn btn-secondary btn-sm" disabled>
                                        <i className="fas fa-add"></i> ເພີ່ມລົດ
                                      </button>
                                      <button type="button" className="btn btn-danger btn-sm" disabled>
                                        ປິດ
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : null}
                            <br />
                            <br />
                            <ListVehicle whereID={value?.st_id} />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
