import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_EXTRA_PAYMENT = gql`
    query ExtraPayments($where: extraPaymentWhereInput, $limit: Int, $skip: Int) {
      extraPayments(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          exTraID
          extraDriverID {
            _id
            first_name
            last_name
            gender
          }
          exTraAmount
          exTraDate
          exTraNote
          exTraCheck
          noID
          isExtra
          exTraCreatedAt
          exTraStatus
          exTraCreatedBy {
            last_name
            _id
            first_name
            gender
          }
          consolidate_ID {
            consolidateID
          }
        }
      }
    }
  `;

  static CERATE_EXTRA_PAYMENT = gql`
    mutation CreateExtraPayment($data: extraPaymentInput!) {
      createExtraPayment(data: $data) {
        status
      }
    }
  `;
  static UPDATE_EXTRA_PAYMENT = gql`
    mutation UpdateExtraPayment($where: whereEID!, $data: extraPaymentInput) {
      updateExtraPayment(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static DELETE_EXTRA_PAYMENT = gql`
    mutation DeleteExtraPayment($where: whereEID!) {
      deleteExtraPayment(where: $where) {
        status
      }
    }
  `;
}
