import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Schema from "./Schema";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Calculator, Gender, RootVariable, checkUserRole, currency, formatDateTime } from "../../../utils";
import { useSearchParams } from "react-router-dom";
import TableData from "../../../utils/components/TableData";
import SelectDriverRoute from "../../../utils/components/SelectDriverRoute";
import moment from "moment";
import SearchDateRange from "../../../utils/components/SearchDateRange";
import UpdateShippingRouteChecking from "./updateShippingCheckingTwo";
import { UpdateBranchInLocation, UpdateRoute, Verified } from "./componentTwo";
import { IoMdCloseCircle } from "react-icons/io";
import { UpdateHistorySup } from "../shippingRouteTwo/componentTwo";
import { FaList } from "react-icons/fa";

export default function ShippingCheckingRouteTwo() {
  const [items, setItems] = useState<any[]>();
  const [showItems, setShowItems] = useState<Record<string, any[]>>({});
  const [searchValue, setSearchValue] = useState("");
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [pageNumber, setPageNumber] = useState(1);
  const [reloading, setReloading] = useState(false);
  const [reloadhistory, setReloadhistory] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectIsDAy, setSelectIsDay] = useState<any>();
  const [selectVehicle, setSelectVehicle] = useState<any>();
  const [selectCheckRoute, setSelectCheckRoute] = useState<any>();
  const [checkStatus, setCheckStatus] = useState<any>();
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [DataRows, setDataRows] = useState<boolean[]>([]);
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [query_shipping_check, { data, loading }] = useLazyQuery(Schema.QUERY_SHIPPING_CHECK, {
    fetchPolicy: "cache-and-network",
  });
  const [FetchData, { data: result }] = useLazyQuery(Schema.QUERY_HISTORY_SHIPPING, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    if (dateBetween?.length !== 2) return;
    const vehicleID = selectVehicle === 0 ? undefined : Number(selectVehicle);
    query_shipping_check({
      variables: {
        where: {
          vehicleID: vehicleID ? vehicleID : undefined,
          isDay: selectIsDAy ? selectIsDAy : undefined,
          checkScan: Number(checkStatus) ? Number(checkStatus) : undefined,
          checkRoute: selectCheckRoute ? selectCheckRoute : undefined,
          createdDateBetween: [dateBetween[0], dateBetween[1]],
          acceptStatus: "ACCEPTED",
          isDif: ["2"],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [
    query_shipping_check,
    pageNumber,
    numberRow,
    searchValue,
    reloading,
    total,
    checkStatus,
    selectIsDAy,
    selectCheckRoute,
    selectVehicle,
    dateBetween,
    reloadhistory,
  ]);

  useEffect(() => {
    setItems(data?.shippingRoutes?.data || []);
    setTotal(data?.shippingRoutes?.total || 0);
  }, [data]);

  useEffect(() => {
    const _pages = searchParams.get("page");
    const _rows = searchParams.get("rows");
    const _isDay = searchParams.get("isDay");
    const _vehicle = searchParams.get("vehicle");
    const _checkRoute = searchParams.get("CheckRoute");
    const _checkStatus = searchParams.get("CheckStatus");

    const decodeVehicle = _vehicle ? atob(_vehicle) : undefined;
    const decodeIsDay = _isDay ? atob(_isDay) : undefined;
    const decodeCheckRoute = _checkRoute ? atob(_checkRoute) : undefined;
    const decodeCheckStatus = _checkStatus ? atob(_checkStatus) : undefined;

    setSelectVehicle(decodeVehicle ? decodeVehicle : undefined);
    setSelectIsDay(decodeIsDay ? decodeIsDay : undefined);
    setSelectCheckRoute(decodeCheckRoute ? decodeCheckRoute : undefined);
    setCheckStatus(decodeCheckStatus ? decodeCheckStatus : undefined);

    setPageNumber(_pages ? parseInt(_pages) : 1);
    setNumberRow(_rows ? parseInt(_rows) : RootVariable.limit);
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");
    if (_startDate && _endDate) setDateBetween([_startDate, _endDate]);
    else setDateBetween([moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
  }, [searchParams]);

  useEffect(() => {
    if (selectedId) {
      FetchData({
        variables: {
          where: {
            sping_route: Number(selectedId),
            service: "OPEN",
          },
        },
      });
    }
  }, [FetchData, selectedId, reloadhistory]);

  useEffect(() => {
    if (selectedId && result?.HistoryShippingRoutes?.data) {
      setShowItems((prevState) => ({
        ...prevState,
        [selectedId]: result?.HistoryShippingRoutes?.data || [],
      }));
    }
  }, [result, selectedId]);

  const toggleRow = (index: number, sp_id: string) => {
    setDataRows((prev) => {
      const newDataRows = [...prev];
      newDataRows[index] = !newDataRows[index];
      return newDataRows;
    });
    setSelectedId(sp_id);
  };

  const handleSuccess = () => {
    setReloadhistory(!reloadhistory);
  };

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ລາຍການລົດ ກຳລັງເດີນທາງ ແລະ ຮອດປາຍທາງເສີມພິເສດ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header">
            <h3>
              ລາຍການລົດ ກຳລັງເດີນທາງ ແລະ ຮອດປາຍທາງ<span style={{ color: "red" }}>(ເສີມພິເສດ)</span>
            </h3>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-4">
                    <SearchDateRange />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມກະອອກລົດ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("isDay", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="1">ພາກແລງ</option>
                      <option value="0">ພາກເຊົ້າ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ເລືອກຕາມເບີລົດ</label>
                    <SelectDriverRoute
                      onChange={(option: any) => {
                        searchParams.set("vehicle", btoa(option.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ປະເພດສາຍ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("CheckRoute", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="OUT">ສາຍນອກ</option>
                      <option value="IN">ສາຍໃນ</option>
                      <option value="SPARE">ສາຍສຳຮອງ</option>
                    </Form.Select>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="#">ການກວດສອບ</label>
                    <Form.Select
                      onChange={(e: any) => {
                        searchParams.set("CheckStatus", btoa(e.target.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="">ທັງໝົດ</option>
                      <option value="1">ກວດສອບ(ຜ່ານ)</option>
                      <option value="0">ກວດສອບ(ບໍ່ຜ່ານ)</option>
                    </Form.Select>
                  </div>
                </div>
                <br />
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || " ");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(1)</th>
                      <th className="text-nowrap text-center">ໂຊເຟີ້(2)</th>
                      <th className="text-nowrap text-center">ເບີລົດ</th>
                      <th className="text-nowrap text-center">ຜູ້ຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ສາຍທີ່ແລ່ນ</th>
                      <th className="text-nowrap text-center">ເລກທີໂກຕ່າ</th>
                      <th className="text-nowrap text-center">ປະເພດສາຍ</th>
                      <th className="text-nowrap text-center">ກະອອກລົດ</th>
                      <th className="text-nowrap text-center">ປະເພດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ການກວດສອບ</th>
                      <th className="text-nowrap text-center">ກວດສອບໂດຍ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວໃນສາຍ(ກີບ)</th>
                      <th className="text-nowrap text-center">ເວລາອອກຕົ້ນທາງ</th>
                      <th className="text-nowrap text-center">ເວລາຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ໃຊ້ເວລາເດີນທາງ</th>
                      <th className="text-nowrap text-center">ຢືນຢິນຮອດປາຍທາງ</th>
                      <th className="text-nowrap text-center">ວັນທີຈັດຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ວັນທີກວດສອບ</th>
                      <th className="text-nowrap text-center">ວັນທີສະແກນອອກ</th>
                      <th className="text-nowrap text-center">ໝາຍເຫດ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນອອກໂດຍ</th>
                      <th className="text-nowrap text-center">ຢືນຢັນຮອດໂດຍ</th>
                      <th className="text-nowrap text-center">ສູນປາຍທາງ</th>
                      <th className="text-nowrap text-center">ຈັດການ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index} className={value?.isDif === "1" ? "table-danger" : ""}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.driverID?.gender ? Gender(value?.driverID?.gender) : ""}&nbsp; {value?.driverID?.first_name || "----"}{" "}
                            {value?.driverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.driverTwo?.gender ? Gender(value?.driverTwo?.gender) : ""}&nbsp; {value?.driverTwo?.first_name || "----"}{" "}
                            {value?.driverTwo?.last_name || "-----"}
                          </td>
                          <td className="text-nowrap">{value?.vehicleID?.vehicleName || "-----"}</td>
                          <td className="text-nowrap ">
                            {value?.createdBy?.gender ? Gender(value?.createdBy?.gender) : ""} {value?.createdBy?.first_name || "----"}{" "}
                            {value?.createdBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap ">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <button
                                onClick={() => toggleRow(index, value?.sp_id || "")}
                                style={{ border: "none", background: "transparent", cursor: "pointer" }}
                              >
                                {DataRows[index] ? <IoMdCloseCircle size={20} /> : <FaList size={20} />}
                              </button>
                              {value?.routeLocationID?.title || "----"}
                            </div>
                            {DataRows[index] && (
                              <div className="location-info">
                                {showItems[value?.sp_id]?.length > 0 ? (
                                  <>
                                    {showItems[value?.sp_id].map((item, itemIndex) => (
                                      <p key={itemIndex}>
                                        <UpdateHistorySup item={item} className="delete-button" onSuccess={handleSuccess} /> {itemIndex + 1}: ສາຂາ{" "}
                                        {item?.branch_id?.branch_name || "---"}
                                      </p>
                                    ))}

                                    {value?.newLocation === "NO" ? (
                                      <>
                                        <hr />
                                        <UpdateBranchInLocation values={value} onSuccess={handleSuccess} />
                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  <p>--- ບໍ່ມີຂໍ້ມູນສາຂາ ---</p>
                                )}
                              </div>
                            )}
                          </td>
                          <td className="text-center text-nowrap">{value?.billNoRout || "----"}</td>
                          <td className="text-center text-nowrap">
                            {value?.checkRoute === "IN" ? "ສາຍໃນ" : value?.checkRoute === "OUT" ? "ສາຍນອກ" : "ສາຍສຳຮອງ"}
                          </td>
                          <td className="text-center text-nowrap">{value?.isDay === "1" ? "ພາກແລງ" : " ພາກເຊົ້າ"}</td>
                          <td className={value?.isDif === "2" ? "text-danger text-nowrap" : "text-center text-nowrap"}>
                            {value?.isDif === "2" ? "ຖ້ຽວເສີມພິເສດ" : ""}
                          </td>
                          <td className="text-center text-nowrap">
                            {value?.checkScan === 0 ? (
                              <span className="text-danger text-nowrap">ຍັງບໍ່ກວດສອບ</span>
                            ) : value?.checkScan === 1 ? (
                              <span className="text-success text-nowrap">ກວດສອບ(ຜ່ານ)</span>
                            ) : value?.checkScan === 2 ? (
                              <span className="text-danger text-nowrap">ກວດສອບ(ບໍ່ຜ່ານ)</span>
                            ) : null}
                          </td>
                          <td className="text-nowrap">
                            {value?.checkBy?.gender ? Gender(value?.checkBy?.gender) : ""}
                            {value?.checkBy?.first_name || "-----"} {value?.checkBy?.last_name || "----"}
                          </td>

                          <td className="text-nowrap">{currency(value?.amountValue ? value?.amountValue : 0)} ກີບ</td>
                          <td className="text-center text-nowrap">{formatDateTime(value?.startTime)}</td>
                          <td className="text-center text-nowrap">{value?.endTime ? formatDateTime(value?.endTime) : "-----"}</td>
                          <td className="text-nowrap text-center">{value?.endTime ? Calculator(value?.startTime, value?.endTime) : "-----"}</td>
                          <td className="text-center text-nowrap">
                            {value?.newLocation === "NO" ? (
                              <>
                                <h5>
                                  <Button variant="danger" className="disable-cursor" size="sm">
                                    <Spinner animation="border" size="sm" variant="warning" /> ລົດກຳລັງເດີນທາງ...
                                  </Button>
                                </h5>
                              </>
                            ) : (
                              <>
                                <h5>
                                  <Button variant="success" className="disable-cursor" size="sm">
                                    <i className="fas fa-check-circle"></i> ຮອດປາຍທາງແລ້ວ
                                  </Button>
                                </h5>
                              </>
                            )}
                          </td>
                          <td className="text-center text-nowrap">{value?.createdAt ? formatDateTime(value?.createdAt) : "----"}</td>
                          <td className="text-center text-nowrap">{value?.checkDate ? formatDateTime(value?.checkDate) : "-----"}</td>
                          <td className="text-center text-nowrap">{value?.dateScan_out ? formatDateTime(value?.dateScan_out) : "-----"}</td>
                          <td className="text-nowrap">{value?.difNote || "----"}</td>
                          <td className="text-nowrap">
                            {value?.acceptBy?.gender ? Gender(value?.acceptBy?.gender) : ""} {value?.acceptBy?.first_name || "----"}
                            {value?.acceptBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">
                            {value?.acceptArrivedBy?.gender ? Gender(value?.acceptArrivedBy?.gender) : ""}{" "}
                            {value?.acceptArrivedBy?.first_name || "----"}
                            {value?.acceptArrivedBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.centerRoute?.centerName || "----"}</td>

                          <td className=" text-nowrap text-nowrap">
                            <div className="btn-group">
                              {checkUserRole(["SUPER_ADMIN"]) ? (
                                <>
                                  <UpdateShippingRouteChecking Items={value} />
                                  <UpdateRoute item={value} onSuccess={() => setReloading(!reloading)} />
                                </>
                              ) : null}
                              {checkUserRole(["SUPER_ADMIN", "ADMIN", "AUDITOR"]) ? (
                                <Verified data={value} onSuccess={() => setReloading(!reloading)} />
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
