import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ParentPath } from "../routes/Path";
import { clearLocalStorage, confirmShow } from "../utils";
import ProfileInfo from "../utils/components/ProfileInfo";

export default function NavbarApp() {
  const navigate = useNavigate();
  const onLogout = () => {
    confirmShow("ທ່ານຕ້ອງການອອກຈາກລະບົບ ແທ້ ຫຼື ບໍ່?", () => {
      clearLocalStorage();
      window.location.replace(ParentPath.login);
    });
  };

  const toggleSidebar = () => {
    const hide = localStorage.getItem("hide_sidebar");
    const sidebar = document.getElementById("app-sidebar");
    const layout = document.getElementById("app-layout");

    if (!hide) {
      localStorage.setItem("hide_sidebar", "true");
      sidebar?.classList.remove("show");
      layout?.classList.remove("sidebar-show");
      return;
    }

    if (hide === "true") {
      localStorage.setItem("hide_sidebar", "false");
      sidebar?.classList.add("show");
      layout?.classList.add("sidebar-show");
    } else {
      localStorage.setItem("hide_sidebar", "true");
      sidebar?.classList.remove("show");
      layout?.classList.remove("sidebar-show");
    }
  };
  return (
    <>
      <Navbar bg="dark" variant="dark" className="px-3" id="app-navbar">
        <Nav.Link className="btn-bars" onClick={toggleSidebar}>
          <i className="fas fa-bars fs-5" />
        </Nav.Link>
        <Container>
          <Navbar.Brand
            onClick={(e) => {
              navigate("/home");
            }}
          >
            ລະບົບບໍລິຫານຈັດຖ້ຽວລົດ ອານຸສິດ ໂລຈິສະຕິກ
          </Navbar.Brand>
          <Nav className="me-auto w-100 justify-content-end nav-link-control" style={{ marginTop: -20, marginInlineStart: 90 }}>
          </Nav>
        </Container>
        <Dropdown align={"end"}>
          <Dropdown.Toggle variant="lightgrey" className="btn-user-dropdown">
            <i className="fas fa-user m-0" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <ProfileInfo />
            <Dropdown.Item onClick={onLogout}>
              <i className="fas fa-right-from-bracket m-0" /> ອອກຈາກລະບົບ
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
    </>
  );
}
