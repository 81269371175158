import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";

import Login from "../screens/login";
import { clearLocalStorage, LocalStorageName } from "../utils";
import { ParentPath, ChildPath } from "./Path";
import { gql, useMutation } from "@apollo/client";
import Home from "../screens/home/index";
import AppLayout from "../layouts/index";
import User from "../screens/user";
import CompanyDriverPartner from "../screens/companyDriverPartner";
import driverPartnerSeparation from "../screens/driverPartnerSeparation";
import transportationVehicle from "../screens/transportationVehicle";
import calculateFare from "../screens/calculateFare";
import vehicleManagement from "../screens/vehicleManagement";
import CheckingDestinationVehicle from "../screens/checkingDestinationVehicle";
import SummarizeDestinationVehicle from "../screens/summarizeDestinationVehicle";
import SummarizeDestinationVehicleTwo from "../screens/summarizeDestinationVehicleTwo";
import Trash from "../screens/trash";
import HistoryComponent from "../screens/transportationVehicle/modifyDestinationVehicle/components";
import SummaryTotal from "../screens/summarizeDestinationVehicle/componentsConsolidate";
import SummaryTotalTwo from "../screens/summarizeDestinationVehicleTwo/componentsConsolidateTwo";
import AddVehicle from "../screens/driverPartnerSeparation/containVehicleCentral/containVehicle";
import RecordBranches from "../screens/transportationVehicle/destinationVehicle/addBranches";
import RecordVehicle from "../screens/transportationVehicle/destinationVehicle/addVehicle";
import DetailDriver from "../screens/transportationVehicle/containDriver/detailDriver";
import DetailUser from "../screens/transportationVehicle/containDriver/detailUser";
import InformationDriver from "../screens/user/InformationUser";
import AddShippingRoute from "../screens/vehicleManagement/shippingRoute/addShippingRoute";
import UpdateShippingRoute from "../screens/vehicleManagement/shippingRoute/updateShippingRoute";
import ExtraPayment from "../screens/summarizeDestinationVehicle/extraPayment";
import DeductionPayment from "../screens/summarizeDestinationVehicle/deductionPayment";
import PrintDetails from "../screens/summarizeDestinationVehicle/invoice";
import ExportTotal from "../screens/summarizeDestinationVehicle/ExportTotal";
import ListShippings from "../screens/summarizeDestinationVehicle/listRouteDetail";
import ExportIBank from "../screens/exportIBank/index";
import ExportIBankTwo from "../screens/exportIBankTwo/index";
import ListShippingsTwo from "../screens/summarizeDestinationVehicleTwo/listRouteDetailTwo";
import ExtraPaymentTwo from "../screens/summarizeDestinationVehicleTwo/extraPaymentTwo";
import DeductionPaymentTwo from "../screens/summarizeDestinationVehicleTwo/deductionPaymentTwo";

// Public Route
const publicRoute = createBrowserRouter([
  { path: "/", element: <Navigate to={ParentPath.login} /> },
  { path: ParentPath.login, element: <Login /> },

  // Not Found
  { path: "*", element: <Navigate to={ParentPath.login} /> },
]);

// Private Route
const privateRoute = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={ParentPath.home} />,
  },
  {
    path: ParentPath.home,
    element: <AppLayout component={<Home />} />,
  },
  {
    path: ParentPath.users,
    element: <AppLayout component={<User />} />,
  },
  {
    path: ParentPath.companyDriverPartner,
    element: <AppLayout component={<CompanyDriverPartner />} />,
  },
  {
    path: ParentPath.driverPartnerSeparation,
    children: [
      { path: "", element: <Navigate to={ChildPath.centralSeparation} /> },
      { path: ChildPath.centralSeparation, element: <AppLayout component={<driverPartnerSeparation.CentralSeparation />} /> },
      { path: ChildPath.containVehicleCentral, element: <AppLayout component={<driverPartnerSeparation.ContainVehicleCentral />} /> },
    ],
  },
  {
    path: ParentPath.transportationVehicle,
    children: [
      { path: "", element: <Navigate to={ChildPath.containDriver} /> },
      { path: ChildPath.containDriver, element: <AppLayout component={<transportationVehicle.ContainDriver />} /> },
      { path: ChildPath.destinationVehicle, element: <AppLayout component={<transportationVehicle.DestinationVehicle />} /> },
      { path: ChildPath.modifyDestinationVehicle, element: <AppLayout component={<transportationVehicle.ModifyDestinationVehicle />} /> },
      { path: ChildPath.typeVehicle, element: <AppLayout component={<transportationVehicle.TypeVehicle />} /> },
      { path: ChildPath.informationVehicle, element: <AppLayout component={<transportationVehicle.ManageVehicle />} /> },
      { path: ChildPath.HistoryComponent, element: <AppLayout component={<HistoryComponent />} /> },
    ],
  },
  {
    path: ParentPath.calculateFare,
    children: [
      { path: "", element: <Navigate to={ChildPath.deductionPayment} /> },
      { path: ChildPath.deductionPayment, element: <AppLayout component={<calculateFare.DeductionPayment />} /> },
      { path: ChildPath.exTraPayment, element: <AppLayout component={<calculateFare.ExtraPayment />} /> },
    ],
  },
  {
    path: ParentPath.vehicleManagement,
    children: [
      { path: "", element: <Navigate to={ChildPath.modifyPriceRoute} /> },
      { path: ChildPath.modifyPriceRoute, element: <AppLayout component={<vehicleManagement.ModifyPriceRoute />} /> },
      { path: ChildPath.shippingCheckingRoute, element: <AppLayout component={<vehicleManagement.ShippingCheckingRoute />} /> },
      { path: ChildPath.shippingCheckingRouteTwo, element: <AppLayout component={<vehicleManagement.ShippingCheckingRouteTwo />} /> },
      { path: ChildPath.shippingRoute, element: <AppLayout component={<vehicleManagement.ShippingRoute />} /> },
      { path: ChildPath.shippingRouteTwo, element: <AppLayout component={<vehicleManagement.ShippingRouteTwo />} /> },
    ],
  },
  {
    path: ParentPath.checkingDestinationVehicle,
    element: <AppLayout component={<CheckingDestinationVehicle />} />,
  },
  {
    path: ParentPath.summarizeDestinationVehicle,
    element: <AppLayout component={<SummarizeDestinationVehicle />} />,
  },
  {
    path: ParentPath.summarizeDestinationVehicleTwo,
    element: <AppLayout component={<SummarizeDestinationVehicleTwo />} />,
  },
  {
    path: ParentPath.ExportIBank,
    element: <AppLayout component={<ExportIBank />} />,
  },
  {
    path: ParentPath.ExportIBankTwo,
    element: <AppLayout component={<ExportIBankTwo />} />,
  },

  {
    path: ParentPath.addVehicle,
    element: <AppLayout component={<AddVehicle />} />,
  },
  {
    path: ParentPath.RecordBranches,
    element: <AppLayout component={<RecordBranches />} />,
  },
  {
    path: ParentPath.RecordVehicle,
    element: <AppLayout component={<RecordVehicle />} />,
  },
  {
    path: ParentPath.DetailDriver,
    element: <AppLayout component={<DetailDriver />} />,
  },
  {
    path: ParentPath.DetailUser,
    element: <AppLayout component={<DetailUser />} />,
  },
  {
    path: ParentPath.AddShippingRoute,
    element: <AppLayout component={<AddShippingRoute />} />,
  },
  {
    path: ParentPath.UpdateShippingRoute,
    element: <AppLayout component={<UpdateShippingRoute />} />,
  },
  {
    path: ParentPath.InformationDriver,
    element: <AppLayout component={<InformationDriver />} />,
  },
  {
    path: ParentPath.summaryTotal,
    element: <AppLayout component={<SummaryTotal />} />,
  },
  {
    path: ParentPath.summaryTotalTwo,
    element: <AppLayout component={<SummaryTotalTwo />} />,
  },
  {
    path: ParentPath.trash,
    element: <AppLayout component={<Trash />} />,
  },
  {
    path: ParentPath.DeductionPayments,
    element: <AppLayout component={<DeductionPayment />} />,
  },
  {
    path: ParentPath.DeductionPaymentTwo,
    element: <AppLayout component={<DeductionPaymentTwo />} />,
  },
  {
    path: ParentPath.ExtraPayments,
    element: <AppLayout component={<ExtraPayment />} />,
  },
  {
    path: ParentPath.ExtraPaymentTwo,
    element: <AppLayout component={<ExtraPaymentTwo />} />,
  },
  {
    path: ParentPath.ExportTotal,
    element: <AppLayout component={<ExportTotal />} />,
  },
  {
    path: ParentPath.listShipping,
    element: <AppLayout component={<ListShippings />} />,
  },
  {
    path: ParentPath.ListShippingsTwo,
    element: <AppLayout component={<ListShippingsTwo />} />,
  },
  {
    path: ParentPath.PrintInvoice,
    element: <AppLayout component={<PrintDetails />} />,
  },
  // Not Found
  {
    path: "*",
    element: <Navigate to={ParentPath.home} />,
  },
]);

export default function Routes() {
  const token = localStorage.getItem(LocalStorageName.token);
  return (
    <React.Fragment>
      {token ? <TokenValidation component={<RouterProvider router={privateRoute} />} /> : <RouterProvider router={publicRoute} />}
    </React.Fragment>
  );
}

function TokenValidation({ component }: { component: React.ReactNode }) {
  const MUTATION = gql`
    mutation TokenValidation {
      tokenValidation {
        status
      }
    }
  `;
  const [tokenValidation] = useMutation(MUTATION);

  useEffect(() => {
    (async () => {
      const { data } = await tokenValidation();
      if (data?.tokenValidation?.status !== 200) {
        clearLocalStorage();
        window.location.replace("/login");
      }
    })();
  }, [tokenValidation]);

  return <React.Fragment>{component}</React.Fragment>;
}
