import { useEffect } from "react";
import { UserRole } from "../utils";
import NavbarApp from "./Navbar";
import "./Style.scss";
import Sidebar from "./Sidebar";

interface AppLayoutProps {
  component: JSX.Element;
  roles?: UserRole[];
}

export default function AppLayout({ component, roles }: AppLayoutProps) {
  useEffect(() => {
    const hide = localStorage.getItem("hide_sidebar");
    const sidebar = document.getElementById("app-sidebar");
    const layout = document.getElementById("app-layout");
    if (!hide || hide === "false") {
      sidebar?.classList.add("show");
      layout?.classList.add("sidebar-show");
    }
  }, []);

  // Check Role

  return (
    <div id="app-layout">
      <div className="toggle-sidebar-container">
        <NavbarApp />
      </div>
      <Sidebar />
      <div className="toggle-sidebar-container">
        <div id="app-content">{component}</div>
      </div>
    </div>
  );
}
