import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_SHIPPING_ROUTES = gql`
    query ShippingRoutes($where: ShippingRouteWhereInput, $limit: Int, $skip: Int) {
      shippingRoutes(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          sp_id
          routeLocationID {
            title
            _id
          }
          vehicleID {
            _id
            vehicleName
          }
          status
          startTime
          endTime
          amountValue
          paidStatus
          paidDate
          acceptStatus
          acceptBy {
            _id
            first_name
            last_name
            gender
          }
          newLocation
          driverID {
            _id
            first_name
            last_name
            gender
          }
          driverTwo {
            first_name
            last_name
            gender
          }
          createdAt
          createdBy {
            _id
            first_name
            last_name
            gender
          }
          consolidate_ID {
            consolidateID
          }
          isConsolidated
          consolidatePaymentID
          isDif
          difNote
          isDay
          checkRoute
          pay
          petroleum
          checkScan
          dateScan_out
          checkBy {
            _id
            first_name
            last_name
            gender
          }
          checkDate
          noID
          noRID
          totalItem
          billNoRout
          acceptArrivedBy {
            _id
            first_name
            last_name
            gender
          }
          centerRoute {
            st_id
            centerName
            centerCode
          }
        }
      }
    }
  `;

  static UPDATE_ALL = gql`
    mutation Mutation($where: whereId!) {
      updateShippingRoute(where: $where) {
        status
        data
      }
    }
  `;

  static UPDATE_CHECK_SCAN = gql`
    mutation UpdateShippingRouteCheckScan($where: whereCheckScan!, $data: ShippingRouteWhereInput) {
      updateShippingRouteCheckScan(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static UPDATE_VERIFY_SHIPPING_ROUTE = gql`
    mutation UpdateShippingRouteVerify($where: whereVerify!, $data: ShippingRouteWhereInput) {
      updateShippingRouteVerify(where: $where, data: $data) {
        status
        data
      }
    }
  `;
  static VERIFY_BY_ADMIN = gql`
    mutation UpdateShippingRouteVerifyByAdmin($where: whereVerifyByAdmin!, $data: ShippingRouteWhereInput) {
      updateShippingRouteVerifyByAdmin(where: $where, data: $data) {
        status
        data
      }
    }
  `;
}
