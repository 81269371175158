import React, { useState } from "react";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import compressImage, {
  LocalStorageName,
  MessageConfirms,
  MessageErrors,
  MessageSuccess,
  confirmShow,
  getStaffLogin,
  notifyError,
  notifySuccess,
} from "../../utils";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useFormik } from "formik";
import SearchCenter from "../../utils/components/SelectCenter";
import SearchDriverCompany from "../../utils/components/SelectDriverCompany";
import axios from "axios";
import { FcAddImage, FcImageFile } from "react-icons/fc";
import { url_upload_Profile, url_upload_cardImage, url_upload_docFile, url_upload_passImage } from "../../configs/Environment";

const initialValues: any = {
  first_name: "",
  last_name: "",
  gender: "",
  age: 0,
  address: "",
  cardID: 0,
  image: "",
  passID: "",
  basicSalary: 0,
  cardImage: "",
  startDate: "",
  phoneNumber: 0,
  password: "",
  role: "",
  center: 0,
  account_bank_name: "",
  account_bank_number: "",
  docFile: "",
  stStatus: 0,
  passImage: "",
};

const validate = (values: any) => {
  const errors: any = {};
  if (!values?.first_name) errors.first_name = "ກະລຸນາປ້ອນຊື່";
  if (!values?.last_name) errors.last_name = "ກະລຸນາປ້ອນນາມສະກຸນ";
  if (!values?.gender) errors.gender = "ກະລຸນາປ້ອນເພດ";
  if (!values?.age) errors.age = "ກະລຸນາປ້ອນອາຍຸ";
  if (!values?.address) errors.address = "ກະລຸນາປ້ອນທີ່ຢູ່";
  if (!values?.startDate) errors.startDate = "ກະລຸນາປ້ອນວັນທີເຂົ້າວຽກ";
  if (!values?.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທໃຫ້ຄົບ 8 ຕົວເລກກ່ອນ";
  if (!values?.password) errors.password = "ກະລຸນາປ້ອນລະຜ່ານ";
  if (!values?.role) errors.role = "ກະລຸນາເລືອກສິດທິນຳໃຊ້";
  if (!values?.account_bank_name) errors.account_bank_name = "ກະລຸນາປ້ອນຊື່ທະນາຄານ";
  if (!values?.account_bank_number) errors.account_bank_number = "ກະລຸນາປ້ອນເລກບັນຊີໃຫ້ຄົບ 13 ຕົວເລກກ່ອນ";
  if (!values?.center) errors.center = "ກະລຸນາເລືອກສູນກ່ອນ";
  if (!values?.stStatus) errors.stStatus = "ກະລຸນາເລືອກບໍລິສັດ";

  return errors;
};

// create component
export const Create = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_USERS);
  const userID = getStaffLogin()?._id;
  const [PassImage, setPassImage] = useState<any>();
  const [CardIDimage, setCardImage] = useState<any>();
  const [userFile, setUserFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const handleChangeFileImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("image", compressedFile);
        const res = await axios.post(url_upload_Profile, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setImage(filename);
        } else {
          setImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
      return compressedFile;
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // userImage

  const handleChangeFileCardImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setCardImage(e.target?.value);
        const formData = new FormData();
        formData.append("cardImage", compressedFile);
        const res = await axios.post(url_upload_cardImage, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setCardImage(filename);
        } else {
          setCardImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // PassImage

  const handleChangeFilePassImage = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile: any = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setPassImage(e.target?.value);
        const formData = new FormData();
        formData.append("passImage", compressedFile);
        const res = await axios.post(url_upload_passImage, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setPassImage(filename);
        } else {
          setPassImage("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // useFile

  const handleChangeUserFile = async (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        setUserFile(e.target?.value);
        const formData = new FormData();
        formData.append("docFile", file);
        const res = await axios.post(url_upload_docFile, formData, {
          headers: {
            Authorization: localStorage.getItem(LocalStorageName.token),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          notifySuccess("ອັບໂຫລດຮູບພາບສຳເລັດແລ້ວ");
          setUserFile(filename);
        } else {
          setUserFile("");
          notifyError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      notifyError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<any>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await createData({
          variables: {
            data: {
              cardID: Number(values?.cardID),
              basicSalary: Number(values?.basicSalary),
              createdBy: Number(userID),
              first_name: String(values?.first_name),
              last_name: String(values?.last_name),
              gender: String(values?.gender),
              age: Number(values?.age),
              address: String(values?.address),
              image: String(image),
              passID: String(values?.passID),
              cardImage: String(CardIDimage),
              startDate: String(values?.startDate),
              phoneNumber: Number(values?.phoneNumber),
              password: String(values?.password),
              role: String(values?.role),
              center: Number(values?.center),
              account_bank_name: String(values?.account_bank_name),
              account_bank_number: String(values?.account_bank_number),
              docFile: String(userFile),
              stStatus: Number(values?.stStatus),
              passImage: String(PassImage),
            },
          },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add" />
        ເພີ່ມຂໍ້ມູນຜູ້ໃຊ້ລະບົບໃຫມ່
      </Button>

      <Modal show={show} dialogClassName="modal-width-95">
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
            resetForm();
          }}
        >
          <Modal.Title>
            <i className="fas fa-add" />
            ເພີ່ມຂໍ້ມູນຜູ້ໃຊ້ລະບົບ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-image">
                    {image ? (
                      <>
                        <Image
                          src={url_upload_Profile + image}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={handleChangeFileImage}
                          id="file-upload-image"
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          className="form-control"
                          onChange={handleChangeFileImage}
                          id="file-upload-image"
                          hidden
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບຜູ້ນຳໃຊ້ລະບົບ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ຊື່</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    placeholder="ກະລຸນາປ້ອນຊື່"
                    onChange={handleChange}
                    value={values?.first_name}
                    isInvalid={errors?.first_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ນາມສະກຸນ</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                    onChange={handleChange}
                    value={values?.last_name}
                    isInvalid={errors?.last_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກເພດ</Form.Label>
                  <Form.Select name="gender" onChange={handleChange} value={values?.gender} isInvalid={errors?.gender ? true : false}>
                    <option>-- ເລືອກ --</option>
                    <option value="MALE">ເພດຊາຍ</option>
                    <option value="FEMALE">ເພດຍິງ</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ອາຍຸ</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="ອາຍຸ"
                    onWheel={(e: any) => e.target.blur()}
                    name="age"
                    onChange={handleChange}
                    value={values?.age}
                    isInvalid={errors?.age ? true : false}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-cardImage">
                    {CardIDimage ? (
                      <>
                        <Image
                          src={url_upload_Profile + CardIDimage}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="cardImage"
                          onChange={handleChangeFileCardImage}
                          id="file-upload-cardImage"
                          hidden
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="cardImage"
                          onChange={handleChangeFileCardImage}
                          id="file-upload-cardImage"
                          hidden
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບໃບຂັບຂີ່</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກທີໃບຂັບຂີ່</Form.Label>
                  <Form.Control type="text" name="passID" placeholder="ກະລຸນາປ້ອນເລກໃບຂັບຂີ່" onChange={handleChange} value={values?.passID} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເບີໂທ</Form.Label>
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    onWheel={(e: any) => e.target.blur()}
                    placeholder="ກະລຸນາປ້ອນເບີໂທ"
                    onChange={handleChange}
                    value={values?.phoneNumber}
                    isInvalid={errors?.phoneNumber ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ລະຫັດຜ່ານ</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    placeholder="ລະຫັດຜ່ານ"
                    onChange={handleChange}
                    value={values?.password}
                    isInvalid={errors?.password ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ສິດການນຳໃຊ້</Form.Label>
                  <Form.Select name="role" onChange={handleChange} value={values?.role} isInvalid={errors?.role ? true : false}>
                    <option value="">-- ເລືອກ --</option>
                    <option value="SUPER_ADMIN">ຜູ້ດູແລລະບົບ</option>
                    <option value="DRIVER">ໂຊເຟີ້(ບໍລິສັດ)</option>
                    <option value="DRIVER_OWN">ໂຊເຟີ້ສ່ວນບຸກຄົນ</option>
                    <option value="USERS">ຜູ້ໃຊ້</option>
                    <option value="AUDITOR">ຜູ້ກວດສອບ</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-passImage">
                    {PassImage ? (
                      <>
                        <Image
                          src={url_upload_Profile + PassImage}
                          style={{
                            height: 120,
                            width: 120,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                        />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="passImage"
                          onChange={handleChangeFilePassImage}
                          hidden
                          id="file-upload-passImage"
                        />
                      </>
                    ) : (
                      <>
                        <FcAddImage size={120} style={{ cursor: "pointer" }} />
                        <Form.Control
                          type="file"
                          accept="image/*"
                          name="passImage"
                          onChange={handleChangeFilePassImage}
                          hidden
                          id="file-upload-passImage"
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດຮູບບັດປະຈຳຕົວ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກບັດປະຈຳຕົວ</Form.Label>
                  <Form.Control
                    type="number"
                    name="cardID"
                    placeholder="ກະລຸນາປ້ອນເລກບັດປະຈຳຕົວ"
                    onChange={handleChange}
                    value={values?.cardID}
                    onWheel={(e: any) => e.target.blur()}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ວັນທີເລີ່ມ</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    onChange={handleChange}
                    value={values?.startDate}
                    isInvalid={errors?.startDate ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກສູນ</Form.Label>
                  <SearchCenter
                    value={values?.center}
                    onChange={(res: any) => {
                      setFieldValue("center", res?.value);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">{String(errors?.center)}</Form.Control.Feedback>
                <Form.Group className="mb-3">
                  <Form.Label>ເລືອກບໍລິສັດ</Form.Label>
                  <SearchDriverCompany
                    value={values?.stStatus}
                    onChange={(res: any) => {
                      setFieldValue("stStatus", res?.value);
                    }}
                  />
                </Form.Group>
                <Form.Control.Feedback type="invalid">{String(errors?.stStatus)}</Form.Control.Feedback>
              </div>
              <div className="col-sm-3">
                <div className="text-center">
                  <label htmlFor="file-upload-docFile">
                    {userFile ? (
                      <>
                        <FcImageFile size={95} style={{ cursor: "pointer" }} />
                        <div>{userFile}</div>
                        <Form.Control
                          type="file"
                          accept="application/pdf,application/vnd.ms-excel"
                          className="form-control"
                          name="docFile"
                          onChange={handleChangeUserFile}
                          hidden
                          id="file-upload-docFile"
                        />
                      </>
                    ) : (
                      <>
                        <FcImageFile size={120} style={{ cursor: "pointer" }} />
                        <Form.Control
                          type="file"
                          accept="application/pdf,application/vnd.ms-excel"
                          className="form-control"
                          name="docFile"
                          onChange={handleChangeUserFile}
                          hidden
                          id="file-upload-docFile"
                        />
                      </>
                    )}
                  </label>
                  <br />
                  <h5 className="text-center mt-2">ອັບໂຫຼດເອກະສານ</h5>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>ເງິນເດືອນພື້ນຖານ(ສະເພາະໂຊເຟີ້ບໍລິສັດ)</Form.Label>
                  <Form.Control
                    type="number"
                    name="basicSalary"
                    onChange={handleChange}
                    value={values?.basicSalary}
                    onWheel={(e: any) => e.target.blur()}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ຊື່ບັນຊີທະນາຄານ(ກີບ)</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_bank_name"
                    placeholder="ຊື່ບັນຊີທະນາຄານ"
                    onChange={handleChange}
                    value={values?.account_bank_name}
                    isInvalid={errors?.account_bank_name ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ເລກບັນຊີທະນາຄານ(ກີບ)</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_bank_number"
                    placeholder="ເລກບັນຊີທະນາຄານ"
                    onChange={handleChange}
                    value={values?.account_bank_number}
                    isInvalid={errors?.account_bank_number ? true : false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>ທີ່ຢູ່</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="ກະລຸນາປ້ອນທີ່ຢູ່"
                    onChange={handleChange}
                    value={values?.address}
                    isInvalid={errors?.address ? true : false}
                    as="textarea"
                    rows={5}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
};

export const Delete = ({ _id, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_USERS);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  _id: _id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

