import React, { useState, useEffect } from "react";

import icon3 from "../../img/GGGGGG.png";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { Image, Spinner } from "react-bootstrap";

export default function CountAccepted({ eDay, sDay }: any) {
  const [countAccepted, setCountAccepted] = useState(0);
  const [query_count_accepted, { data, loading }] = useLazyQuery(Schema.QUERY_COUNT_STATUS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const where = {
      acceptStatus: "ACCEPTED",
      newLocation: "YES",
      isConsolidated: 0,
      createdDateBetween: [sDay, eDay],
    };

    query_count_accepted({
      variables: {
        where,
      },
    });
  }, [query_count_accepted, eDay, sDay]);

  useEffect(() => {
    setCountAccepted(data?.shippingRoutes?.total || 0);
  }, [data]);

  return (
    <>
      <div className=" header_top row col-md-4 shadow card dash">
        <Image className="header_img col-md-2 p-2 " src={icon3} />

        <div className=" header_box d-flex flex-column justify-content-between flex-grow-1  ">
          <span>ຮອດປາຍທາງແລ້ວ</span>
          <span>{loading ? <Spinner animation="border" variant="#EA3F30" size="sm" /> : countAccepted} ຄັນ</span>
        </div>
      </div>
    </>
  );
}
