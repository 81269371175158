import React from "react";
import { Badge, Button, Spinner, Stack } from "react-bootstrap";
import { currency } from "../../utils";

export default function TotalSummary({
  loading,
  TotalDrivers,
  TotalVehicles,
  totalBalance,
  totalPetroleum,
  consolidateTotalRoute,
  SumTotalAmountRoutes,
  extraPayment,
  SumTotalSalary,
  SumDeductionTotal,
  servicesMoney,
  servicesMoneyTotal,
  FinalServicesTotal,
}: any) {
  return (
    <React.Fragment>
      <Stack direction="horizontal" gap={3}>
        <Button variant="primary disable-cursor">
          ລວມນ້ຳມັນທັງໝົດ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(totalPetroleum)} ລິດ
          </Badge>
        </Button>
        <Button variant="primary disable-cursor">
          ຈຳນວນລົດ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(TotalVehicles)} ຄັນ
          </Badge>
        </Button>
        <Button variant="primary disable-cursor">
          ຈຳນວນໂຊເຟີ້:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(TotalDrivers)} ຄົນ
          </Badge>
        </Button>
        <Button variant="primary disable-cursor">
          ຈຳນວນຖ້ຽວ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(consolidateTotalRoute)} ຖ້ຽວ
          </Badge>
        </Button>
        <Button variant="primary disable-cursor">
          ລວມມູນຄ່າຖ້ຽວ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(SumTotalAmountRoutes)} ກີບ
          </Badge>
        </Button>
        <Button variant="primary disable-cursor">
          ເງິນເດືອນລວມ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(SumTotalSalary)} ກີບ
          </Badge>
        </Button>
      </Stack>
      <br />
      <Stack direction="horizontal" gap={2}>
        <Button variant="info disable-cursor">
          ເງິນເພີ່ມລວມ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(extraPayment)} ກີບ
          </Badge>
        </Button>
        <Button variant="danger disable-cursor">
          ເງິນຫັກລວມ:{" "}
          <Badge bg="light" text="dark">
            {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(SumDeductionTotal)} ກີບ
          </Badge>
        </Button>
        {servicesMoney > 0 ? (
          <>
            <Button variant="primary disable-cursor">
              ຄ່າບໍລິຫານ:{" "}
              <Badge bg="light" text="dark">
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : Number(servicesMoneyTotal) || 0} ກີບ
              </Badge>
            </Button>
          </>
        ) : (
          <>
            <Button variant="primary disable-cursor">
              ຄ່າບໍລິຫານ:{" "}
              <Badge bg="light" text="dark">
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : 0} ກີບ
              </Badge>
            </Button>
          </>
        )}
        {servicesMoney > 0 ? (
          <>
            <Button variant="success disable-cursor">
              ເງິນລວມສຸດທິ:{" "}
              <Badge bg="light" text="dark">
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(FinalServicesTotal)} ກີບ
              </Badge>
            </Button>
          </>
        ) : (
          <>
            <Button variant="success disable-cursor">
              ເງິນລວມສຸດທິ:{" "}
              <Badge bg="light" text="dark">
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : currency(totalBalance)} ກີບ
              </Badge>
            </Button>
          </>
        )}
      </Stack>
    </React.Fragment>
  );
}
