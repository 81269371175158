import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Form, Pagination, Spinner, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { currency } from "..";

type TableDataProps = {
  containerClass?: string;
  header: JSX.Element;
  body: JSX.Element;
  onSearchText?: (val?: string) => void;
  total?: number;
  pageNumber?: number;
  numberRow?: number;
  onPageChange?: (val: number) => void;
  loading?: boolean;
  hideSelectRow?: boolean;
  hideSearchText?: boolean;
  totalOnly?: boolean;
  searchPlaceholder?: string;
  selectOption?: string;
  selectCar?: string;
  selectCheck?: string;
};

type Page = {
  page: number;
  ellipsis: boolean;
};

const TableData = forwardRef<HTMLTableElement, TableDataProps>(
  (
    {
      containerClass,
      header,
      body,
      onSearchText,
      total,
      pageNumber,
      numberRow,
      onPageChange,
      loading,
      hideSelectRow,
      hideSearchText,
      totalOnly,
      searchPlaceholder,
    },
    ref
  ) => {
    const [pages, setPages] = useState<Page[]>([]);
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const inputRef = useRef<HTMLInputElement>(null);

    // onSearch
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const trs = document.querySelectorAll("#table-data-component tbody tr");
      if (trs?.length) {
        if (value) {
          trs.forEach((elm) => {
            if (!elm.textContent?.includes(value)) {
              elm.classList.add("d-none");
            } else {
              elm.classList.remove("d-none");
            }
          });
        } else {
          trs.forEach((elm) => {
            elm.classList.remove("d-none");
          });
        }
      }
    };

    // count pages
    useEffect(() => {
      const countPages: number[] = [];
      const x = (total ? total : 0) / (numberRow ? numberRow : 0);
      if (isNaN(x) || x === Infinity) return;

      for (let i = 1; i <= Math.ceil(x); i++) {
        countPages.push(i);
      }
      if (countPages?.length <= 10) {
        const arrays: Page[] = [];
        for (const page of countPages) {
          arrays.push({ page: page, ellipsis: false });
        }
        setPages(arrays);
        return;
      }
      if (countPages?.length > 10) {
        const _pageNumber = pageNumber ? pageNumber : 0;
        const indexOf = countPages?.indexOf(_pageNumber);
        const arrays: Page[] = [];
        let loopPrev: number = 4;
        let loopNext: number = 4;

        // before
        if (_pageNumber === countPages[countPages?.length - 1]) {
          loopPrev = 7;
        } else if (_pageNumber === countPages[countPages?.length - 2]) {
          loopPrev = 6;
        } else if (_pageNumber === countPages[countPages?.length - 3]) {
          loopPrev = 5;
        }

        for (let i = indexOf - 1; i > indexOf - loopPrev; i--) {
          if (countPages[i]) {
            arrays.push({ page: countPages[i], ellipsis: false });
          }
        }

        // after
        if (pageNumber === 1) {
          loopNext = 7;
        } else if (pageNumber === 2) {
          loopNext = 6;
        } else if (pageNumber === 3) {
          loopNext = 5;
        }

        for (let i = indexOf; i < indexOf + loopNext; i++) {
          if (countPages[i]) {
            arrays.push({ page: countPages[i], ellipsis: false });
          }
        }

        const orderBy = _.orderBy(arrays, "page", ["asc"]);
        setPages(orderBy);
        setFirstPage(countPages[0]);
        setLastPage(countPages[countPages?.length - 1]);
      }
    }, [total, numberRow, pageNumber]);

    return (
      <>
        <div className={containerClass}>
          <div className="d-block d-md-flex align-items-center mb-3">
            <div>
              {totalOnly ? (
                <span>ທັງໝົດ {currency(total)} ລາຍການ</span>
              ) : (
                <span>
                  ສະແດງ {currency((numberRow || 0) * (pageNumber || 0) - (numberRow || 0) + (total || 0) ? 1 : 0)} -{" "}
                  {currency((numberRow || 0) * (pageNumber || 0))} ຈາກ {currency(total)} ລາຍການ
                </span>
              )}{" "}
              {loading && <Spinner animation="border" variant="danger" size="sm" />}
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                gap: "0.2rem",
              }}
            >
              {!hideSearchText ? (
                <div className="input-search-container">
                  <Form.Control
                    type="text"
                    placeholder={searchPlaceholder ? searchPlaceholder : "ຄົ້ນຫາ..."}
                    style={{ width: 190 }}
                    onChange={handleSearch}
                    ref={inputRef}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        onSearchText?.(e.currentTarget.value);
                      }
                    }}
                  />
                  <div
                    className="btn-search"
                    onClick={() => {
                      const value = inputRef.current?.value;
                      onSearchText?.(value);
                    }}
                  >
                    <i className="fas fa-search m-0" />
                  </div>
                </div>
              ) : null}
              {!hideSelectRow ? (
                <Form.Select
                  style={{ width: 140 }}
                  onChange={(e) => {
                    const value = e.target.value;
                    searchParams.set("row", value);
                    setSearchParams(searchParams);
                  }}
                  value={String(numberRow)}
                >
                  <option value="">ສະແດງຈຳນວນ</option>
                  <option value="20">20 ລາຍການ</option>
                  <option value="50">50 ລາຍການ</option>
                  <option value="100">100 ລາຍການ</option>
                  <option value="200">200 ລາຍການ</option>
                  <option value="300">300 ລາຍການ</option>
                  <option value="400">400 ລາຍການ</option>
                  <option value="500">500 ລາຍການ</option>
                  <option value="1000">1,000 ລາຍການ</option>
                </Form.Select>
              ) : null}
            </div>
          </div>
          <div className="table-responsive mb-2 data-table-responsive">
            <Table striped bordered hover id="table-data-component" size="lg" ref={ref}>
              <thead>{header}</thead>
              <tbody>{body}</tbody>
            </Table>
          </div>
        </div>
        <div className="card-footer border-top pb-0" style={{ height: 40 }}>
          {pages?.length > 1 && (
            <Pagination className="justify-content-center">
              <Pagination.First
                onClick={() => {
                  if (onPageChange) {
                    onPageChange?.(firstPage);
                  } else {
                    searchParams.set("page", String(firstPage));
                    setSearchParams(searchParams);
                  }
                }}
              />
              <Pagination.Prev
                onClick={() => {
                  const prevPage = (pageNumber ? pageNumber : 1) - 1;
                  if (prevPage < 1) return;
                  if (onPageChange) {
                    onPageChange?.(prevPage);
                  } else {
                    searchParams.set("page", String(prevPage));
                    setSearchParams(searchParams);
                  }
                }}
              />
              {pages?.map((item, index) => (
                <React.Fragment key={index}>
                  {!item?.ellipsis && (
                    <Pagination.Item
                      active={pageNumber === item?.page}
                      onClick={() => {
                        if (onPageChange) {
                          onPageChange?.(item?.page);
                        } else {
                          searchParams.set("page", String(item?.page));
                          setSearchParams(searchParams);
                        }
                      }}
                    >
                      {item?.page}
                    </Pagination.Item>
                  )}
                  {item?.ellipsis && <Pagination.Ellipsis />}
                </React.Fragment>
              ))}
              <Pagination.Next
                onClick={() => {
                  const nextPage = (pageNumber ? pageNumber : 1) + 1;
                  if (nextPage > pages[pages?.length - 1]?.page) {
                    return;
                  }
                  if (onPageChange) {
                    onPageChange?.(nextPage);
                  } else {
                    searchParams.set("page", String(nextPage));
                    setSearchParams(searchParams);
                  }
                }}
              />
              <Pagination.Last
                onClick={() => {
                  if (onPageChange) {
                    onPageChange?.(lastPage);
                  } else {
                    searchParams.set("page", String(lastPage));
                    setSearchParams(searchParams);
                  }
                }}
              />
            </Pagination>
          )}
        </div>
      </>
    );
  }
);

export default TableData;
