import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { toDayDate } from "..";
type DateRangeProps = {
  hideStartDate?: boolean;
  hideEndDate?: boolean;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  isToday?: boolean;
};
export default function SearchDateRange({ hideStartDate, hideEndDate, containerClass, containerStyle, isToday }: DateRangeProps) {
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const _start = searchParams.get("startDate");
    const _end = searchParams.get("endDate");
    const _isToday = isToday ? toDayDate("YYYY-MM-DD") : "";
    setDefaultStartDate(_start ? _start : _isToday);
    setDefaultEndDate(_end ? _end : _isToday);
  }, [searchParams, isToday]);

  return (
    <div className={`search-date-range ${containerClass}`} style={containerStyle}>
      <i className="fas fa-search" />
      ຄົ້ນຫາຂໍ້ມູນຈາກວັນທີ
      <div className="input-group">
        {!hideStartDate && <Form.Control type="date" value={defaultStartDate} onChange={(e) => setDefaultStartDate(e.target.value)} />}
        {!hideEndDate && <Form.Control type="date" value={defaultEndDate} onChange={(e) => setDefaultEndDate(e.target.value)} />}
        <Button
          disabled={!defaultStartDate || !defaultEndDate}
          onClick={() => {
            if (searchParams.get("page")) {
              searchParams.set("page", "1");
            }
            searchParams.set("startDate", defaultStartDate);
            searchParams.set("endDate", defaultEndDate);
            setSearchParams(searchParams);
          }}
        >
          <i className="fas fa-search" />
          ຄົ້ນຫາ
        </Button>
      </div>
    </div>
  );
}
