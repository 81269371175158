import React, { useEffect, useState } from "react";
import { ChildPath, ParentPath } from "../../../../routes/Path";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Gender, RootVariable, currency, formatDateTime } from "../../../../utils";
import Schema from "../Schema";
import TableData from "../../../../utils/components/TableData";
import SearchRouteLocation from "../../../../utils/components/SelectRouteLocation";

export default function HistoryComponent() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [getRoute, setRoute] = useState<any>();
  const [parans, setParans] = useSearchParams();
  const getShow = parans.get("show");

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_ROUTE_LOCATION_LOG, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    FetchData({
      variables: {
        where: {
          routeID: parseInt(getRoute) ? parseInt(getRoute) : undefined,
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, searchValue, getRoute]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.routeLocationLogs?.data || []);
    setTotal(data?.routeLocationLogs?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);
  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          {getShow ? (
            <li
              className="breadcrumb-item"
              onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.destinationVehicle}`)}
              style={{ cursor: "pointer" }}
            >
              ຈັດການສາຍຂົນສົ່ງ
            </li>
          ) : (
            <li
              className="breadcrumb-item"
              onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.modifyDestinationVehicle}`)}
              style={{ cursor: "pointer" }}
            >
              ແກ້ໄຂລາຄາສາຍຂົນສົ່ງ
            </li>
          )}
          <li className="breadcrumb-item">ປະຫວັດການແກ້ໄຂລາຄາສາຍ</li>
        </ol>
      </div>
      <div className="main-container">
        <div className="card">
          <div className="card-header border-bottom">
            <h3>ປະຫວັດການແກ້ໄຂລາຄາສາຍ</h3>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="col-md-4">
                  <label>ຄົ້ນຫາຕາມສາຍລົດ</label>
                  <SearchRouteLocation
                    value={getRoute}
                    onChange={(res: any) => {
                      setRoute(res?.value);
                    }}
                  />
                </div>
                <br />
                <br />

                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ຊື່ສາຍ</th>
                      <th className="text-nowrap text-center">ລະຫັດ</th>
                      <th className="text-nowrap text-center">ພາກ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວ(ພາກເຊົ້າ)(ເກົ່າ)ກີບ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວ(ພາກເຊົ້າ)(ໃໝ່)ກີບ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວ(ພາກແລງ)(ເກົ່າ)ກີບ</th>
                      <th className="text-nowrap text-center">ລາຄາຕໍ່ຖ້ຽວ(ພາກແລງ)(ໃໝ່)ກີບ</th>
                      <th className="text-nowrap text-center">ຜູ້ແກ້ໄຂ</th>
                      <th className="text-nowrap text-center">ວັນທີແກ້ໄຂ</th>
                      <th className="text-nowrap text-center">ລາຍລະອຽດ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr className="tbody" key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-nowrap">{value?.routeID?.title || "----"}</td>
                          <td className="text-nowrap">{value?.code || "----"}</td>
                          <td className="text-nowrap">{value?.routeID?.lg_isDay === 1 ? "ພາກເຊົ້າ" : "ພາກແລງ" || "-----"}</td>
                          <td className="text-nowrap ">{currency(value?.priceDayOld || 0)} ກີບ</td>
                          <td className="text-nowrap">{currency(value?.priceDayNew || 0)} ກີບ</td>
                          <td className="text-nowrap">{currency(value?.priceNightOld || 0)} ກີບ</td>
                          <td className="text-nowrap">{currency(value?.priceNightNew || 0)} ກີບ</td>
                          <td className="text-nowrap">
                            {value?.changeBy?.gender ? Gender(value?.changeBy?.gender) : ""} {value?.changeBy?.first_name || "----"}{" "}
                            {value?.changeBy?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{value?.changeDate ? formatDateTime(value?.changeDate) : "-----"}</td>
                          <td className="text-nowrap">{value?.detail || "----"}</td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
