import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Select, { StylesConfig } from "react-select";
import Schema from "./Schema";
import { Gender } from "..";

type SelectDriverProps = {
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  disabled?: boolean;
  value?: number;
  onChange?: (e: { value: number; label: string }) => void;
  styleConfig?: StylesConfig;
};

type Options = {
  value: number;
  label: string;
};

export default function SearchDriverVehicleConsolidate({ disabled, value, onChange, styleConfig }: SelectDriverProps) {
  const [selectedDriver, setSelectDriver] = useState<Options | null>(null);
  const [items, setItems] = useState<any>([]);
  const [fetchData, { data, loading }] = useLazyQuery(Schema.SELECT_USER_DRIVER, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (disabled) return;
    fetchData({
      variables: {
        where: {
          // role: "DRIVER",
        },
        limit: 600,
      },
    });
  }, [fetchData, disabled]);

  useEffect(() => {
    if (!data?.users?.data?.length) {
      setItems([]);
      return;
    }
    const newData = data?.users?.data?.map((item: any) => {
      return { ...item, value: item?._id, label: Gender(item?.gender) + " " + item?.first_name + " " + item?.last_name };
    });
    setItems(newData);
  }, [data]);

  //set value
  useEffect(() => {
    if (value) {
      const result = items?.filter((item: any) => item?._id === value);
      setSelectDriver(result[0] || null);
    } else {
      setSelectDriver({ value: 0, label: "ທັງໝົດ" });
    }
  }, [items, value]);

  const handleChange = (option: Options | null) => {
    setSelectDriver(option);
    if (option) {
      onChange?.(option as { value: number; label: string });
    } else {
      onChange?.({ value: 0, label: "ທັງໝົດ" });
    }
  };

  return (
    <div>
      <Select
        isDisabled={disabled}
        value={selectedDriver}
        placeholder={loading ? "ກຳລັງໂຫຼດ..." : "ເລືອກໂຊເຟີ້ລົດ"}
        isLoading={loading}
        onChange={(option) => handleChange(option as Options)}
        options={[{ value: 0, label: "ເລືອກຄ່າເລີ່ມຕົ້ນ" }, ...items]}
        styles={styleConfig}
      />
    </div>
  );
}
