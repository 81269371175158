import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_CONTAIN_VEHICLE = gql`
  query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
    vehicles(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        _id
        vehicleName
        VehicleType {
          _id
          name
        }
        brand
        colour
        power
        machineNumber
        tankNumber
        sign
        isPublic
        isDeleted
        insurance
        insuranceOver
        dateIncome
        status
        audit
        isRent
        driver {
          _id
          first_name
          last_name
          gender
        }
        driver_two {
          _id
          first_name
          last_name
          gender
        }
        createdBy {
          _id
          first_name
          last_name
        }
        center {
          st_id
          centerName
        }
        routeID {
          _id
          title
        }
        note
        runStatus
        createdAt
      }
    }
  }
  `;

  static UPDATE_DRIVER_TO_VEHICLE = gql`
    mutation UpdateVehicle($where: WhereById!, $data: vehicleInput) {
      updateVehicle(where: $where, data: $data) {
        status
        data
      }
    }
  `;

  static query_user_details = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          first_name
          last_name
          gender
          age
          phoneNumber
          passID
          cardID
          address
          password
          role
          status
          startDate
          endDate
          image
          createdAt
          createdBy {
            _id
            first_name
            last_name
            gender
          }
          cardImage
          passImage
          docFile
          account_bank_name
          account_bank_number
          basicSalary
          dateConsolidate
          center {
            st_id
            centerName
          }
          stStatus {
            cp_id
            cp_name
          }
        }
      }
    }
  `;
}
