import React, { useState, useEffect } from "react";
import { RootVariable, checkUserRole, currency } from "../../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import TableData from "../../../utils/components/TableData";
import Schema from "./Schema";
import SearchRouteLocation from "../../../utils/components/SelectRouteLocation";
import { ChildPath, ParentPath } from "../../../routes/Path";
import {
  Create_destinationVehicles,
  Delete_destinationVehicles,
  ExportTable_destinationVehicles,
  ListBranches_destinationVehicles,
  ListItemsVehicle_destinationVehicles,
  Update_destinationVehicles,
  UpdateStatus_destinationVehicles,
} from "./component";
export default function DestinationVehicle() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams, setSearchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [RouteLocation, setRouteLocation] = useState<any>();
  const [SelectPublic, setSelectPublic] = useState<any>();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_ROUTES_LOCATION, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          _id: RouteLocation ? parseInt(RouteLocation) : undefined,
          isPublic: SelectPublic ? parseInt(SelectPublic) : undefined,
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue, RouteLocation, SelectPublic]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.routeLocations?.data || []);
    setTotal(data?.routeLocations?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _routeLocation = searchParams.get("routeLocation");
    const _public = searchParams.get("public");

    const decodePublic = _public ? atob(_public) : undefined;
    const decodeRouteLocation = _routeLocation ? atob(_routeLocation) : "";
    setSelectPublic(decodePublic ? decodePublic : undefined);
    setRouteLocation(decodeRouteLocation ? decodeRouteLocation : "");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item active">ຈັດການສາຍຂົນສົ່ງ</li>
        </ol>
      </div>
      <div className="main-container mt-4">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-8">
                <h3>ຈັດການສາຍຂົນສົ່ງ</h3>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN"]) ? (
                    <>
                      <ExportTable_destinationVehicles data={data} loading={loading} />
                      <Create_destinationVehicles onSuccess={() => setReloading(!reloading)} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <label>ເລືອກສາຍຂົນສົ່ງ</label>
                    <SearchRouteLocation
                      onChange={(option: any) => {
                        searchParams.set("routeLocation", btoa(option?.value));
                        setSearchParams(searchParams);
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>ຄົ້ນຫາຕາມການປິດເປີດ</label>
                    <select
                      className="form-control"
                      onChange={(e: any) => {
                        searchParams.set("public", btoa(e?.target?.value));
                        setSearchParams(searchParams);
                      }}
                    >
                      <option value="1">ເປີດ</option>
                      <option value="0">ປິດ</option>
                    </select>
                  </div>
                </div>
                &nbsp;&nbsp;
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ຊື່ສາຍ | ປິດ/ເປີດ | ແກ້ໄຂ | ລົບ | ປະຫວັດແກ້ໄຂ | ລາຄາຖ້ຽວ</th>
                      <th className="text-nowrap text-center">ລະຫັດ/ອັກສອນຫຍໍ້</th>
                      <th className="text-nowrap text-center">ພາກ/ປະເພດສາຍ</th>
                      <th className="text-nowrap text-center">ໄລຍະທາງ/ນ້ຳມັນ</th>
                      <th className="text-nowrap text-center">ສະຖານະສາຍ</th>
                      <th className="text-nowrap text-center">ລາຍລະອຽດ</th>
                      <th className="text-nowrap text-center">ລົດໃນສາຍ</th>
                      <th className="text-nowrap text-center">ສາຂາໃນສາຍ</th>
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="text-center ">{index + 1}</td>
                          <td className="text-nowrap">
                            <div className="d-flex justify-content-between">
                              <div>{value?.title || "---"}</div>

                              <div className="btn-group">
                                {checkUserRole(["SUPER_ADMIN"]) ? (
                                  <>
                                    <UpdateStatus_destinationVehicles item={value} onSuccess={() => setReloading(!reloading)} />
                                    {value?.isPublic === 1 ? (
                                      <Update_destinationVehicles item={value} onSuccess={() => setReloading(!reloading)} />
                                    ) : null}
                                    <Delete_destinationVehicles id={value?._id} onSuccess={() => setReloading(!reloading)} />
                                  </>
                                ) : null}
                                <button
                                  type="button"
                                  className="btn btn-sm btn-default float-right"
                                  onClick={() =>
                                    navigate(`${ParentPath.transportationVehicle}/${ChildPath.HistoryComponent}?&&show="transportatoion"`)
                                  }
                                >
                                  <i className="fas fa-list"></i>
                                </button>
                              </div>
                            </div>
                            <hr />
                            <h6>ລາຄາຕໍ່ຖ້ຽວ(ພາກເຊົ້າ): {currency(value?.priceDay || 0)} ກີບ</h6>
                            <h6>ລາຄາຕໍ່ຖ້ຽວ(ພາກແລງ): {currency(value?.priceNight || 0)} ກີບ</h6>
                          </td>
                          <td className="text-nowrap text-center">
                            <div className="mt-2"> {value?.code || "----"}</div>
                            <hr />
                            <div>{value?.locationCode || 0}</div>
                          </td>
                          <td className="text-nowrap text-center">
                            <div className="mt-2">{value?.lg_isDay === "0" ? "ພາກເຊົ້າ" : "ພາກແລງ"}</div>
                            <hr />
                            <div>{value?.checkRoute === "IN" ? <p>ສາຍໃນ</p> : value?.checkRoute === "OUT" ? <p>ສາຍນອກ</p> : <p>ສາຍສຳຮອງ</p>}</div>
                          </td>
                          <td className="text-center text-nowrap">
                            <div className="mt-2">{currency(value?.km || 0)} ກິໂລແມັດ</div>
                            <hr />
                            <div>{currency(value?.petroleum || 0)} ລິດ</div>
                          </td>
                          <td className="text-center text-nowrap">
                            <div className="mt-2">{value?.isAuction === 1 ? "ສາຍປະມູນ" : "ສາຍທົ່ວໄປ"}</div>
                            <hr />
                            <div>{value?.inSide === 0 ? "ສາຍພາຍໃນແຂວງ" : "ສາຍຕ່າງແຂວງ"}</div>
                          </td>
                          <td style={{ width: 300 }}>{value?.note || "----"}</td>
                          <td className="text-center text-nowrap">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                navigate(
                                  `${ParentPath.RecordVehicle}?routeID=${value?._id}&&title=${value?.title}&&CheckVehicle=${value?.routeID?.vehicleID}`
                                )
                              }
                            >
                              <i className="fas fa-add"></i>ເພີ່ມລົດເຂົ້າໃນສາຍ
                            </button>
                            <br />
                            <br />
                            <ListItemsVehicle_destinationVehicles routeID={value?._id} onSuccess={() => setReloading(!reloading)} />
                          </td>
                          <td className="text-center text-nowrap">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() =>
                                navigate(`${ParentPath.RecordBranches}?routeID=${value?._id}&&title=${value?.title}&&isDay=${value?.lg_isDay}`)
                              }
                            >
                              <i className="fas fa-add"></i>ເພີ່ມສາຂາເຂົ້າໃນສາຍ
                            </button>
                            <br />
                            <br />
                            <td className="text-start text-nowrap">
                              <ListBranches_destinationVehicles routeID={value?._id} onSuccess={() => setReloading(!reloading)} />
                            </td>
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
