import React, { useEffect, useState } from "react";
import SearchRouteLocation from "../../../utils/components/SelectRouteLocation";
import { InvalidText, MessageErrors, MessageSuccess, currency, getStaffLogin, notifyError, notifySuccess } from "../../../utils";
import Schema from "./Schema";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import Notiflix from "notiflix";

// update price wires

export const FormLayout = ({ onSuccess }: { onSuccess: () => void }) => {
  const [loading, setLoading] = useState(false);
  const [newPrice, setNewPrice] = useState<any>();
  const [routeID, setRouteID] = useState();
  const [FetchData, { data }] = useLazyQuery(Schema.QUERY_ITEM_MODIFY_PRICE);
  const [UpdatePrice] = useMutation(Schema.UPDATE_PRICE_ONE);
  useEffect(() => {
    if (!routeID) return;
    FetchData({
      variables: {
        where: {
          _id: routeID ? parseInt(routeID) : undefined,
        },
      },
    });
  }, [routeID, loading]);

  const value = data?.routeLocations?.data[0];
  const OldPriceDay = value?.priceDay;
  const OldPriceNight = value?.priceNight;
  let isDay = value?.lg_isDay;
  let oldPrice = 0;
  if (Number(isDay) === 0) {
    oldPrice = OldPriceDay;
  } else {
    oldPrice = OldPriceNight;
  }

  const Submit = async () => {
    try {
      await UpdatePrice({
        variables: {
          data: {
            priceNew: Number(newPrice),
          },
          where: {
            _id: value?._id,
          },
        },
      });
      notifySuccess(MessageSuccess.update);
      setNewPrice(0);
      setLoading(true);
      setTimeout(() => {
        onSuccess?.();
      }, 1200);
    } catch (error) {
      notifyError(MessageErrors.update);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>ເລືອກສາຍຂົ່ນສົ່ງ</label>
          <SearchRouteLocation
            onChange={(option: any) => {
              setRouteID(option?.value);
            }}
          />
        </div>
        <div className="col-md-3">
          <label>ລາຄາເກົ່າ</label>
          <input className="form-control" type="text" name="priceDay" value={currency(oldPrice)} readOnly placeholder="0" />
        </div>
        <div className="col-md-3">
          <label>ລາຄາໃໝ່</label>
          <input
            className="form-control"
            type="number"
            name="priceNew"
            placeholder="0"
            onWheel={(e: any) => e.target.blur()}
            onChange={(e: any) => setNewPrice(e.target.value)}
            value={newPrice}
          />
        </div>
      </div>
      <div className="text-right text-nowrap mt-4">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!newPrice || !routeID || getStaffLogin()?.role !== "SUPER_ADMIN"}
          onClick={Submit}
        >
          <i className="fas fa-save"></i> ບັນທຶກ
        </button>
      </div>
    </>
  );
};

// update all prices

type TypeValue = {
  note: string;
};

type FormLayoutProp = {
  values: TypeValue;
  handleChange: any;
  setFieldValue: any;
};

const FormUpdateLayout = ({ values, handleChange, setFieldValue }: FormLayoutProp) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>ເຫດຜົນ: {InvalidText()}</Form.Label>
        <Form.Control
          type="text"
          name="note"
          as="textarea"
          rows={4}
          placeholder="ເຫດຜົນ....................................."
          onChange={handleChange}
          value={values?.note}
        />
      </Form.Group>
    </>
  );
};

// update component
export const Updated = ({ onSuccess, disabled, item }: { onSuccess: () => void; disabled?: boolean; item: any }) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_ROUTES_LOCATION_MANY);

  // useFormik
  const initialValues = {
    note: "",
    lg_isDay: item?.lg_isDay,
    priceDay: item?.lg_isDay === "0" ? item?.priceNew : item?.priceDay || 0,
    priceNight: item?.lg_isDay === "1" ? item?.priceNew : item?.priceNight || 0,
  };
  const { values, handleChange, submitForm, setSubmitting, resetForm, setFieldValue } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        let dataToSubmit = { ...values };

        if (item?.lg_isDay === "0") {
          dataToSubmit.priceDay = item?.priceNew;
        } else if (item?.lg_isDay === "1") {
          dataToSubmit.priceNight = item?.priceNew;
        }
        await updateData({
          variables: {
            data: dataToSubmit,
            where: {
              ids: item?.map((value: any) => value._id),
            },
          },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
        Notiflix.Loading.remove();
      }
    },
  });
  return (
    <>
      {!disabled ? (
        <button type="button" className="btn btn-success" onClick={() => setShow(true)} disabled>
          <i className="fas fa-check-circle"></i> ອັບເດບທັງໝົດ
        </button>
      ) : (
        <button type="button" className="btn btn-success" onClick={() => setShow(true)}>
          <i className="fas fa-check-circle"></i> ອັບເດບທັງໝົດ
        </button>
      )}

      <Modal show={show} size="lg">
        <Modal.Header onHide={() => setShow(false)} closeButton={!loading}>
          <Modal.Title>
            <i className="fas fa-add"></i> ເຫດຜົນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormUpdateLayout values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!values?.note || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            {loading ? <> ກຳລັງອັບເດບລາຄາທັງໝົດ.....</> : <> ສົ່ງຟອມ</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const UpdatedOnlyOne = ({ onSuccess, disabled, item }: { onSuccess: () => void; disabled?: boolean; item: any }) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading: updatingData }] = useMutation(Schema.UPDATE_PRICE_DAY);
  const [updateRouteLocation, { loading: updatingRoute }] = useMutation(Schema.UPDATE_PRICE_DAY);

  // useFormik
  const initialValues = {
    note: "",
    lg_isDay: item?.lg_isDay,
    priceDay: item?.lg_isDay === "0" ? item?.priceNew : item?.priceDay || 0,
    priceNight: item?.lg_isDay === "1" ? item?.priceNew : item?.priceNight || 0,
  };
  const { values, handleChange, submitForm, setSubmitting, resetForm, setFieldValue } = useFormik({
    initialValues: initialValues,

    onSubmit: async (values) => {
      try {
        let dataToSubmit = { ...values };

        if (item?.lg_isDay === "0") {
          dataToSubmit.priceDay = item?.priceNew;
        } else if (item?.lg_isDay === "1") {
          dataToSubmit.priceNight = item?.priceNew;
        }
        await updateData({
          variables: {
            data: dataToSubmit,
            where: {
              _id: item?._id,
            },
          },
        });

        await updateRouteLocation({
          variables: {
            data: {
              priceNew: 0,
            },
            where: {
              _id: item?._id,
            },
          },
        });

        Notiflix.Loading.remove();
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
        Notiflix.Loading.remove();
      }
    },
  });
  return (
    <>
      {!disabled ? (
        <button type="button" className="btn btn-success" onClick={() => setShow(true)} disabled>
          <i className="fas fa-check-circle"></i> ອັບເດບ
        </button>
      ) : (
        <button type="button" className="btn btn-success" onClick={() => setShow(true)}>
          <i className="fas fa-check-circle"></i> ອັບເດບ
        </button>
      )}

      <Modal show={show} size="lg">
        <Modal.Header onHide={() => setShow(false)} closeButton={!updatingData && !updatingRoute}>
          <Modal.Title>
            <i className="fas fa-add"></i> ເຫດຜົນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormUpdateLayout values={values} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!values?.note || updatingData || updatingRoute} onClick={submitForm}>
            {updatingData || updatingRoute ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            {updatingData || updatingRoute ? <> ກຳລັງອັບເດບລາຄາທັງໝົດ.....</> : <> ສົ່ງຟອມ</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


