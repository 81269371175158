import React, { useEffect, useState } from "react";
import icon1 from "../../img/EEEEEE.png";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { Image, Spinner } from "react-bootstrap";

export default function CountUnaccepted({ eDay , sDay }: any) {
  const [countUnaccepted, setCountUnaccepted] = useState(0);
  const [query_count_unaccepted, { data, loading }] = useLazyQuery(Schema.QUERY_COUNT_STATUS, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    const where = {
      acceptStatus: "UNACCEPTED",
      newLocation: "NO",
      createdDateBetween: [sDay, eDay],
    };
    query_count_unaccepted({
      variables: {
        where,
      },
    });
  }, [query_count_unaccepted, eDay,sDay]);

  useEffect(() => {
    setCountUnaccepted(data?.shippingRoutes?.total || 0);
  }, [data]);
  return (
    <>
      <div className="header_top row col-md-4 shadow card">
        <Image className="header_img col-3 col-md-2 p-2" src={icon1} /> 
        <div className="header_box d-flex flex-column justify-content-between flex-grow-1">
          <span>ລໍຖ້າລົດອອກ</span>
          <span>{loading ? <Spinner animation="border" variant="#EA3F30" size="sm" /> : countUnaccepted} ຄັນ</span>
        </div>
      </div>
    </>
  );
}
