import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { InvalidText, MessageSuccess, notifyError, notifySuccess } from "../../../utils";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";

export const UPDATE_PRICE_ROUTE = ({ value, onSuccess }: { value: any; onSuccess: () => void; className?: string }) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>(value?.difNote);
  const [amountValue, setAmountValue] = useState<any>(value?.amountValue);
  const [UPDATE, { loading }] = useMutation(Schema.UPDATE_ALL);

  const HandleUpdate = async () => {
    try {
      await UPDATE({
        variables: {
          data: {
            amountValue: Number(amountValue),
            difNote: String(note),
          },
          where: {
            sp_id: value?.sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.update);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageSuccess.update);
    }
  };

  return (
    <>
      <button className="btn btn-primary" onClick={() => setShow(true)}>
        <FaEdit />
      </button>
      <Modal show={show}>
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-edit"></i>ແກ້ໄຂລາຄາຖ້ຽວ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>ລາຄາຖ້ຽວ{InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ລາຄາຖ້ຽວໃໝ່"
              value={amountValue}
              name="amountValue"
              onChange={(e: any) => {
                setAmountValue(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເຫດຜົນ{InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ເຫດຜົນທີ່ເເກ້ໄຂຂໍ້ມູນ"
              as="textarea"
              name="note"
              rows={3}
              value={note}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={HandleUpdate} disabled={loading}>
            <i className="fas fa-save"></i> ສົ່ງຟອມ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const UPDATE_PRICE_SHIPPING_ALL = ({ items, onSuccess, className }: { items: any; onSuccess: () => void; className?: string }) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>();
  const [amountValue, setAmountValue] = useState<any>();
  const [UPDATE, { loading }] = useMutation(Schema.UPDATE_ALL_PRICE_SHIPPING_ROUTE);

  const HandleUpdateAll = async () => {
    try {
      await UPDATE({
        variables: {
          data: {
            amountValue: Number(amountValue),
            difNote: String(note),
          },
          where: {
            ids: items?.map((value: any) => value?.sp_id),
          },
        },
      });
      notifySuccess(MessageSuccess.update);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageSuccess.update);
    }
  };

  return (
    <>
      <button className="btn btn-success me-2" onClick={() => setShow(true)}>
        <i className="fas fa-edit"></i>ແກ້ໄຂທັງໝົດ
      </button>
      <Modal show={show}>
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-edit"></i>ແກ້ໄຂລາຄາຖ້ຽວ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>ລາຄາຖ້ຽວ{InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ລາຄາຖ້ຽວໃໝ່"
              value={amountValue}
              name="amountValue"
              onChange={(e: any) => {
                setAmountValue(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເຫດຜົນ{InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ເຫດຜົນທີ່ເເກ້ໄຂຂໍ້ມູນ"
              as="textarea"
              name="note"
              rows={3}
              value={note}
              onChange={(e: any) => {
                setNote(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={HandleUpdateAll} disabled={loading || !note || !amountValue}>
            {loading ? <><i className="fas fa-save"></i> ກຳລັງອັບເດບລາຄາທັງໝົດ.....</> : <> <i className="fas fa-save"></i>ສົ່ງຟອມ</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


