import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../utils";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";

// verify paidStatus
export const Paid = ({
  consolidateID,
  className,
  onSuccess,
  disabled,
}: {
  consolidateID: number;
  className?: string;
  onSuccess: () => void;
  disabled: boolean;
}) => {
  const [PaidData, { loading }] = useMutation(Schema.VERIFY_PAID_PAYMENTS);
  const [consolidateStatus, setConsolidateStatus] = useState<string | null>(null);
  const useID = getStaffLogin()?._id;
  return (
    <React.Fragment>
      <Button
        style={{ marginTop: 25 }}
        variant="success"
        className={className}
        disabled={disabled}
        onClick={() => {
          confirmShow(MessageConfirms.Paid, async () => {
            try {
              await PaidData({
                variables: {
                  data: {
                    consolidatePaidStatus: "PAID",
                    paidBy: Number(useID),
                  },
                  where: {
                    consolidateID: Number(consolidateID),
                  },
                },
              });
              setConsolidateStatus("PAID");
              localStorage.setItem(`consolidateStatus-${consolidateID}`, "PAID");
              notifySuccess(MessageSuccess.Paid);
              onSuccess?.();
            } catch (error) {
              notifyError(MessageErrors.Paid);
            }
          });
        }}
      >
        <>
          {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle" />}
          ຢືນຢັນຊຳລະ
        </>
      </Button>
    </React.Fragment>
  );
};
// cancel verify Status

export const DeleteBack = ({
  className,
  onSuccess,
  disabled,
  items,
}: {
  items: any;
  className?: string;
  onSuccess: () => void;
  disabled: boolean;
}) => {
  const [BackData, { loading }] = useMutation(Schema.UPDATE_CANCEL_CONSOLIDATE_ROUTE);

  let basicSalaryOneConsol: any = "";
  if (items?.basicSalaryOne > 0) {
    basicSalaryOneConsol = 1;
  } else {
    basicSalaryOneConsol = 0;
  }
  let basicSalaryTwoConsol: any = "";
  if (items?.basicSalaryTwo > 0) {
    basicSalaryTwoConsol = 1;
  } else {
    basicSalaryTwoConsol = 0;
  }
  return (
    <React.Fragment>
      <Button
        variant="danger"
        className={className}
        style={{ borderRadius: "5px" }}
        disabled={disabled}
        onClick={() => {
          confirmShow(MessageConfirms.back, async () => {
            try {
              await BackData({
                variables: {
                  data: {
                    driverConfirmed: 0,
                    isDay: String(items?.isDay),
                    consolidateVehicleID: Number(items?.consolidateVehicleID?._id),
                    isDif: String(items?.isDif),
                    checkRoute: String(items?.checkRoute),
                    consolidateDriverID: Number(items?.consolidateDriverID?._id),
                    driverTwo: Number(items?.driverTwo?._id),
                    basicSalaryOne: Number(basicSalaryOneConsol),
                    basicSalaryTwo: Number(basicSalaryTwoConsol),
                  },
                  where: {
                    consolidateID: Number(items?.consolidateID),
                  },
                },
              });
              notifySuccess(MessageSuccess.back);
              onSuccess?.();
            } catch (error) {
              notifyError(MessageErrors.back);
            }
          });
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash" />}
      </Button>
    </React.Fragment>
  );
};

// verify All paidStatus
export const PaidTotal = ({ consolidateID, className, onSuccess }: { consolidateID: any; className?: string; onSuccess: () => void }) => {
  const [PaidDataTotal, { loading }] = useMutation(Schema.VERIFY_PAID_PAYMENTS_ALL);
  const useID = getStaffLogin();
  return (
    <React.Fragment>
      <Button
        variant="success"
        className={className}
        disabled={loading}
        onClick={() => {
          confirmShow(MessageConfirms.verify_all, async () => {
            try {
              await PaidDataTotal({
                variables: {
                  data: {
                    paidBy: Number(useID?._id),
                  },
                  where: {
                    ids: consolidateID?.map((value: any) => value?.consolidateID),
                  },
                },
              });
              notifySuccess(MessageSuccess.Paid);
              onSuccess?.();
            } catch (error) {
              notifyError(MessageErrors.Paid);
            }
          });
        }}
      >
        {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle" />}&nbsp;
        {loading ? <>ກຳລັງຢືນຢັນຊຳລະທັງໝົດ.....</> : <>ຢືນຢັນຊຳລະທັງໝົດ</>}
      </Button>
    </React.Fragment>
  );
};
