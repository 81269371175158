import { gql } from "@apollo/client";

export default class Schema {
  static SELECT_CENTER = gql`
    query SeparationCenters($where: SeparationCenterWhereInput, $limit: Int, $skip: Int) {
      separationCenters(where: $where, limit: $limit, skip: $skip) {
        data {
          st_id
          centerName
        }
      }
    }
  `;
  static SELECT_TYPE_VEHICLE = gql`
    query TypeVehicles($where: typeVehicleWhereInput, $limit: Int, $skip: Int) {
      typeVehicles(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          name
        }
      }
    }
  `;

  static SELECT_USER_DRIVER = gql`
    query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
      users(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          first_name
          last_name
          gender
          stStatus {
            cp_id
          }
        }
      }
    }
  `;

  static SELECT_NUMBER_VEHICLE = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          vehicleName
        }
      }
    }
  `;

  static SELECT_ROUTE_LOCATION_ID = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        data {
          _id
          lg_isDay
        }
      }
    }
  `;

  static SELECT_ROUTE_LOCATION_TITLE = gql`
    query RouteLocations($where: routeLocationInput, $limit: Int, $skip: Int) {
      routeLocations(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          _id
          lg_isDay
          title
          priceDay
          priceNight
          checkRoute
          code
          petroleum
          inSide
          isPublic
        }
      }
    }
  `;

  static SELECT_ROUTE_LOCATION = gql`
    query RouteLocations($where: routeLocationInput, $skip: Int, $limit: Int) {
      routeLocations(where: $where, skip: $skip, limit: $limit) {
        total
        data {
          _id
          title
          code
        }
      }
    }
  `;

  static QUERY_DRIVER = gql`
    query DriverPartners($where: driverPartnerInput, $limit: Int, $skip: Int) {
      driverPartners(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          cp_id
          cp_name
        }
      }
    }
  `;

  static QUERY_VEHICLES = gql`
    query Vehicles($where: vehicleWhereInput, $limit: Int, $skip: Int) {
      vehicles(where: $where, limit: $limit, skip: $skip) {
        total
        data {
          vehicleName
          _id
        }
      }
    }
  `;
}
