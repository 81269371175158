import { useEffect, useState } from "react";
import { InvalidText, MessageConfirms, MessageErrors, MessageSuccess, confirmShow, getStaffLogin, notifyError, notifySuccess } from "../../../utils";
import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { IoMdAdd } from "react-icons/io";
import Schema from "./Schema";

// verify component
type VerifyProps = {
  data: any;
  onSuccess: () => void;
  className?: string;
};

export const Verified = ({ data, onSuccess, className }: VerifyProps) => {
  const [updateData, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_NEW_LOCATION);
  const getUserLogin = getStaffLogin();

  const handleUpdate = async () => {
    confirmShow(MessageConfirms.confirmCheckDriver, async () => {
      try {
        await updateData({
          variables: {
            where: {
              sp_id: data?.sp_id,
            },
            data: {
              newLocation: "YES",
              acceptArrivedBy: getUserLogin?._id,
            },
          },
        });

        notifySuccess(MessageSuccess.updateNewLocation);
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.updateNewLocation);
      }
    });
  };
  return (
    <Button variant="success" className={className} disabled={loading || data?.newLocation === "YES"} onClick={handleUpdate}>
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
    </Button>
  );
};

// update shipping_route component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};

export const UpdateRoute = ({ item, className, onSuccess }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [note, setNote] = useState<any>(item?.difNote);
  const [DeleteShippingRoute, { loading }] = useMutation(Schema.UPDATE_SHIPPING_ROUTE_DELETE);
  const HandleSubmit = async () => {
    try {
      await DeleteShippingRoute({
        variables: {
          data: {
            difNote: note,
          },
          where: {
            sp_id: item?.sp_id,
          },
        },
      });
      notifySuccess(MessageSuccess.delete);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageSuccess.delete);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-danger" onClick={() => setShow(true)}>
        <i className="fa fa-trash" />
      </button>
      <Modal show={show}>
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>
            <i className="fas fa-add"></i>ເຫດຜົນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group className="mb-3">
              <Form.Label>ເຫດຜົນທີ່ຕ້ອງການລົບຖ້ຽວນີ້{InvalidText()}</Form.Label>
              <Form.Control name="difNote" as="textarea" rows={3} onChange={(e: any) => setNote(e.target.value)} value={note} />
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!note || loading} onClick={HandleSubmit}>
            <i className="fas fa-check-circle m-0" />
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type UpdateLocationProps = {
  values: {
    sp_id?: number;
  };
  onSuccess: () => void;
  className?: string;
};

interface BranchItem {
  _id: number;
  branch_id: {
    id_branch: number;
    branch_name: string | null;
  };
  locationID: {
    _id: number;
    title: string | null;
  };
  isDay: number;
  service_type: string;
}

export const UpdateBranchInLocation = ({ values, onSuccess, className }: UpdateLocationProps) => {
  const [show, setShow] = useState(false);
  const [isDays, setIsDays] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [item, setItem] = useState<BranchItem[]>([]);

  const [QUERY_LOCATION_BRANCH, { data }] = useLazyQuery(Schema.QUERY_LOCATION_BRANCH, { fetchPolicy: "cache-and-network" });
  const [CREATE_BRANCH_IN_SHIPPING_ROUTE] = useMutation(Schema.CREATE_BRANCH_IN_SHIPPING_ROUTE);

  useEffect(() => {
    QUERY_LOCATION_BRANCH({
      variables: {
        where: {
          isDay: isDays ? parseInt(isDays) : undefined,
          service_type: "CLOSE",
        },
        limit: 500,
      },
    });
  }, [QUERY_LOCATION_BRANCH, isDays]);

  useEffect(() => {
    setItem(data?.locationGroupBranches?.data || []);
  }, [data]);

  const filteredItems = item.filter(
    (value) =>
      (value.branch_id.branch_name?.toLowerCase() || "").includes(searchQuery.toLowerCase()) ||
      (value.locationID.title?.toLowerCase() || "").includes(searchQuery.toLowerCase())
  );
  const createHandler = async (values: any, branchItem: BranchItem) => {
    try {
      await CREATE_BRANCH_IN_SHIPPING_ROUTE({
        variables: {
          data: {
            _id: branchItem?._id,
            branch_id: branchItem?.branch_id?.id_branch,
            is_day: branchItem?.isDay,
            route_id: branchItem?.locationID?._id,
            service: "OPEN",
            sping_route: values?.sp_id,
            isDayShipping: String(values?.isDay),
          },
        },
      });

      QUERY_LOCATION_BRANCH({
        variables: {
          where: {
            isDay: isDays ? parseInt(isDays) : undefined,
            service_type: "CLOSE",
          },
          limit: 500,
        },
      });

      notifySuccess(MessageSuccess.updateAddBranch);
      onSuccess?.();
      setShow(false);
    } catch (error) {
      notifyError(MessageErrors.updateAddBranch);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-success" onClick={() => setShow(true)}>
        <IoMdAdd /> ເພີ່ມສາຂາ
      </button>
      <Modal show={show} centered size="xl">
        <Modal.Header onHide={() => setShow(false)} closeButton>
          <Modal.Title>ເພີ່ມສາຂາເຂົ້າລົດເສີມ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Label>ຊື່ສາຂາ ຫຼື ຊື່ສາຍຂົນສົ່ງ</Form.Label>
                <Form.Group>
                  <Form.Control
                    type="text"
                    placeholder="ຄົ້ນຫາຊື່ສາຂາ ຫຼື ຊື່ສາຍຂົນສົ່ງ"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Label>ພາກ</Form.Label>
                <Form.Group>
                  <Form.Select aria-label="Select option" value={isDays} onChange={(e) => setIsDays(e.target.value)}>
                    <option value="">ຄ່າເລີ່ມຕົ້ນ</option>
                    <option value="0">ພາກເຊົ້າ</option>
                    <option value="1">ພາກແລງ</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ຊື່ສາຂາ</th>
                  <th>ຊື່ສາຍຂົນສົ່ງ</th>
                  <th className="text-nowrap">ພາກສາຍຂົນສົ່ງ</th>
                  <th className="text-nowrap text-center">ຈັດການ</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems?.map((value: any, index: number) => (
                  <>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{value?.branch_id?.branch_name || "---"}</td>
                      <td>{value?.locationID?.title || "---"}</td>
                      <td className="text-nowrap" style={{ color: value?.isDay === 1 ? "red" : "green" }}>
                        {value?.isDay === 1 ? "ພາກແລງ" : " ພາກເຊົ້າ"}
                      </td>
                      <td className="text-nowrap text-center">
                        <Button variant="success" size="sm" onClick={() => createHandler(values, value)}>
                          <IoMdAdd /> ເພີ່ມ
                        </Button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
