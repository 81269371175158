import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RootVariable, checkUserRole, formatDateTime } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import TableData from "../../../utils/components/TableData";
import { Create, Delete, ListVehicle, Update } from "./component";
export default function CentralSeparation() {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_CENTER, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    FetchData({
      variables: {
        where: {
          isPublic: 1,
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.separationCenters?.data || []);
    setTotal(data?.separationCenters?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item active">ຈັດການຂໍ້ມູນສູນຄັດແຍກ</li>
        </ol>
      </div>
      <div className="main-container mt-3">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-8">
                {" "}
                <h4>ລາຍຊື່ສູນຄັດແຍກ</h4>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN", "PC", "AUDITOR"]) ? (
                    <>
                      <Create onSuccess={() => setReloading(!reloading)} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog">
              <div className="col-md-12 p-2">
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap text-center">ລຳດັບ</th>
                      <th className="text-nowrap text-center">ຊື່ສູນ</th>
                      <th className="text-nowrap text-center">ອັກສອນຫຍໍ້</th>
                      <th className="text-nowrap text-center">ລົດທີ່ບັນຈຸໃນສູນ</th>
                      <th className="text-nowrap text-center">ພາກ</th>
                      <th className="text-nowrap text-center">ວັນທີສ້າງ</th>
                      <th className="text-nowrap text-center">ລາຍລະອຽດ</th>
                      {checkUserRole(["SUPER_ADMIN"]) ? <th className="text-nowrap text-center">ຈັດການ</th> : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">{value?.centerName || "---"}</td>
                          <td className="text-nowrap">{value?.centerCode || "---"}</td>
                          <td className="text-nowrap">
                            <ListVehicle vehicle={value?.st_id} />
                          </td>
                          {value?.position === "NORTHERN" ? (
                            <td className="text-center text-nowrap">{value?.position === "NORTHERN" ? "ພາກເໜືອ" : ""}</td>
                          ) : value?.position === "CENTRAL" ? (
                            <td className="text-center text-nowrap">{value?.position === "CENTRAL" ? "ພາກກາງ" : ""}</td>
                          ) : (
                            <td className="text-center text-nowrap">{value?.position === "SOUTHERN" ? "ພາກໃຕ້" : ""}</td>
                          )}

                          <td className="text-nowrap text-center">{value?.createdAt ? formatDateTime(value?.createdAt) : "----"}</td>
                          <td className="text-nowrap">{value?.note || "----"}</td>
                          {checkUserRole(["SUPER_ADMIN"]) ? (
                            <td className="text-nowrap text-center">
                              <div className="btn-group float-right">
                                <Update item={value} onSuccess={() => setReloading(!reloading)} />
                                <Delete st_id={value?.st_id} onSuccess={() => setReloading(!reloading)} />
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
