import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import car from "../../img/icon123.png";
import redCar from "../../assets/images/truckRed.png";
import YellowCar from "../../assets/images/truckYellow.png";
import "./index.scss";
import CountUnaccepted from "./CountUnaccepted";
import CountAccepted from "./CountAccepted";
import CountLoading from "./CountLoading";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { EndOfDay, FormatDateToAM_PM, StartOfDay } from "../../utils";
import SelectDriverRoute from "../../utils/components/SelectDriverRoute";
import SearchDateRangeConsolidateSummary from "../../utils/components/SearchDateRangeConsolidateSummary";
import { useSearchParams } from "react-router-dom";

export default function Home() {
  const [item, setItem] = useState<any[]>();
  const [filter, setFilter] = useState({});
  const [getNumberVehicle, setGetNumberVehicle] = useState<number | undefined>(undefined);
  const [dateBetween, setDateBetween] = useState<string[]>([]);

  const [sDay, setSDay] = useState<string>(StartOfDay());
  const [eDay, setEDay] = useState<string>(EndOfDay());
  const [searchParams, setSearchParams] = useSearchParams();

  const [query_card_list, { data }] = useLazyQuery(Schema.QUERY_CARD, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const vehicleID = getNumberVehicle === 0 ? undefined : Number(getNumberVehicle);
    query_card_list({
      variables: {
        where: {
          ...filter,
          vehicleID: vehicleID ? vehicleID : undefined,
          createdDateBetween: [sDay, eDay],
        },
      },
    });
  }, [query_card_list, filter, getNumberVehicle, dateBetween, sDay, eDay]);

  useEffect(() => {
    if (data) {
    setItem(data?.shippingRoutes?.data || []);
    }
  }, [data]);

  const handleFilterClick = () => {
    setFilter({
      ...filter,
      acceptStatus: "UNACCEPTED",
      newLocation: "NO",
    });
  };
  const handleFilterClick2 = () => {
    setFilter({
      ...filter,
      acceptStatus: "ACCEPTED",
      newLocation: "NO",
    });
  };

  const handleFilterClick3 = () => {
    setFilter({
      ...filter,
      newLocation: "YES",
      isConsolidated: 0,
    });
  };

  const handleFilterClickAll = () => {
    setFilter({});
  };

  useEffect(() => {
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");
    const _vehicleID = searchParams.get("vehicle");

    const decodeVehicle = _vehicleID ? atob(_vehicleID) : undefined;

    setGetNumberVehicle(decodeVehicle ? Number(decodeVehicle) : undefined);

    if (_startDate && _endDate) {
      setSDay(_startDate);
      setEDay(_endDate);
    } else {
      setSDay(StartOfDay());
      setEDay(EndOfDay());
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <h5 className="text-nowrap" style={{ fontWeight: "bold" }}>
              ລາຍງານຂໍ້ມູນລົດ
            </h5>
          </li>
        </ol>
      </div>
      <div className="main_top">
        <CountUnaccepted eDay={eDay} sDay={sDay} />
        <CountLoading eDay={eDay} sDay={sDay} />
        <CountAccepted eDay={eDay} sDay={sDay} />
      </div>

      <div className="body_table row p-2 ">
        <div className="body_card shadow card p-3">
          <div className="header-card">
            <div className="head-content row">
              <div className="col-md-4">
                <SearchDateRangeConsolidateSummary eDay sDay />
              </div>

              <div className="col-md-4">
                <label>ຄົ້ນຫາຕາມເບີລົດ</label>
                <SelectDriverRoute
                  onChange={(option: any) => {
                    searchParams.set("vehicle", btoa(option.value));
                    setSearchParams(searchParams);
                  }}
                />
              </div>
              <div className="col-md-4 mt-4 d-flex justify-content-between">
                <button type="button" className="btn btn-info" onClick={handleFilterClickAll}>
                  ທັງໝົດ
                </button>
                <button type="button" className="btn btn-light btn-1 " onClick={handleFilterClick} style={{ width: 130 }}>
                  ລໍຖ້າລົດອອກ
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-light btn-2 " onClick={handleFilterClick2} style={{ width: 130 }}>
                  ກຳລັງແລ່ນ
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-light btn-3 " onClick={handleFilterClick3}>
                  ຮອດປາຍທາງແລ້ວ
                </button>
              </div>
            </div>
          </div>
          <hr />

          <Row>
            {item?.map((value, index) => (
              <Col key={index} xs={12} md={4}>
                <div className="card-detail">
                  <Row>
                    <Col xs={4} className="box-icon">
                      <p className="text-icon">{value?.vehicleID?.vehicleName}</p>
                      {value?.acceptStatus === "UNACCEPTED" && value?.newLocation === "NO" ? (
                        <Image className="icon" src={YellowCar} />
                      ) : value?.acceptStatus === "ACCEPTED" && value?.newLocation === "NO" ? (
                        <Image className="icon" src={redCar} />
                      ) : value?.newLocation === "YES" && value?.acceptStatus === "ACCEPTED" ? (
                        <Image className="icon" src={car} />
                      ) : null}
                    </Col>
                    <Col xs={8} className="box-content">
                      <h5 className="text-content">{value?.routeLocationID?.title}</h5>
                      {value?.acceptStatus === "UNACCEPTED" && value?.newLocation === "NO" ? (
                        <h6 className="text-content-time">{FormatDateToAM_PM(value?.startTime)}</h6>
                      ) : value?.acceptStatus === "ACCEPTED" && value?.newLocation === "NO" ? (
                        <h6 className="text-content-time">{FormatDateToAM_PM(value?.startTime)}</h6>
                      ) : value?.newLocation === "YES" && value?.acceptStatus === "ACCEPTED" ? (
                        <h6 className="text-content-time">
                          {FormatDateToAM_PM(value?.startTime)} ເຖິງ {FormatDateToAM_PM(value?.endTime)}
                        </h6>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div style={{ position: "relative" }}></div>
      </div>
    </React.Fragment>
  );
}
