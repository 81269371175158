import React, { useEffect, useState } from "react";
import TableData from "../../../utils/components/TableData";
import { RootVariable, checkUserRole } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import Schema from "./Schema";
import SearchTypeVehicle from "../../../utils/components/SelectTypeVehicle";
import SearchDriverCompany from "../../../utils/components/SelectDriverCompany";
import VehicleDetailComponent from "./vehicleDetail";
import { ParentPath } from "../../../routes/Path";
import { CreateMenageVehicle, DeleteMenageVehicle, UpdateMenageVehicle } from "./component";

export default function ManageVehicle() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams , setSearchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [getTypeVehicle, setTypeVehicle] = useState<any>();
  const [getDriverCompany, setDriverCompany] = useState<any>();
  const [typeItems, setTypeItems] = useState<any[]>();
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_VEHICLE, { fetchPolicy: "cache-and-network" });
  const [FetchType, { data: results, loading: newLoading }] = useLazyQuery(Schema.QUERY_TYPE_VEHICLE, { fetchPolicy: "cache-and-network" });

  // vehicle type


  useEffect(() => {
    FetchType({
      variables: {
        where: {
          isPublic: 1,
        },
      },
    });
  }, [FetchType]);
  useEffect(() => {
    if (newLoading) {
      setTypeItems([]);
      return;
    }
    setTypeItems(results?.typeVehicles?.data || []);
  }, [newLoading, results]);

 

  useEffect(() => {
    const vehicleID = getTypeVehicle === 0 ? undefined : Number(getTypeVehicle); 
    const driverID = getDriverCompany === 0 ? undefined : Number(getDriverCompany); 
    FetchData({
      variables: {
        where: {
          isDeleted: 0,
          VehicleType: vehicleID ? vehicleID : undefined,
          driver: driverID ? driverID : undefined,
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [FetchData, pageNumber, numberRow, reloading, searchValue, getTypeVehicle, getDriverCompany]);

  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _typeVehicle = searchParams.get("TypeVehicle");
    const _driverCompany = searchParams.get("DriverCompany");

    const decodeTypeVehicle =  _typeVehicle ? _typeVehicle : ""
    const decodeDriverCompany =  _driverCompany ? atob(_driverCompany) : ""

    setDriverCompany(decodeDriverCompany ? decodeDriverCompany : "")
    setTypeVehicle(decodeTypeVehicle ? decodeTypeVehicle : "")
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
  }, [searchParams]);

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ຈັດການຂໍ້ມູນລົດຂົນສົ່ງ</li>
        </ol>
      </div>
      <div className="main-container mt-4">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-8">
                <h4>ລາຍການລົດຂົນສົ່ງທັງໝົດ</h4>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN"]) ? <CreateMenageVehicle onSuccess={() => setReloading(!reloading)} /> : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog">
              <div className="container">
                <div className="row">
                  {typeItems?.map((items, index) => (
                    <div className="col-sm-3" key={index}>
                      <VehicleDetailComponent value={items?._id} name={items?.name} />
                      &nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="row">
                  <div className="col-lg-3">
                    <label>ເລືອກຕາມປະເພດລົດ</label>
                    <SearchTypeVehicle
                      onChange={(option:  any) => {
                        searchParams.set("TypeVehicle", option.value)
                        setSearchParams(searchParams)
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-2">
                    <label>ເລືອກຕາມບໍລິສັດ</label>
                    <SearchDriverCompany
                      onChange={(option : any) => {
                        searchParams.set("DriverCompany", btoa(option.value))
                        setSearchParams(searchParams)
                      }}
                    />
                  </div>
                </div>
              </div>
              <TableData
                total={total}
                loading={loading}
                pageNumber={pageNumber}
                numberRow={numberRow}
                onSearchText={(val) => {
                  setSearchValue(val || "");
                }}
                header={
                  <tr>
                    <th>ລຳດັບ</th>
                    <th className="text-nowrap text-center">ໂຊເຟີ້ລົດ</th>
                    <th className="text-nowrap text-center">ເບີລົດ</th>
                    <th className="text-nowrap text-center">ປະເພດລົດ</th>
                    <th className="text-nowrap text-center">ທະບຽນລົດ</th>
                    <th className="text-nowrap text-center">ບັນຈຸຢູ່ສູນ</th>
                    <th className="text-nowrap text-center">ບູລິມະສິດ</th>
                    <th className="text-nowrap text-center">ສະຖານະລົດ</th>
                    <th className="text-nowrap text-center">ຈັດການ</th>
                  </tr>
                }
                body={
                  <>
                    {items?.map((value, index) => (
                      <tr key={index}>
                        <td className="no">{index + 1}</td>
                        <td className="text-nowrap">
                          {value?.driver?.first_name || "----"}
                          {value?.driver?.last_name || "-----"}
                        </td>
                        <td className="text-center text-nowrap">{value?.vehicleName || "----"}</td>
                        <td className="text-nowrap">{value?.VehicleType?.name || "----"}</td>
                        <td className="text-nowrap">{value?.sign || "----"}</td>
                        <td className="text-nowrap">{value?.center?.centerName || "---"}</td>
                        <td className="text-nowrap text-center">
                          {value?.isRent === 1 ? (
                            <p className="text-nowrap text-success">ລົດຮ່ວມ</p>
                          ) : (
                            <p className="text-nowrap text-danger">ລົດບໍລິສັດ</p>
                          )}
                        </td>
                        <td className="text-nowrap text-center">
                          {value?.isPublic === 1 ? <p className="text-success">ເປີດ</p> : <p className="text-danger">ປິດ</p>}
                        </td>
                        <td className="text-center text-nowrap">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() => navigate(`${ParentPath.DetailDriver}?Driver=${value?._id}&&CheckStatus=information_vehicle`)}
                            >
                              <i className="fas fa-list"></i>
                            </button>
                            {checkUserRole(["SUPER_ADMIN"]) ? (
                              <>
                                <UpdateMenageVehicle item={value} onSuccess={() => setReloading(!reloading)} />
                                <DeleteMenageVehicle _id={value?._id} onSuccess={() => setReloading(!reloading)} />
                              </>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
