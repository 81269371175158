import React, { useState, useEffect, useRef } from "react";
import {
  InvalidText,
  MessageConfirms,
  MessageErrors,
  MessageSuccess,
  confirmShow,
  currency,
  formatDate,
  getStaffLogin,
  notifyError,
  notifySuccess,
} from "../../../utils";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import Schema from "./Schema";
import { BiPrinter } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment, { relativeTimeRounding } from "moment";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/images/ans-logo-red.svg";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

type routeLocationsValue = {
  title: string;
  locationCode: string;
  priceDay: number;
  priceNight: number;
  petroleum: number;
  km: number;
  lg_isDay: number;
  note: string;
  checkRoute: string;
  isAuction: number;
  isPublic: number;
};

type routeLocationsError = {
  title?: string;
  locationCode?: string;
  priceDay?: string;
  priceNight?: string;
  petroleum?: string;
  km?: string;
  lg_isDay?: string;
  isAuction?: string;
};

type FormLayoutProp = {
  values: routeLocationsValue;
  errors: routeLocationsError;
  handleChange: any;
  setFieldValue: any;
};

const FormLayout = ({ values, errors, handleChange, setFieldValue }: FormLayoutProp) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>ຊື່ສາຍຂົນສົ່ງ{InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ຊື່ສາຍຂົນສົ່ງ"
              name="title"
              value={values?.title}
              onChange={handleChange}
              isInvalid={errors?.title ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ອັກສອນຫຍໍ້ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ອັກສອນຫຍໍ້"
              name="locationCode"
              value={values?.locationCode}
              onChange={handleChange}
              isInvalid={errors?.locationCode ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ລາຄາຕໍ່ຖ້ຽວ(ກາງເວັນ): {InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ລາຄາຕໍ່ຖ້ຽວ(ກາງເວັນ)"
              name="priceDay"
              onChange={handleChange}
              value={values?.priceDay}
              isInvalid={errors?.priceDay ? true : false}
              onWheel={(e: any) => e.target.blur()}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ລາຄາຕໍ່ຖ້ຽວ(ກາງຄືນ): {InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ລາຄາຕໍ່ຖ້ຽວ(ກາງຄືນ)"
              onWheel={(e: any) => e.target.blur()}
              name="priceNight"
              value={values?.priceNight}
              onChange={handleChange}
              isInvalid={errors?.priceNight ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ໄລຍະທາງ(km): {InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ໄລຍະທາງ(km)"
              onWheel={(e: any) => e.target.blur()}
              name="km"
              onChange={handleChange}
              value={values?.km}
              isInvalid={errors?.km ? true : false}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>ນ້ຳມັນ(ລິດ): {InvalidText()}</Form.Label>
            <Form.Control
              type="number"
              placeholder="ນ້ຳມັນ(ລິດ)"
              isInvalid={errors?.petroleum ? true : false}
              name="petroleum"
              value={values?.petroleum}
              onChange={handleChange}
              onWheel={(e: any) => e.target.blur()}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເລືອກພາກ</Form.Label>
            <Form.Select name="lg_isDay" onChange={handleChange} value={values?.lg_isDay}>
              <option value="">ເລືອກພາກ</option>
              <option value="0">ພາກເຊົ້າ</option>
              <option value="1">ພາກແລງ</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເລືອກປະເພດສາຍ</Form.Label>
            <Form.Select name="checkRoute" onChange={handleChange} value={values?.checkRoute}>
              <option value="">ເລືອກສາຍ</option>
              <option value="IN">ສາຍໃນ</option>
              <option value="OUT">ສາຍນອກ</option>
              <option value="SPARE">ສາຍສຳຮອງ</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເລືອກສະຖານະສາຍ{InvalidText()}</Form.Label>
            <select value={values?.isAuction} onChange={(e: any) => setFieldValue("isAuction", e.target.value)} className="form-control">
              <option value="">ເລືອກສະຖານະສາຍ</option>
              <option value="0">ສາຍທົ່ວໄປ</option>
              <option value="1">ສາຍປະມູນ</option>
            </select>
            <div className="text-danger">{errors?.isAuction}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ລາຍລະອຽດ</Form.Label>
            <Form.Control type="text" placeholder="ລາຍລະອຽດ" as="textarea" rows={5} name="note" value={values?.note} onChange={handleChange} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const initialValues: routeLocationsValue = {
  title: "",
  locationCode: "",
  priceDay: 0,
  priceNight: 0,
  petroleum: 0,
  km: 0,
  lg_isDay: 0,
  note: "",
  checkRoute: "",
  isAuction: 0,
  isPublic: 1,
};
const validate = (values: routeLocationsValue) => {
  const errors: routeLocationsError = {};
  if (!values?.title) errors.title = "ກະລຸນາປ້ອນສາຍຂົ່ນສົ່ງ";
  if (!values?.locationCode) errors.locationCode = "ກະລຸນາປ້ອນອັກສອນຫຍໍ້";
  if (!values?.priceDay) errors.priceDay = "ກະລຸນາປ້ອນລາຄາກາງເວັນ";
  if (!values?.priceNight) errors.priceNight = "ກະລຸນາປ້ອນລາຄາກາງຄືນ";
  if (!values?.petroleum) errors.petroleum = "ກະລຸນາປ້ອນຈຳນວນລິດ";
  if (!values?.km) errors.km = "ກະລຸນາປ້ອນໄລຍະທາງ";
  if (!values?.isAuction) errors.isAuction = "ກະລຸນາເລືອກສາຍ";
  return errors;
};

// create component
export const Create_destinationVehicles = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_ROUTES_LOCATION);

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<routeLocationsValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values) => {
      try {
        const newData = {
          ...values,
          lg_isDay: String(values?.lg_isDay),
          isAuction: Number(values?.isAuction),
          isPublic: 1,
        };
        await createData({
          variables: {
            data: newData,
          },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add"></i> ເພີ່ມສາຍຂົນສົ່ງ
      </Button>
      <Modal show={show} size="lg">
        <Modal.Header closeButton={!loading} onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-add"></i> ຟອມບັນທຶກສາຍຂົນສົ່ງ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  id: number;
  onSuccess: () => void;
  className?: string;
};

export const Delete_destinationVehicles = ({ id, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_ROUTES_LOCATION);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                where: {
                  _id: id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};
export const Update_destinationVehicles = ({ item, onSuccess, className }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_ROUTES_LOCATION);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<routeLocationsValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: {
            data: {
              ...values,
              lg_isDay: values?.lg_isDay,
              isAuction: Number(values?.isAuction),
            },
            where: {
              _id: item?._id,
            },
          },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });

  useEffect(() => {
    if (!show) return;
    setValues({
      title: item?.title || "",
      locationCode: item?.locationCode || "",
      priceDay: item?.priceDay || 0,
      priceNight: item?.priceNight || 0,
      petroleum: item?.petroleum || 0,
      km: item?.km || 0,
      lg_isDay: item?.lg_isDay || 0,
      note: item?.note || "",
      checkRoute: item?.checkRoute || "",
      isAuction: item?.isAuction,
      isPublic: 1,
    });
  }, [show, item, setValues]);
  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-edit" />
            ຟອມແກ້ໄຂສາຍຂົນສົ່ງ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// update status routeLocation

type UpdateStatusProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};

export const UpdateStatus_destinationVehicles = ({ item, onSuccess, className }: UpdateStatusProps) => {
  const [updateData, { loading }] = useMutation(Schema.UPDATE_STATUS_ROUTE);
  const isPublicStatus = item?.isPublic === 1;

  // Define messages based on isPublic status
  const confirmMessage = isPublicStatus ? MessageConfirms.closedRouteLocation : MessageConfirms.openRouteLocation;
  const successMessage = isPublicStatus ? MessageSuccess.closedRouteLocation : MessageSuccess.openRouteLocation;
  const errorsMessage = isPublicStatus ? MessageErrors.closedRouteLocation : MessageErrors.openRouteLocation;

  return (
    <Button
      variant="success"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(confirmMessage, async () => {
          try {
            let newData = {};

            if (item?.isPublic === 1) {
              newData = {
                isPublic: 0,
              };
            } else if (item?.isPublic === 0) {
              newData = {
                isPublic: 1,
              };
            }
            await updateData({
              variables: {
                data: newData,
                where: {
                  _id: item?._id,
                },
              },
            });
            if (item?.isPublic === 1) {
            }
            notifySuccess(successMessage);
            onSuccess?.();
          } catch (error) {
            notifyError(errorsMessage);
          }
        });
      }}
    >
      {loading ? (
        <Spinner animation="border" variant="white" size="sm" />
      ) : item?.isPublic === 1 ? (
        <i className="fas fa-check-circle m-0" />
      ) : (
        <i className="fas fa-window-close m-0" />
      )}
    </Button>
  );
};

// list VEHICLE and delete VEHICLE

type itemVehicles = {
  routeID: any;
  className?: string;
  onSuccess: () => void;
};

export const ListItemsVehicle_destinationVehicles = ({ routeID, className, onSuccess }: itemVehicles) => {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any>([]);
  const [FetchVehicle, { data, loading }] = useLazyQuery(Schema.QUERY_LIST_VEHICLE, { fetchPolicy: "cache-and-network" });
  const [updateData, { loading: newLoading }] = useMutation(Schema.UPDATE_VEHICLE);

  useEffect(() => {
    FetchVehicle({
      variables: {
        where: {
          routeID: routeID,
          isPublic: 1,
        },
      },
    });
  }, [FetchVehicle]);
  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.vehicles?.data || []);
    setTotal(data?.vehicles?.total || 0);
  }, [data, loading]);

  return (
    <React.Fragment>
      {items?.map((value: any, index: number) => (
        <>
          <button type="button" className="btn btn-light btn-sm">
            {index + 1}
          </button>
          <button type="button" className="btn btn-default btn-sm green-background">
            {value?.vehicleName}
          </button>
          <Button
            variant="light"
            className={className}
            size="sm"
            disabled={loading || getStaffLogin()?.role !== "SUPER_ADMIN"}
            onClick={() => {
              confirmShow(MessageConfirms.delete, async () => {
                try {
                  await updateData({
                    variables: {
                      data: {
                        routeID: 0,
                      },
                      where: {
                        _id: value?._id,
                      },
                    },
                  });
                  notifySuccess(MessageSuccess.delete);
                  onSuccess?.();
                } catch (error) {
                  notifyError(MessageErrors.delete);
                }
              });
            }}
          >
            {newLoading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-close m-0" />}
          </Button>
          <br />
        </>
      ))}
      <hr />
      <div>ທັງໝົດ {total} ຄັນ</div>
    </React.Fragment>
  );
};

// list BRANCH

type ItemBranch = {
  routeID: any;
  className?: string;
  onSuccess: () => void;
};

export const ListBranches_destinationVehicles = ({ routeID, className, onSuccess }: ItemBranch) => {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any>([]);
  const [FetchBranches, { data, loading }] = useLazyQuery(Schema.QUERY_BRANCH_GROUP, { fetchPolicy: "cache-and-network" });
  const [DeleteBranch, { loading: newLoading }] = useMutation(Schema.DELETE_BRANCH_GROUP);

  useEffect(() => {
    FetchBranches({
      variables: {
        where: {
          locationID: routeID,
        },
      },
    });
  }, [FetchBranches]);
  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.locationGroupBranches?.data || []);
    setTotal(data?.locationGroupBranches?.total || 0);
  }, [data, loading]);

  return (
    <React.Fragment>
      {items?.map((value: any, index: number) => (
        <>
          <button type="button" className="btn btn-light btn-sm">
            {index + 1}
          </button>
          <button type="button" className="btn btn-default btn-sm">
            {value?.branch_id?.branch_name || "---"}
          </button>
          <Button
            variant="light"
            className={className}
            size="sm"
            disabled={loading || getStaffLogin()?.role !== "SUPER_ADMIN"}
            onClick={() => {
              confirmShow(MessageConfirms.delete, async () => {
                try {
                  await DeleteBranch({
                    variables: {
                      where: {
                        _id: value?._id,
                      },
                    },
                  });
                  notifySuccess(MessageSuccess.delete);
                  onSuccess?.();
                } catch (error) {
                  notifyError(MessageErrors.delete);
                }
              });
            }}
          >
            {newLoading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-close m-0" />}
          </Button>
          <br />
        </>
      ))}
      <hr />
      <div>ທັງໝົດ {total} ສາຂາ</div>
    </React.Fragment>
  );
};

// add branches to route Location

type AddBranchesToRouteLocationProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
  BranchID: any;
  // disabled: any;
  isDay: any;
};

export const AddingBranchesToRouteLocationProps_destinationVehicles = ({
  _id,
  onSuccess,
  className,
  BranchID,
  isDay,
}: // disabled,
AddBranchesToRouteLocationProps) => {
  const [updateData, { loading }] = useMutation(Schema.CREATE_BRANCH_LOCATION);
  const getDay = new Date();
  const today = formatDate(getDay);
  return (
    <Button
      variant="success"
      className={className}
      // disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.addBranches, async () => {
          try {
            await updateData({
              variables: {
                data: {
                  branch_id: Number(_id),
                  locationID: Number(BranchID),
                  isDay: Number(isDay),
                  createdAt: today,
                },
              },
            });
            notifySuccess(MessageSuccess.addBranches);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.addBranches);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-add m-0" />} ເພີ່ມ
    </Button>
  );
};

// add vehicle to RouteLocation

type AddVehicleToRouteLocationProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
  vehicleID: any;
  disabled: any;
};

// add vehicle to location

export const AddingVehicleRouteLocation_destinationVehicles = ({
  _id,
  onSuccess,
  className,
  vehicleID,
  disabled,
}: AddVehicleToRouteLocationProps) => {
  const [updateData, { loading }] = useMutation(Schema.UPDATE_VEHICLE_LOCATION);
  return (
    <Button
      variant="success"
      className={className}
      disabled={disabled}
      onClick={() => {
        confirmShow(MessageConfirms.addVehicleTORouteLocation, async () => {
          try {
            await updateData({
              variables: {
                data: {
                  routeID: Number(vehicleID),
                },
                where: {
                  _id: _id,
                },
              },
            });
            notifySuccess(MessageSuccess.addVehicleTORouteLocation);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.addVehicleTORouteLocation);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-add m-0" />} ເພີ່ມ
    </Button>
  );
};

// Export html table to excel

export const ExportTable_destinationVehicles = ({ data, loading }: any) => {
  const [show, setShow] = useState(false);
  const elmRef = useRef<HTMLDivElement>(null);
  let today = moment().format("YYYY-MM-DD");
  let time = moment().format("HH:mm:ss");
  const getLogin = getStaffLogin();
  const handlePrint = useReactToPrint({
    content: () => elmRef.current,
  });
  const [items, setItems] = useState<any>([]);
  useEffect(() => {
    if (!show) return;
    setItems(data?.routeLocations?.data || []);
  }, [data, show]);
  return (
    <>
      <button
        type="button"
        className="btn btn-secondary  me-2 shadow-none"
        onClick={() => setShow(true)}
        style={{ position: "relative", top: "0px" }}
        disabled={loading}
      >
        <BiPrinter className="me-2" />
        ພິມລາຍງານ
      </button>

      <Modal show={show} animation={false} dialogClassName="modal-width-95">
        <Modal.Header closeButton onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-truck"></i> ຂໍ້ມູນລາຍງານສາຍຂົນສົ່ງ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 flex-control-group">
            <button onClick={handlePrint} type="button" className="btn btn-lg btn-dark ml-3 me-2">
              <BiPrinter /> ພິມລາຍງານ
            </button>
            <DownloadTableExcel filename="ຂໍ້ມູນລາຍງານສາຍຂົນສົ່ງ" sheet="ຂໍ້ມູນລາຍງານສາຍຂົນສົ່ງ" currentTableRef={elmRef.current}>
              <button className="btn btn-lg btn-success">
                <BsFileEarmarkExcel /> Export excel{" "}
              </button>
            </DownloadTableExcel>
          </div>
          &nbsp;&nbsp;
          <div ref={elmRef} id="table-to-xls" className="container-print" style={{ fontFamily: "phetsarath OT" }}>
            <div className="text-center fw-bold" style={{ fontSize: "1.2rem" }}>
              ຂໍ້ມູນລາຍງານສາຍຂົນສົ່ງ
            </div>
            <div>
              <img style={{ width: 150 }} src={Logo} alt="" />
            </div>
            &nbsp;
            <div className="justify-content-between">
              <div>
                <div>ບໍລິສັດ ອານຸສິດ ໂລຈິສຕິກ ຈຳກັດ.</div>
                <div>ບ້ານ ໂພນສະຫວ່າງ, ເມືອງ ຈັນທະບູລີ, ນະຄອນຫຼວງວຽງຈັນ</div>
                <div className="text-end">
                  ພິມໂດຍ: {getLogin?.first_name} {getLogin?.last_name}
                </div>
                <div className="text-end">
                  ວັນທີພິມ: {time} , {today}
                </div>
              </div>
            </div>
            &nbsp;&nbsp;
            <div className="table">
              <table className="table" border={1}>
                <thead>
                  <tr>
                    <th className="text-nowrap">ລຳດັບ</th>
                    <th className="text-nowrap">ຊື່ສາຍ ແລະ ລາຄາຕໍ່ຖ້ຽວ</th>
                    <th className="text-nowrap">ລະຫັດສາຍ</th>
                    <th className="text-nowrap">ອັກສອນຫຍໍ້</th>
                    <th className="text-nowrap">ພາກ</th>
                    <th className="text-nowrap">ປະເພດສາຍ</th>
                    <th className="text-nowrap">ໄລຍະທາງ</th>
                    <th className="text-nowrap">ນ້ຳມັນ</th>
                    <th className="text-nowrap">ສະຖານະສາຍ</th>
                    <th className="text-nowrap">ລາຍລະອຽດ</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {items?.map((value: any, index: number) => (
                      <tr key={index}>
                        <td className="text-center ">{index + 1}</td>
                        <td className="text-nowrap ">
                          {value?.title || "---"}
                          <hr />
                          <h6>ລາຄາຕໍ່ຖ້ຽວ(ພາກເຊົ້າ): {currency(value?.priceDay || 0)} ກີບ</h6>
                          <h6>ລາຄາຕໍ່ຖ້ຽວ(ພາກແລງ): {currency(value?.priceNight || 0)} ກີບ</h6>
                        </td>
                        <td className="text-nowrap text-center">{value?.code || "----"}</td>
                        <td className="text-nowrap text-center">{value?.locationCode || 0}</td>
                        <td className="text-nowrap text-center">{value?.lg_isDay === 0 ? "ພາກເຊົ້າ" : "ພາກແລງ"}</td>
                        <td className="text-center">
                          {value?.checkRoute === "IN" ? <p>ສາຍໃນ</p> : value?.checkRoute === "OUT" ? <p>ສາຍນອກ</p> : <p>ສາຍສຳຮອງ</p>}
                        </td>
                        <td className="text-center ">{currency(value?.km || 0)} ກິໂລແມັດ</td>
                        <td className="text-center">{currency(value?.petroleum || 0)} ລິດ</td>
                        <td className="text-center">{value?.isAuction === 1 ? "ສາຍປະມູນ" : "ສາຍທົ່ວໄປ"}</td>
                        <td className="text-center">{value?.note || "----"}</td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
