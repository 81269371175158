import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  InvalidText,
  MessageConfirms,
  MessageErrors,
  MessageSuccess,
  confirmShow,
  formatDateUpdate,
  notifyError,
  notifySuccess,
} from "../../../utils";
import { useMutation } from "@apollo/client";
import Schema from "./Schema";
import { useFormik } from "formik";
import SearchTypeVehicle from "../../../utils/components/SelectTypeVehicle";
import SearchCenter from "../../../utils/components/SelectCenter";

type VehicleValue = {
  VehicleType: number;
  vehicleName: string;
  brand: string;
  colour: string;
  power: string;
  isRent: number;
  machineNumber: string;
  tankNumber: string;
  sign: string;
  insurance: string;
  insuranceOver: string;
  center: number;
  note: string;
};

type VehicleError = {
  VehicleType?: string;
  vehicleName?: string;
  brand?: string;
  colour?: string;
  power?: string;
  isRent?: string;
  machineNumber?: string;
  tankNumber?: string;
  sign?: string;
  center?: string;
};

type FormLayoutProp = {
  values: VehicleValue;
  errors: VehicleError;
  handleChange: any;
  setFieldValue: any;
};

const FormLayout = ({ values, errors, handleChange, setFieldValue }: FormLayoutProp) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>ປະເພດລົດ {InvalidText()}</Form.Label>
            <SearchTypeVehicle
              value={values?.VehicleType}
              onChange={(res): any => {
                setFieldValue("VehicleType", res?.value);
              }}
            />
            <div className="text-danger">{errors.VehicleType}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເບີລົດ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ເບີລົດ"
              name="vehicleName"
              onChange={handleChange}
              value={values?.vehicleName}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ຍີ່ຫໍ້ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ຍີ່ຫໍ້"
              name="brand"
              onChange={handleChange}
              value={values?.brand}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ສີລົດ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ສີລົດ"
              name="colour"
              onChange={handleChange}
              value={values?.colour}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ຄວາມແຮງ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ຄວາມແຮງ"
              name="power"
              onChange={handleChange}
              value={values?.power}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ບູລິມະສິດ {InvalidText()}</Form.Label>
            <Form.Select name="isRent" onChange={handleChange} value={values?.isRent} isInvalid={values?.isRent === undefined}>
              <option value={undefined}>ເລືອກບູລິມະສິດ</option>
              <option value={1}>ລົດຮ່ວມ</option>
              <option value={0}>ລົດບໍລິສັດ</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>ເລກຈັກ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ເລກຈັກ"
              name="machineNumber"
              onChange={handleChange}
              value={values?.machineNumber}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ເລກຖັງ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ເລກຖັງ"
              name="tankNumber"
              onChange={handleChange}
              value={values?.tankNumber}
              isInvalid={errors?.VehicleType ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ປ້າຍທະບຽບ {InvalidText()}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ປ້າຍທະບຽບ"
              name="sign"
              isInvalid={errors?.sign ? true : false}
              onChange={handleChange}
              value={values?.sign}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ປະກັນໄພ</Form.Label>
            <Form.Control type="text" placeholder="ປະກັນໄພ" name="insurance" onChange={handleChange} value={values?.insurance} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ວັນປະກັນໄພໝົດອາຍຸ</Form.Label>
            <Form.Control type="date" name="insuranceOver" onChange={handleChange} value={values?.insuranceOver} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>ສູນຄັດແຍກ {InvalidText()}</Form.Label>
            <SearchCenter
              value={values?.center}
              onChange={(res): any => {
                setFieldValue("center", res?.value);
              }}
            />
            <div className="text-danger">{errors.center}</div>
          </Form.Group>
        </Col>
        <Form.Group className="mb-3">
          <Form.Label>ລາຍລະອຽດ</Form.Label>
          <Form.Control type="text" placeholder="ລາຍລະອຽດ" as="textarea" rows={3} name="note" onChange={handleChange} value={values?.note} />
        </Form.Group>
      </Row>
    </>
  );
};

const initialValues: VehicleValue = {
  VehicleType: 0,
  vehicleName: "",
  brand: "",
  colour: "",
  power: "",
  isRent: 0,
  machineNumber: "",
  tankNumber: "",
  sign: "",
  insurance: "",
  insuranceOver: "",
  center: 0,
  note: "",
};

const validate = (values: VehicleValue) => {
  const errors: VehicleError = {};
  if (!values?.VehicleType) errors.VehicleType = "ກະລຸນາປ້ອນປະເພດລົດ";
  if (!values?.vehicleName) errors.vehicleName = "ກະລຸນາປ້ອນເບີລົດ";
  if (!values?.brand) errors.brand = "ກະລຸນາປ້ອນຍີ່ຫໍ້";
  if (!values?.power) errors.power = "ກະລຸນາປ້ອນຄວາມແຮງ";
  if (values?.isRent === undefined) errors.isRent = "ກະລຸນາປ້ອນບູລິມະສິດ";
  if (!values?.machineNumber) errors.machineNumber = "ກະລຸນາປ້ອນເລກຈັກ";
  if (!values?.tankNumber) errors.tankNumber = "ກະລຸນາປ້ອນເລກຖັງ";
  if (!values?.center) errors.center = "ກະລຸນາເລືອກສູນ";
  if (!values?.sign) errors.sign = "ກະລຸນາປ້ອນປ້າຍທະບຽບ";
  return errors;
};

export const CreateMenageVehicle = ({ onSuccess }: { onSuccess: () => void }) => {
  const [show, setShow] = useState(false);
  const [createData, { loading }] = useMutation(Schema.CREATE_VEHICLE);

  // useFormik
  const { values, errors, handleChange, submitForm, isSubmitting, setSubmitting, resetForm, setFieldValue } = useFormik<VehicleValue>({
    initialValues: initialValues,
    validate: validate,
    onSubmit: async (values) => {
      try {
        await createData({
          variables: {
            data: {
              ...values,
              isRent: Number(values?.isRent),
            },
          },
        });
        notifySuccess(MessageSuccess.insert);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.insert);
      }
    },
  });
  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <i className="fas fa-add"></i> ເພີ່ມຂໍ້ມູນລົດ
      </Button>
      <Modal show={show} size="lg">
        <Modal.Header closeButton={!loading} onHide={() => setShow(false)}>
          <Modal.Title>
            <i className="fas fa-add"></i> ຟອມເພີ່ມຂໍ້ມູນລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

// delete component
type DeleteProps = {
  _id: number;
  onSuccess: () => void;
  className?: string;
};

export const DeleteMenageVehicle = ({ _id, onSuccess, className }: DeleteProps) => {
  const [updateData, { loading }] = useMutation(Schema.DELETE_VEHICLE);
  return (
    <Button
      variant="danger"
      className={className}
      size="sm"
      disabled={loading}
      onClick={() => {
        confirmShow(MessageConfirms.delete, async () => {
          try {
            await updateData({
              variables: {
                data: {
                  isDeleted: 1,
                },
                where: {
                  _id: _id,
                },
              },
            });
            notifySuccess(MessageSuccess.delete);
            onSuccess?.();
          } catch (error) {
            notifyError(MessageErrors.delete);
          }
        });
      }}
    >
      {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-trash m-0" />}
    </Button>
  );
};

// update component
type UpdateProps = {
  item: any;
  onSuccess: () => void;
  className?: string;
};
export const UpdateMenageVehicle = ({ item, onSuccess, className }: UpdateProps) => {
  const [show, setShow] = useState(false);
  const [isRentNumber, setIsRentNumber] = useState<number>(0);
  const [updateData, { loading }] = useMutation(Schema.UPDATE_VEHICLE);

  // useFormik
  const { values, errors, handleChange, submitForm, setSubmitting, setValues, setFieldValue, isSubmitting } = useFormik<VehicleValue>({
    initialValues: initialValues,
    validate: validate,

    onSubmit: async (values, { resetForm }) => {
      try {
        await updateData({
          variables: {
            data: {
              ...values,
            },
            where: {
              _id: item?._id,
            },
          },
        });
        notifySuccess(MessageSuccess.update);
        setSubmitting(false);
        setShow(false);
        resetForm();
        onSuccess?.();
      } catch (error) {
        notifyError(MessageErrors.update);
      }
    },
  });
  useEffect(() => {
    setIsRentNumber(Number(values?.isRent));
  }, [values.isRent]);

  useEffect(() => {
    if (!show) return;
    setValues({
      VehicleType: item?.VehicleType?._id || 0,
      vehicleName: item?.vehicleName || "",
      brand: item?.brand || "",
      colour: item?.colour || "",
      isRent: isRentNumber,
      machineNumber: item?.machineNumber || "",
      tankNumber: item?.tankNumber || "",
      sign: item?.sign || "",
      insurance: item?.insurance || "",
      insuranceOver: item?.insuranceOver ? formatDateUpdate(item?.insuranceOver) : "",
      center: item?.center?.st_id || 0,
      note: item?.note || "",
      power: item?.power || "",
    });
  }, [show, item, setValues, isRentNumber]);

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary" onClick={() => setShow(true)}>
        <i className="fa fa-edit" />
      </button>
      <Modal show={show} size="lg">
        <Modal.Header
          closeButton={!loading}
          onHide={() => {
            setShow(false);
          }}
        >
          <Modal.Title>
            <i className="fas fa-edit" />
            ຟອມແກ້ໄຂຂໍ້ມູນລົດ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormLayout values={values} errors={errors} handleChange={handleChange} setFieldValue={setFieldValue} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting || loading} onClick={submitForm}>
            {loading ? <Spinner animation="border" variant="white" size="sm" /> : <i className="fas fa-check-circle m-0" />}
            <span className="ms-1">ສົ່ງຟອມ</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

