import ModifyPriceRoute from './modifyPriceRoute';
import ShippingCheckingRoute from './shippingCheckingRoute';
import ShippingRoute from './shippingRoute';
import ShippingRouteTwo from './shippingRouteTwo';
import ShippingCheckingRouteTwo from './shippingCheckingRouteTwo';

const vehicleManagement = {
    ModifyPriceRoute,
    ShippingCheckingRoute,
    ShippingRoute,
    ShippingRouteTwo,
    ShippingCheckingRouteTwo,
};
export default vehicleManagement;