import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChildPath, ParentPath } from "../../../routes/Path";
import { Button, Card, Col, Image, Row, Spinner } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import Profile from "../../../img/user_null.png";
import { formatDate, formatDateTime } from "../../../utils";

export default function DetailUser() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const getUser = params.get("User");
  const [FetchData, { data, loading }] = useLazyQuery(Schema.query_user_details, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    FetchData({
      variables: {
        where: {
          _id: Number(getUser),
        },
      },
    });
  }, [getUser, FetchData]);

  const value = data?.users?.data[0];

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li
            className="breadcrumb-item"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.containDriver}`)}
          >
            ບັນຈຸໂຊເຟີລົດ
          </li>
          <li className="breadcrumb-item">ລາຍລະອຽດຂໍ້ມູນໂຊເຟີ້ລົດ</li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>
            <i className="fas fa-car"></i> ລາຍລະອຽດຂໍ້ມູນໂຊເຟີ້ລົດ
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={3}>
              <Image src={Profile} style={{ width: "100%", height: "auto" }} />
              <br />
              <h3 className="text-center text-dark mt-2">
                ID:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?._id || 0}
              </h3>
              <br />
            </Col>
            <Col md={9}>
              <div className="card-header bg-primary">
                <h3 className="text-white">ລາຍລະອຽດຂໍ້ມູນໂຊເຟີ້ລົດ </h3>
              </div>
              <br />
              <p>
                ຊື່ ແລະ ນາມສະກຸນ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.first_name || "----"}{" "}
                {value?.last_name || "----"}
              </p>
              <hr />
              <p>
                ສິດການນຳໃຊ້:
                {value?.role === "SUPER_ADMIN" ? (
                  <Button variant="success" size="sm">
                    ຜູ້ດູແລລະບົບ
                  </Button>
                ) : value?.role === "ADMIN" ? (
                  <Button variant="danger" size="sm">
                    ຜູ້ຈັດຖ້ຽວ
                  </Button>
                ) : value?.role === "USERS" ? (
                  <Button variant="danger" size="sm">
                    ຜູ້ໃຊ້
                  </Button>
                ) : value?.role === "DRIVER_OWN" ? (
                  <Button variant="danger" size="sm">
                    ໂຊເຟີ້ສ່ວນບຸກຄົນ
                  </Button>
                ) : value?.role === "AUDITOR" ? (
                  <Button variant="danger" size="sm">
                    ກວດສອບຖ້ຽວລົດ
                  </Button>
                ) : value?.role === "ACCOUNTANT" ? (
                  <Button variant="danger" size="sm">
                    ບັນຊີ
                  </Button>
                ) : (
                  <Button variant="danger" size="sm">
                    ໂຊເຟີ້ບໍລິສັດ
                  </Button>
                )}
              </p>
              <hr />
              <p>
                ສະຖານະ:
                {value?.status === "ACTIVED" ? (
                  <Button variant="success" size="sm">
                    ກຳລັງໃຊ້ງານ
                  </Button>
                ) : (
                  <Button variant="danger" size="sm">
                    ອອກແລ້ວ
                  </Button>
                )}
              </p>
              <hr />
              <p>ວັນທີສ້າງ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : formatDateTime(value?.createdAt)}</p>
              <hr />
              <p>
                ສ້າງໂດຍ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.createdBy?.first_name || "----"}{" "}
                {value?.createdBy?.last_name || "----"}
              </p>
              <hr />
              <p>ເພດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.gender === "FEMALe" ? "ເພດຍິງ" : "ເພດຊາຍ"}</p>
              <hr />
              <p>ເບີໂທ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.phoneNumber || "-----"}</p>
              <hr />

              <p>ອາຍຸ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.age || "-----"}</p>
              <hr />
              <p>ທີ່ຢູ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.address || "-----"}</p>
              <hr />
              <p>ເລກບັດປະຈຳຕົວ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.cardID || "-----"}</p>
              <hr />
              <p>ເລກທີໃບຂັບຂີ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.passID || "-----"}</p>
              <hr />
              <p>
                ວັນທີເລີ່ມ:{" "}
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.startDate ? formatDate(value?.startDate) : "-----"}
              </p>
              <hr />
              <p>
                ວັນທີອອກ:{" "}
                {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.endDate ? formatDate(value?.endDate) : "-----"}
              </p>
              <hr />
              <p>ເອກະສານຕິດຂັດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.docFile || "-----"}</p>
              <hr />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
