import React, { useEffect } from "react";
import { ChildPath, ParentPath } from "../../../routes/Path";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { useLazyQuery } from "@apollo/client";
import Schema from "./Schema";
import { formatDateTime } from "../../../utils";

export default function DetailDriver() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const getNameVehicle = params.get("Driver");

  const getStatus = params.get("CheckStatus");

  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_CONTAIN_VEHICLE, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    FetchData({
      variables: {
        where: {
          _id: Number(getNameVehicle),
        },
      },
    });
  }, [getNameVehicle, FetchData]);

  const value = data?.vehicles?.data[0];

  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          {getStatus ? (
            <li
              className="breadcrumb-item"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.informationVehicle}`)}
            >
              ຈັດການຂໍ້ມູນລົດຂົນສົ່ງ
            </li>
          ) : (
            <li
              className="breadcrumb-item"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${ParentPath.transportationVehicle}/${ChildPath.containDriver}`)}
            >
              ບັນຈຸໂຊເຟີ້ລົດ
            </li>
          )}

          <li className="breadcrumb-item">ລາຍລະອຽດຂໍ້ມູນລົດ</li>
        </ol>
      </div>

      <div className="col-sm-12">
        <Card>
          <Card.Header>
            <Card.Title>
              <i className="fas fa-car"></i> ລາຍລະອຽດຂໍ້ມູນລົດ
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-sm-4">
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="auto"
                        fill="currentColor"
                        className="bi bi-truck"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                      </svg>
                      <hr />
                      <h1 className="text-center">{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.vehicleName || 0}</h1>
                    </Card.Title>
                  </Card.Header>
                </Card>
              </div>
              <div className="col-sm-8">
                <div className="bg-primary card-header">
                  <h3 className="text-white">ຂໍ້ມູນລົດ</h3>
                </div>
                <br />
                <p>ປະເພດລົດ:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.VehicleType?.name || "----"}</p>
                <hr />
                <p>ເບີລົດ:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.vehicleName || 0}</p>
                <hr />
                <p>ຍີຫໍ້: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.brand || "-----"}</p>
                <hr />
                <p>ສີລົດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.colour || "----"}</p>
                <hr />
                <p>ຄວາມແຮງ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.power || "----"}</p>
                <hr />
                <p>ເລກຈັກ:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.machineNumber || "----"}</p>
                <hr />
                <p>ເລກຖັງ:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.tankNumber || "----"}</p>
                <hr />
                <p>ປ້າຍທະບຽນລົດ:{loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.sign || "----"}</p>
                <hr />
                <p>ປະກັນໄພ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.insurance || "----"}</p>
                <hr />
                <p>
                  ວັນປະກັນໄພໝົດອາຍຸ:{" "}
                  {loading ? (
                    <Spinner animation="border" variant="danger" size="sm" />
                  ) : value?.insuranceOver ? (
                    formatDateTime(value?.insuranceOver)
                  ) : (
                    "-----"
                  )}
                </p>
                <hr />

                <p>
                  ບູລິມະສິດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.isRent === 1 ? "ລົດຮ່ວມ" : "ລົດບໍລິສັດ"}
                </p>
                <hr />
                <p>
                  ວັນທີສ້າງ:{" "}
                  {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.createdAt ? formatDateTime(value?.createdAt) : "---"}
                </p>
                <hr />
                <p>ສູນຄັດແຍກ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.center?.centerName || "----"}</p>
                <hr />
                <p>ສະຖານະ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.isPublic === 1 ? "ເປີດ" : "ປິດ"}</p>
                <hr />
                <p>ລາຍລະອຽດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.note || "----"}</p>
                <hr />
                <div className="bg-primary card-header">
                  <h3 className="text-white">ຂໍ້ມູນໂຊເຟີ້ລົດ</h3>
                </div>
                <br />
                <p>
                  ຊື່ ແລະ ນາມສະກຸນ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.first_name || "---"}{" "}
                  {value?.driver?.last_name || "----"}
                </p>
                <hr />
                <p>
                  ເພດ:{" "}
                  {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.gender === "FEMALE" ? "ເພດຍິງ" : "ເພດຊາຍ"}
                </p>
                <hr />
                <p>ອາຍຸ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.age || 0}</p>
                <hr />
                <p>ເບີໂທ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.phoneNumber || 0}</p>
                <hr />
                <p>ບັດປະຈຳຕົວ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.cardID || "----"}</p>
                <hr />
                <p>ໃບຂັບຂີ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.passID || "----"}</p>
                <hr />
                <p>ທີ່ຢູ່: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.driver?.address || "----"}</p>
                <hr />
                <p>
                  ວັນທີລົງທະບຽບ:{" "}
                  {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.createdAt ? formatDateTime(value?.createdAt) : "----"}
                </p>
                <hr />
                <div className="bg-primary card-header">
                  <h3 className="text-white">ຂໍ້ມູນສາຍລົດ</h3>
                </div>
                <br />
                <p>ຊື່ສາຍ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.title || "---"}</p>
                <hr />
                <p>ອັກສອນຫຍໍ້: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.locationCode || "-----"}</p>
                <hr />
                <p>
                  ພາກແລງ:{" "}
                  {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.lg_isDay === 1 ? "ພາກເຊົ້າ" : "ພາກແລງ"}
                </p>
                <hr />
                <p>
                  ປະເພດສາຍ:{" "}
                  {value?.routeID?.checkRoute === "IN"
                    ? "ສາຍໃນ"
                    : value?.routeID?.checkRoute === "OUT"
                    ? "ສາຍນອກ"
                    : value?.routeID?.checkRoute === "SPARE"
                    ? "ສາຍສຳຮອງ"
                    : "---"}
                </p>
                <hr />
                <p>ໄລຍະທາງ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.km || "----"}</p>
                <hr />
                <p>ນໍ້າມັນ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.petroleum || "-----"}</p>
                <hr />
                <p>
                  ສະຖານະສາຍ:{" "}
                  {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.isAuction === 1 ? "ສາຍປົກກະຕິ" : "ສາຍເສີມ"}
                </p>
                <hr />
                <p>ລາຍລະອຽດ: {loading ? <Spinner animation="border" variant="danger" size="sm" /> : value?.routeID?.note || "----"}</p>
                <hr />
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
