import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Gender, RootVariable, checkUserRole, currency, formatDate, formatDateTime } from "../../../utils";
import { useLazyQuery } from "@apollo/client";
import TableData from "../../../utils/components/TableData";
import SearchDriver from "../../../utils/components/SelectDriver";
import Schema from "./Schema";
import SearchDateRange from "../../../utils/components/SearchDateRange";
import moment from "moment";
import { Create, Delete, ExportData, Update } from "./component";

export default function ExtraPayment() {
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState<any[]>();
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numberRow, setNumberRow] = useState(RootVariable.limit);
  const [searchParams,setSearchParams] = useSearchParams();
  const [reloading, setReloading] = useState(false);
  const [getUser, setUser] = useState<any>();
  const [dateBetween, setDateBetween] = useState<string[]>([]);
  const [NotDisplay] = useState<any>(1);
  const [display] = useState<any>(2);
  const [FetchData, { data, loading }] = useLazyQuery(Schema.QUERY_DEDUCTION_PAYMENT, { fetchPolicy: "cache-and-network" });
  useEffect(() => {
    if (dateBetween?.length !== 2) return;
    const driverIDs = getUser === 0 ? undefined : Number(getUser);
    FetchData({
     
      variables: {
        where: {
          deductionDriverID: driverIDs ? driverIDs : undefined,
          createdDateBetween: [dateBetween[0], dateBetween[1]],
        },
        limit: searchValue ? 0 : numberRow,
        skip: searchValue ? 0 : numberRow * (pageNumber - 1),
      },
    });
  }, [pageNumber, numberRow, reloading, searchValue, FetchData, getUser, dateBetween]);
  useEffect(() => {
    if (loading) {
      setItems([]);
      setTotal(0);
      return;
    }
    setItems(data?.deductionPayments?.data || []);
    setTotal(data?.deductionPayments?.total || 0);
  }, [data, loading]);

  useEffect(() => {
    const _page = searchParams.get("page");
    const _row = searchParams.get("row");
    const _user = searchParams.get("user");
    const deCodeUser = _user ? atob(_user) : undefined;
    setUser(deCodeUser ? deCodeUser : undefined)
    setPageNumber(_page ? parseInt(_page) : 1);
    setNumberRow(_row ? parseInt(_row) : RootVariable.limit);
    const _startDate = searchParams.get("startDate");
    const _endDate = searchParams.get("endDate");
    if (_startDate && _endDate) setDateBetween([_startDate, _endDate]);
    else setDateBetween([moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
  }, [searchParams]);
  return (
    <React.Fragment>
      <div className="page-header">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">ໜ້າຫຼັກ</li>
          <li className="breadcrumb-item">ເງິນຫັກ</li>
        </ol>
      </div>

      <div className="main-container">
        <div className="card">
          <div className="card-header border-bottom">
            <div className="row">
              <div className="col-sm-8">
                {" "}
                <h3>ລາຍງານຂໍ້ມູນເງິນຫັກ</h3>
              </div>
              <div className="col-sm-4">
                <div style={{ float: "right" }}>
                  {checkUserRole(["SUPER_ADMIN", "AUDITOR"]) ? (
                    <>
                      <ExportData data={data} loading={loading} />
                      <Create onSuccess={() => setReloading(!reloading)} NotDisplay={NotDisplay} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row blog p-2">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8 ">
                    <SearchDateRange />
                    <br />
                    <label>ເລືອກໂຊເຟີ້ລົດ</label>
                    <div style={{ width: 470 }}>
                      <SearchDriver
                        onChange={(option: any) => {
                          searchParams.set("user", btoa(option.value));
                          setSearchParams(searchParams);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <br />
                <br />
                <TableData
                  total={total}
                  loading={loading}
                  pageNumber={pageNumber}
                  numberRow={numberRow}
                  onSearchText={(val) => {
                    setSearchValue(val || "");
                  }}
                  header={
                    <tr>
                      <th className="text-nowrap">ລຳດັບ</th>
                      <th className="text-nowrap">ຊື່ແລະນາມສະກຸນ</th>
                      <th className="text-nowrap">ຈຳນວນເງິນ</th>
                      <th className="text-nowrap">ວັນທີ</th>
                      <th className="text-nowrap text-center">ສະຖານະການຊຳລະ</th>
                      <th className="text-nowrap">ເລກທີສະຫຼຸບ</th>
                      <th className="text-nowrap">ໝາຍເຫດ</th>
                      <th className="text-nowrap">ສ້າງຂຶ້ນວັນທີ</th>
                      <th className="text-nowrap">ສ້າງໂດຍ</th>
                      {checkUserRole(["SUPER_ADMIN", "AUDITOR"]) ? (
                        <th className="text-nowrap">
                          <i className="fas fa-gear"></i>ຈັດການ
                        </th>
                      ) : null}
                    </tr>
                  }
                  body={
                    <>
                      {items?.map((value, index) => (
                        <tr className="tbody" key={index}>
                          <td className="no">{index + 1}</td>
                          <td className="text-nowrap">
                            {value?.deductionDriverID?.gender ? Gender(value?.deductionDriverID?.gender) : ""}&nbsp;
                            {value?.deductionDriverID?.first_name || "---"} {value?.deductionDriverID?.last_name || "----"}
                          </td>
                          <td className="text-nowrap">{currency(value?.deductionAmount || 0)} ກີບ</td>
                          <td className="text-nowrap">{value?.deductionDate ? formatDate(value?.deductionDate) : "-----"}</td>
                          <td className={value?.deductionStatus === "PAID" ? "text-success text-center" : "text-danger text-center"}>
                            {value?.deductionStatus === "PAID" ? "ຊຳລະແລ້ວ" : "ຍັງບໍ່ທັນຊຳລະ"}
                          </td>
                          <td className="text-nowrap">{value?.deductionCheck || 0}</td>
                          <td className="text-nowrap">{value?.deductionNote || "---"}</td>
                          <td className="text-nowrap ">{value?.deductionCreatedAt ? formatDateTime(value?.deductionCreatedAt) : "-----"}</td>
                          <td className="text-center text-nowrap">
                            {value?.deductionCreatedBy?.gender ? Gender(value?.deductionCreatedBy?.gender) : ""}&nbsp;
                            {value?.deductionCreatedBy?.first_name || "----"} {value?.deductionCreatedBy?.last_name || "----"}
                          </td>
                          {checkUserRole(["SUPER_ADMIN"]) && value?.deductionStatus === "PAID" ? (
                            <>
                              <td className="text-center btn-group">
                                <Update item={value} onSuccess={() => setReloading(!reloading)} display={display} />
                                <Delete deductionID={value?.deductionID} onSuccess={() => setReloading(!reloading)} />
                              </td>
                            </>
                          ) : checkUserRole(["SUPER_ADMIN", "ADMIN", "AUDITOR"]) && value?.deductionStatus === "UNPAID" ? (
                            <>
                              <td className="text-center btn-group">
                                <Update item={value} onSuccess={() => setReloading(!reloading)} display={display} />
                                <Delete deductionID={value?.deductionID} onSuccess={() => setReloading(!reloading)} />
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
